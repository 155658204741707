import { Image } from '@boiler/image'
import { Video as VideoJS } from '@boiler/video'

const VideoPlayer = ({ blok, ratio, fit, options = {}, className }) => {
  const { m3u8 = '', mp4 = '', image } = blok ?? {}

  return (
    <VideoJS
      mode="scale"
      preset="player"
      objectFit={fit}
      className={className}
      aspectRatio={ratio ?? undefined}
      options={{
        sources: [
          { src: m3u8, type: 'application/x-mpegURL' },
          { src: mp4, type: 'video/mp4' },
        ],
        ...options,
      }}
    >
      <Image data={image} object={{ fit: 'cover', position: 'center center' }} inview={true} fitted inset />
    </VideoJS>
  )
}

export default VideoPlayer
