import { useRef } from 'react'
import { useConditionalEffect, useIsomorphicLayoutEffect as useLayoutEffect } from '@react-hookz/web'
import { gsap } from 'gsap'

const useTween = (instance, deps = [], predicates = [], passedRef) => {
  let ref = useRef(null)
  let tween = useRef(null)

  useConditionalEffect(
    () => {
      const main = passedRef ? passedRef.current : ref?.current

      if (typeof instance === 'function') {
        tween.current = instance({ gsap, node: main, $: (obj) => obj?.current })
      } else {
        tween.current = instance
      }
    },
    deps,
    predicates,
    undefined,
    useLayoutEffect
  )

  return [tween.current, { ref, current: ref?.current }]
}

export default useTween
