import { useThemeUI } from 'theme-ui'
import { useMatterEffect, parseSvg, select, parseResponsive, parseMatterProps } from '@boiler/matter'
import { shape as s } from './verticles'

const { landscape, portrait } = {
  landscape: {
    side: 10,
    rows: [6, 6, 5, 5, 5],
    columns: [4, 5, 6, 7, 9],
  },
  portrait: {
    side: 10,
    rows: [7, 7, 8, 9, 8],
    columns: [4, 5, 6, 7, 8],
  },
}

const Physics = ({ scene, ...props }) => {
  const { objects: color = '#ffffff' } = useThemeUI()?.theme?.rawColors ?? {}
  const x = parseMatterProps(scene) ?? {}

  const changedKey = JSON.stringify(scene ?? {})

  const [ref, status] = useMatterEffect(
    ({ Matter, mouseEvents, is_portrait, size: { width, height }, world }) => {
      Matter.Common.setDecomp(require('poly-decomp'))

      const thickness = 200
      const offset = thickness * 0.5
      const wallProps = { isStatic: true, render: { visible: false } }

      // WALLS
      Matter.Composite.add(world, [
        // Matter.Bodies.rectangle(width / 2, -offset, width, thickness, wallProps),
        Matter.Bodies.rectangle(width / 2, height + offset, width, thickness, wallProps),
        Matter.Bodies.rectangle(-offset, 0, thickness, height * 2, wallProps),
        Matter.Bodies.rectangle(width + offset, 0, thickness, height * 2, wallProps),
      ])

      const values = !!!is_portrait ? landscape : portrait

      const { side, rows, columns } = parseResponsive(width, values)

      const viewport_width = width + side
      const part_width = viewport_width / columns
      const stack_gap = part_width * 0.5
      const column_width = part_width - stack_gap

      const svg_width = 100
      const svg_scale = column_width / svg_width

      const shape = parseSvg(s)

      const vertexSets = () =>
        select(shape, 'path').map((path) => {
          const vert = Matter.Svg.pathToVertices(path, 30)
          Matter.Vertices.rotate(vert, Matter.Common.random(0, 180), { x: 0, y: 0 })
          return Matter.Vertices.scale(vert, svg_scale, svg_scale)
        })

      const stack = Matter.Composites.stack(-side, -(height * 2), columns, rows, stack_gap, stack_gap, (x, y) => {
        return Matter.Bodies.fromVertices(
          x,
          y,
          vertexSets(),
          {
            restitution: x?.restitution ?? 0,
            render: {
              fillStyle: color,
              strokeStyle: color,
              lineWidth: 1,
            },
          },
          true
        )
      })

      Matter.Composite.add(world, stack)

      mouseEvents({
        ignoreScroll: true,
        constraint: {
          stiffness: 0.2,
          render: { visible: false },
        },
      })
    },
    {
      responsive: true,
      options: {
        background: 'transparent',
        wireframes: !!x?.wireframe,
      },
      runner: { fps: 120 },
      engine: {
        positionIterations: x?.position_iterations,
        constraintIterations: x?.constraint_iterations,
        velocityIterations: x?.velocity_iterations,
        gravity: {
          scale: x?.gravity_scale,
          x: x?.gravity_x,
          y: x?.gravity_y,
        },
        timing: {
          timeScale: x?.timing_timescale,
          lastDelta: x?.timing_last_delta,
        },
      },
    },
    [color, changedKey]
  )

  return <div ref={ref} data-status={status} {...props} />
}

export default Physics
