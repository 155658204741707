import { Section, Container, Heading } from '@monobits/components'
import { localize } from '@boiler/utils'

import Card from './Card'

export const MoreProjects = ({ sx, projects, _uid, locales, ...props }) => {
  const margin = ['4rem', null, 'calc(var(--padding-m) + var(--padding-x))']

  return !!projects.length ? (
    <>
      <Section as="div" variant="heading" data-inview="fade" sx={{ mb: margin }}>
        <Container>
          <Heading variant="heading">
            {locales?.locales?.['check-out-more-projects'] ?? 'Check out more projects'}
          </Heading>
        </Container>
      </Section>

      <Section sx={{ mt: margin, ...sx }} {...props} data-inview="fade">
        <Container
          sx={{
            display: 'grid',
            alignItems: 'start',
            gridTemplateColumns: ['1fr', '1fr 1fr'],
            gap: [2, 1],
          }}
        >
          {projects.map((node, i) => {
            const _tag = (value) => localize(value, locales?.tags ?? {})

            const tagsList = (node?.tag_list ?? [])
              ?.sort((a, b) => _tag(a).localeCompare(_tag(b)))
              ?.map(
                (v, ii) =>
                  _tag(v) +
                  (ii !== node?.tag_list?.length - 2
                    ? ii !== node?.tag_list?.length - 1
                      ? ', '
                      : ''
                    : '\u00A0&\u00A0')
              )
              .join('')

            return (
              <Card
                id={_uid + node?.uuid + i}
                key={_uid + node?.uuid + i}
                title={[node?.content?.title ?? '']}
                link={node?.full_slug ? '/' + node?.full_slug : null}
                image={node?.content?.featured_image}
                tags={tagsList}
                cta_text={locales?.locales?.['view-project'] ?? 'View Project'}
                ratio={['3/4', '4/3']}
                m3u8={node?.content?.m3u8}
                mp4={node?.content?.mp4}
              />
            )
          })}
        </Container>
      </Section>
    </>
  ) : null
}

export default MoreProjects
