import { forwardRef, createRef, useRef, useState, useMemo } from 'react'
import { useConditionalEffect, useUpdateEffect } from '@react-hookz/web'
import { omit } from 'lodash'

import HlsPlayer from './HlsPlayer'
import { useVideo } from './VideoProvider'
import { MEDIA_DEFAULTS, AUTOPLAY, PLAYINLINE } from '../__consts'

const Hls = forwardRef(
  (
    {
      src,
      mp4,
      playing = null,
      playerRef: plRef = createRef(),
      lazy = {},
      styles: {
        ratio: _ratio,
        fit = null,
        position = null,
        fade = true,
        inset = false,
        //
      },
      options = {},
      events: {
        onCanplay = () => {},
        onPlaying = () => {},
        onPause = () => {},
        //
      } = {},
      poster,
      sx,
      children,
      ...props
    },
    ref
  ) => {
    let initialPlaying = useRef(playing)
    let internalPlaying = useRef(null)
    let playerRef = useRef(null)

    const [ready, setReady] = useState(false)

    const HAS_SOURCE = !!src

    const medias = useVideo() ?? {}
    const ratio = !!_ratio ? _ratio : '16/9'

    const _object = { ...MEDIA_DEFAULTS?.object, ...medias?.object, fit, position }
    const _fade = fade ?? medias?.fade ?? MEDIA_DEFAULTS?.fade
    const _transition = medias?.transition ?? MEDIA_DEFAULTS?.transition

    const _onCanplay = () => {
      if (!!!ready) {
        setReady(true)
        !!onCanplay && onCanplay(playerRef?.current)
      }
    }
    const _onPlaying = () => {
      if (!!!internalPlaying.current) {
        internalPlaying.current = true
        !!onPlaying && onPlaying(playerRef?.current)
      }
    }
    const _onPause = () => {
      if (!!internalPlaying.current) {
        internalPlaying.current = false
        !!onPause && onPause(playerRef?.current)
      }
    }
    const handlePlay = (bool = true) => {
      const current = internalPlaying.current
      const super_bool = typeof bool === 'function' ? bool(internalPlaying.current) : bool

      !!!current && !!super_bool && playerRef.current?.play()
      !!current && !!!super_bool && playerRef.current?.pause()
    }
    const handleStart = () => {
      !!(internalPlaying.current == null) && playerRef.current?.play()
    }

    const isPlaying = () => internalPlaying.current

    useConditionalEffect(() => handlePlay(!!playing), [playing], [playing != null], undefined, useUpdateEffect)

    useConditionalEffect(
      () => {
        plRef.current = {
          parentNode: playerRef.current?.parentNode,
          player: playerRef.current,
          handlePlay,
          handleStart,
          isPlaying,
        }

        playerRef.current?.addEventListener('canplay', _onCanplay)
        playerRef.current?.addEventListener('playing', _onPlaying)
        playerRef.current?.addEventListener('pause', _onPause)
        return () => {
          playerRef.current?.removeEventListener('canplay', _onCanplay)
          playerRef.current?.removeEventListener('playing', _onPlaying)
          playerRef.current?.removeEventListener('pause', _onPause)
        }
      },
      [JSON.stringify({ src })],
      [!!HAS_SOURCE]
    )

    const pip = options?.pip ?? true

    const attributes = {
      ...(!!options?.playsinline ? PLAYINLINE : {}),
      ...(!!initialPlaying?.current || !!options?.autoplay ? AUTOPLAY : {}),
      ...omit(options, ['pip', 'playsinline', 'autoplay']),
      disablePictureInPicture: !!!pip,
      poster,
    }

    const self = useMemo(
      () => (
        <HlsPlayer
          src={src}
          mp4={mp4}
          playerRef={playerRef}
          onUnmount={() => setReady(false)}
          hlsConfig={{
            liveSyncDurationCount: 3,
            initialLiveManifestSize: 3,
          }}
          {...attributes}
        />
      ),
      [JSON.stringify({ src, attributes })]
    )

    return (
      <div
        ref={ref}
        sx={{ variant: 'hls', ...sx }}
        data-ready={!!ready}
        data-inset={!!inset}
        data-with-transition={!!_fade}
        data-aspect-ratio
        style={{
          '--object-fit': _object.fit,
          '--object-position': _object.position,
          '--transition': _transition,
          '--aspect-ratio': ratio,
        }}
        {...props}
      >
        {children}
        {HAS_SOURCE && self}
      </div>
    )
  }
)

export default Hls
