import Bloks from './Bloks'

const MapBloks = ({ items, id = '', childProps = {}, ...props }) => {
  if (items) {
    return items.map((item) => {
      const _props = childProps?.[item?.component ?? ''] ?? {}
      return <Bloks blok={item} key={item._uid + id} {..._props} {...props} />
    })
  }

  return null
}

export default MapBloks
