import { useRef, useState, useCallback } from 'react'

/**
 * This hook helps solve the problem of accessing stale values in your callbacks.
 * It works exactly like useState, but returns a third value, a ref with the current state.
 *
 * @example
 * const [value, setValue, valueRef] = useStateRef<number>(0)
 */
export const useStateRef = (initialState) => {
  const [state, setState] = useState(initialState)
  const ref = useRef(state)

  const dispatch = useCallback((value) => {
    ref.current = typeof value === 'function' ? value(ref.current) : value
    setState(ref.current)
  }, [])

  return [state, dispatch, ref]
}

export default useStateRef

// Borrowed from: https://greensock.com/react-advanced#useStateRef
