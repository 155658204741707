import { RecoilRoot } from 'recoil'

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
  }
  if (typeof ResizeObserver === 'undefined') {
    let { install } = await import('resize-observer')
    install()
  }
}

export const wrapRootElement = ({ element }) => {
  return <RecoilRoot>{element}</RecoilRoot>
}

/**
 * Prevent scrollTop on routeChange until page transition is triggered
 */
export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
  if ('_scroll_delay_ms' in window) {
    const { delay, behavior } =
      {
        delay: window?._scroll_delay_ms || 0,
        behavior: 'auto',
      } ?? {}

    const scrollTop = (top, left) => {
      if (window?._gsap_scrollsmoother != null) {
        window?._gsap_scrollsmoother?.scrollTop(top)
      } else {
        window.scrollTo({ top, left, behavior })
      }
    }

    if (location.action === 'PUSH') {
      window.setTimeout(() => scrollTop(0), delay)
    } else {
      const [left, top] = getSavedScrollPosition(location, location?.key)
      window.setTimeout(() => scrollTop(top || 0, left || 0), delay)
    }
    return false
  }
}
