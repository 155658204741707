import Play from '../play.svg'

export const BigPlayButton = ({ pointerEvents = 'none', size = '4rem', ...props }) => {
  const Component = pointerEvents === 'none' ? 'div' : 'button'

  return (
    <Component
      sx={{
        color: 'white',
        bg: 'rgba(255,255,255, 0.4)',
        boxShadow: 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
        pointerEvents,

        '@supports (backdrop-filter: blur(1px))': {
          bg: 'rgba(255,255,255, 0.2)',
          backdropFilter: 'blur(20px)',
        },

        borderRadius: '50%',
        aspectRatio: '1/1',

        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        size,
        svg: { size: '75%' },

        '> svg': {
          mixBlendMode: 'difference',
        },
      }}
      {...props}
    >
      <Play />
    </Component>
  )
}

export default BigPlayButton
