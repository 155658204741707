import ReactObfuscate from 'react-obfuscate'
import parsePhoneNumber from 'libphonenumber-js'
import { pick } from 'lodash'

import { useComponent } from '../hooks'

const Link = ({ def, data, pre, classId, ...props }) =>
  def({
    value: (
      <>
        {pre && <span>{pre}</span>}
        <ReactObfuscate id={props.id} className={classId} {...data} />
      </>
    ),
    ...props,
  })

const Obfuscate = ({ blok, ...props }) => {
  const { obfuscate } = useComponent() ?? {}
  const { email, custom_link, text } = blok ?? {}
  const link = custom_link || email

  const cc_list = blok?.cc?.list ?? []

  const content = {
    email: link,
    children: text || link,
    headers: {
      ...pick(blok, ['subject', 'body']),
      ...(cc_list?.length && {
        cc: cc_list?.map((node) => node.text).join(),
      }),
    },
    target: '_blank',
  }

  return (
    <Link classId={'email-' + blok._uid} def={obfuscate.component} data={content} {...obfuscate?.email} {...props} />
  )
}

const Phone = ({ blok, ...props }) => {
  const { obfuscate } = useComponent() ?? {}
  const { phone, custom_link, text } = blok ?? {}
  const link = custom_link || phone

  const phoneNumber = parsePhoneNumber(link, 'CA')

  const content = {
    tel: phoneNumber?.number || link,
    children: text || phoneNumber.formatNational(),
  }

  return (
    <Link classId={'phone-' + blok._uid} def={obfuscate.component} data={content} {...obfuscate?.phone} {...props} />
  )
}

Obfuscate.Email = Obfuscate
Obfuscate.Phone = Phone

export default Obfuscate
