export const CONFIGS = {
  inview: {
    once: true,
    disabled: false,
    key: 'data-inview',
  },
  options: { rootMargin: '0px', threshold: 0.0 },
}

export const CONNECTION_THRESHOLD = {
  FAST: '1250px',
  SLOW: '2500px',
}
