import { Heading } from '@monobits/components'

import { Underline } from '../../atoms'

const Head = ({ locale = {}, title = '', year = '', link = '', amount = 0 }) => {
  return (
    <div sx={{ variant: 'project.header' }} data-with-link={!!link} data-inview="project-bar">
      {!!link && (
        <div data-area="live">
          <Underline as="a" variant="caption" href={link} target="_blank" rel="noreferrer noopener">
            {locale?.['visit-site'] ?? 'Visit Site'}
          </Underline>
        </div>
      )}

      <hr />

      <Heading as="h2" variant="caption" data-area="title">
        {title}
      </Heading>
      <Heading
        as="h6"
        variant="caption"
        data-area="amount"
        title={locale?.['quantity-of-assets'] ?? 'Quantity of assets'}
      >
        ↓ ({amount})
      </Heading>
    </div>
  )
}

export default Head
