import { useMemo } from 'react'
import LottiePlayer from 'lottie-react'

const Lottie = ({ blok, autoplay = true, loop = true, ...props }) => {
  const { json } = blok ?? {}

  const lottie = useMemo(
    () => (!!json ? <LottiePlayer animationData={parse(json)} autoplay={true} loop={true} {...props} /> : null),
    [json, props]
  )

  return lottie
}

function parse(json = '{}') {
  let obj
  try {
    obj = JSON.parse(json)
  } catch {
    obj = {}
  }

  return obj
}

export default Lottie
