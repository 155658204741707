import { shade } from 'polished'
import { makeHighlight } from '@monobits/colors'
import { omit } from 'lodash'

const opacity = 0.15

const colors = {
  white: '#FFFFFF',
  light: '#F6F2E2',
  dark: '#000000',
  yellow: '#FFB300',
  blue: '#0759F9',
  orange: '#FD4E01',
}

export default (modes = {}, initial = 'light') => {
  const each = {
    light: {
      text: colors.dark,
      background: colors.light,
      primary: colors.orange,
      highlight: makeHighlight(colors.dark, opacity),
      objects: colors.white,
    },
    dark: {
      text: colors.light,
      background: colors.dark,
      primary: colors.orange,
      highlight: makeHighlight(colors.light, opacity),
      objects: shade(0.85, colors.white),
      objects: '#111111',
    },
    ...Object.entries(modes)?.reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: {
          ...value,
          highlight: makeHighlight(value.text, opacity),
        },
      }),
      {}
    ),
  }

  return {
    ...each[initial],
    ...colors,
    modes: omit(each, [initial]),
  }
}
