import { useConditionalEffect, useResizeObserver, useDebouncedState } from '@react-hookz/web'
import { useStateRef } from '@monobits/gsap'
import { omit, pick } from 'lodash'

import { useMatter } from './MatterProvider'

/**
 * Matter.js Effect Hook, wrapper for writing scenes.
 * (See https://brm.io/matter-js/docs/)
 *
 * @param  {function} effect The effect function
 * @param  {Object} configs Matter.js configurations
 * @param  {Array} dependencies The effect dependecies
 * @param  {Array} predicates The effect predicates, all must return true for the effect to run
 *
 * Properties available in the effect
 * @property  {Object} effect.Matter Matter.js instante
 * @property  {Object} effect.size Parent container width & height
 * @property  {Boolean} effect.is_portrait Parent container orientation
 * @property  {Object} effect.engine Current scene Engine
 * @property  {Object} effect.world Current scene World
 * @property  {Object} effect.render Current scene Render
 * @property  {Object} effect.runner Current scene Runner
 * @property  {function} effect.mouseEvents Utility controlling mouse behaviour
 *
 * @returns  {Array}
 * @returns  {Object} [0]: sceneRef, the ref of the DOM element that will receive the scene canvas
 * @returns  {String} [1]: status, the load status of the Matter.js library, ('loading' | 'success' | 'error' | 'not-executed')
 *
 * @example
 * const [ref, status] = useMatterEffect(({ Matter, world, mouseEvents, size: { width, height } }) => {
 *   const thickness = 30
 *   const offset = thickness * 0.5
 *   const wallProps = { isStatic: true, render: { visible: false } }
 *
 *   // WALLS
 *   Matter.Composite.add(world, [
 *     Matter.Bodies.rectangle(width / 2, -offset, width, thickness, wallProps),
 *     Matter.Bodies.rectangle(width / 2, height + offset, width, thickness, wallProps),
 *     Matter.Bodies.rectangle(-offset, 0, thickness, height * 2, wallProps),
 *     Matter.Bodies.rectangle(width + offset, 0, thickness, height * 2, wallProps),
 *   ])
 *
 *   // ...
 *
 *   Matter.Composite.add(world, stack)
 *
 *   mouseEvents({
 *     ignoreScroll: true,
 *     constraint: {
 *       stiffness: 0.2,
 *       render: { visible: false },
 *     },
 *   })
 * },
 * {
 *   responsive: true,
 *   options: { background: 'transparent' },
 *   runner: { fps: 120 },
 *   engine: {
 *     gravity: { scale: 0.003 },
 *   },
 * },
 * [color, changedKey])
 *
 * ...
 *
 * return <div ref={ref} data-status={status} />
 */
const useMatterEffect = (effect = () => {}, configs = {}, _deps = [], _predicates = []) => {
  const [node, sceneRef] = useStateRef()
  const parentRef = node?.parentNode

  const { Matter, status } = useMatter() ?? {}

  const [resize, setResize] = useDebouncedState({}, 300, 1000)
  useResizeObserver(parentRef, (e) => setResize(e?.contentRect ?? 0))
  const responsive = configs?.responsive ?? true

  const [deps, predicates] = !!responsive ? [[resize?.width, resize?.height], [resize?.width != null]] : [[], []]

  useConditionalEffect(
    () => {
      // create engine
      const engine = Matter.Engine.create(configs?.engine)
      const world = engine.world

      // parent sizes
      const size = {
        width: parentRef?.clientWidth,
        height: parentRef?.clientHeight,
      }

      const is_portrait = !!(size?.width < size?.height)

      // create renderer
      const render = Matter.Render.create({
        engine,
        element: node,
        options: {
          ...size,
          background: 'transparent',
          wireframes: false,
          ...(configs?.options ?? {}),
        },
        ...omit(configs ?? {}, ['options', 'responsive', 'engine', 'runner']),
      })

      Matter.Render.setPixelRatio(render, 'auto')
      Matter.Render.run(render)

      const runner = Matter.Runner.create({ fps: 120, ...(configs?.runner ?? {}) })
      Matter.Runner.run(runner, engine)

      /**
       *
       * @param {Boolean} ignoreScroll Should the canva prenvent page scroll
       * @param {Object} constraint Matter mouse constraint options
       * @param {Number} stiffness Matter mouse stiffness
       *
       * @returns {Object}
       * @returns {Object} mouse instance
       * @returns {Object} mouseConstraint instance
       */
      const mouseEvents = ({ ignoreScroll = false, constraint = {}, stiffness = 0.2 }) => {
        const mouse = Matter.Mouse.create(render.canvas)
        const mouseConstraint = Matter.MouseConstraint.create(engine, {
          mouse,
          constraint: {
            stiffness,
            render: { visible: false },
            ...constraint,
          },
        })

        if (!!ignoreScroll) {
          mouseConstraint.mouse.element.removeEventListener('mousewheel', mouseConstraint.mouse.mousewheel)
          mouseConstraint.mouse.element.removeEventListener('DOMMouseScroll', mouseConstraint.mouse.mousewheel)
        }

        Matter.World.add(world, mouseConstraint)
        render.mouse = mouse

        return { mouse, mouseConstraint }
      }

      !!(typeof effect === 'function') &&
        effect({ Matter, size, is_portrait, engine, world, render, runner, mouseEvents })

      return () => {
        // Kill current engine
        Matter.Render.stop(render)
        Matter.Runner.stop(runner)

        render.canvas.remove()
        render.canvas = null
        render.context = null

        // Keep context in cache
        return {
          engine,
          runner,
          render,
          canvas: render.canvas,
          stop: function () {
            Matter.Render.stop(render)
            Matter.Runner.stop(runner)
          },
        }
      }
    },
    [status, node, ...deps, ..._deps],
    [status === 'success', node != null, ...predicates, ..._predicates]
  )

  return [sceneRef, status]
}

/**
 * Select vertex form svg DOM element
 * @param {*} root DOM Parsed SVG
 * @param {*} selector SVG element to select
 */
export const select = (root, selector = 'path') => Array.prototype.slice.call(root.querySelectorAll(selector))

/**
 * Load an svg from a path or url
 * @param {String} url local path or url to fetch
 * @returns DOM Parsed SVG
 */
export const loadSvg = (url) => {
  if (typeof fetch !== 'undefined') {
    return fetch(url)
      .then((response) => response.text())
      .then((raw) => new window.DOMParser().parseFromString(raw, 'image/svg+xml'))
  } else {
    return () => Matter.Common.warn('Fetch is not available. Could not load SVG.')
  }
}

/**
 * Parse an SVG from a string
 * @param {String} string Stringified SVG
 * @returns DOM Parsed SVG
 */
export const parseSvg = (string) => {
  return new window.DOMParser().parseFromString(string?.toString(), 'image/svg+xml')
}

/**
 * Set a value from a responsive array
 * @param {Number} width Container width (to use for responsive reference)
 * @param {Array} breakpoints Array, of breakpoints in px
 * @returns {Number} The index of the current breakpoint
 */
export const breakpoint = (width = 0, breakpoints = [0, 480, 768, 1024, 1200]) =>
  breakpoints
    .map((a, i) => {
      const b = breakpoints?.[i + 1]
      const bool = b == null ? width > a : width > a && width <= b
      return !!bool ? i : null
    })
    .filter((i) => i != null)?.[0]

/**
 * Select a value from an object containing responsive arrays
 * @param {Number} width Container width (to use for responsive reference)
 * @param {Array} values Object containing keys with either regular values or responsive arrays
 * @returns {Object} Object containing the values of the current breakpoint
 */
export const parseResponsive = (width = 0, values = {}) =>
  Object.entries(values).reduce((acc, [key, value]) => {
    if (typeof value !== 'object') {
      return { ...acc, [key]: value }
    }
    return { ...acc, [key]: value?.[breakpoint(width)] }
  }, {})

const parseValues = (obj, parser = () => {}) =>
  Object.entries(obj)?.reduce((acc, [key, value]) => ({ ...acc, [key]: parser(value) }), {})

/**
 * Parse Storyblok props, ensure each prop has the right type
 * @param {Object} props
 * @returns {Object} The transformed props
 */
export const parseMatterProps = (props) => {
  const integrers = pick(props, [
    'restitution',
    'gravity_scale',
    'gravity_x',
    'gravity_y',
    'timing_timescale',
    'timing_last_delta',
    'constraint_iterations',
    'position_iterations',
    'velocity_iterations',
  ])
  const booleans = {
    wireframe: props?.wireframe ?? 'false',
  }

  return {
    ...parseValues(integrers, (value) => +value),
    ...parseValues(booleans, (value) => ({ true: true, false: false }[value])),
  }
}

// function loadSvgs(svgs = []) {
//   return Promise.all(
//     svgs.map(async ({ path, width }) => {
//       const svg = await loadSvg(DOMAIN + path)
//       return Promise.resolve({ paths: select(svg, 'path'), getScale: (column_width = 0) => column_width / width })
//     })
//   )
// }

export default useMatterEffect
