// https://www.gatsbyjs.com/docs/adding-pagination/

/**
 * Pages need to be created dynamically for this component to work.
 */
const useStaticPagination = ({
  slug,
  range = 0,
  currentPage: current,
  numPages: total,
  labels: { prev, next, first, last } = { prev: 'Prev', next: 'Next', first: false, last: false },
}) => {
  const shift = (index) => ({ 1: `/${slug}` }[index] || `/${slug}/${index}`)

  const numbers = !!total
    ? Array.from({ length: !!range ? range : total }).map((_, i) => {
        const middle = Math.floor(range / 2)
        const inRange = current <= middle ? i + 1 : current - middle + i
        const index = !!range ? { [total]: total - range + i + 1 }[current] || inRange : i + 1

        return {
          id: 'is-page-' + index,
          current: !!(current === index),
          to: shift(index),
          index,
        }
      })
    : []

  const buttons = {
    prev: !!prev && current !== 1 ? [prev, shift(current - 1)] : [],
    next: !!next && current !== total ? [next, shift(current + 1)] : [],
    first: !!first && current !== 1 ? [first, shift(1)] : [],
    last: !!last && current !== total ? [last, shift(total)] : [],
  }

  return {
    numbers,
    buttons,
    current,
    total,
  }
}

export default useStaticPagination
