import { Container } from '@monobits/components'
import { Bloks } from '../bloks'

const Projects = ({ blok, ...props }) => {
  const { body } = blok ?? {}

  const content = body && body.map((item) => <Bloks blok={item} key={item._uid} {...props} />)

  return (
    <>
      <Container variant="box">{content}</Container>
    </>
  )
}

export default Projects
