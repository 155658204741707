import SbEditable from 'storyblok-react'
import ReactObfuscate from 'react-obfuscate'
import parsePhoneNumber from 'libphonenumber-js'
import { Fragment } from 'react'
import { localize } from '@boiler/utils'
import { Section } from '@monobits/components'
import { omit } from 'lodash'

import { Bloks } from '../bloks'
import { useLink } from '../hooks'
import { Cta, Rte, NewlineText } from '../atoms'

const inviewProps = { 'data-scroll-offset': '16%,0' }
const _ = (obj) => omit(obj, ['lang'])

const ContactSection = ({ blok, settings, locales, sanitize = (p) => p, ...props }) => {
  const { show_location, contact_nav, members, text, link, link_text, _uid } = blok ?? {}
  const { location } = settings ?? {}
  const multilink = useLink()

  const with_columns = !!(!!show_location || !!(contact_nav ?? []).length)
  const navs = (contact_nav ?? []).map((nav) => ({ handle: nav, data: settings?.[nav] ?? [] }))

  const nav_blocks = navs.map((nav) => {
    if (nav.handle === 'contact_nav') {
      return (
        <nav sx={{ variant: 'contact.location' }}>
          {nav.data.map((item, i) => (
            <div key={item._uid + '' + i} data-nav-type={item.component}>
              <Bloks blok={item} component="nav_item" inverted offset sx={{ width: 'fit-content' }} />
            </div>
          ))}
        </nav>
      )
    }
    if (nav.handle === 'social_nav') {
      return (
        <nav sx={{ variant: 'contact.social' }}>
          {nav.data.map((item, i) => (
            <div key={item._uid + '' + i} data-nav-type={item.component} sx={{ width: '1.15rem' }}>
              <Bloks blok={item} />
            </div>
          ))}
        </nav>
      )
    }

    return null
  })

  const member_blocks = (
    <ul sx={{ variant: 'contact.members' }}>
      {(members ?? []).map((member, i) => {
        const title = localize(member?.content?.job_title ?? '', locales?.global)

        return (
          <li key={member.uuid + '' + i}>
            <small>{title}</small>
            <h4>{member?.content?.name}</h4>

            {!!member?.content?.email && (
              <div>
                <ReactObfuscate id={'email-' + member?.content?._uid ?? ''} email={member?.content?.email} />
              </div>
            )}

            {!!member?.content?.phone && (
              <div>
                <ReactObfuscate
                  id={'phone-' + member?.content?._uid ?? ''}
                  tel={parsePhoneNumber(member?.content?.phone, 'CA').number}
                >
                  {parsePhoneNumber(member?.content?.phone, 'CA').formatNational()}
                </ReactObfuscate>
              </div>
            )}
          </li>
        )
      })}
    </ul>
  )

  return (
    <SbEditable content={blok} key={blok._uid}>
      <Section
        //
        variant="running"
        data-multiple={with_columns}
        data-inview="running"
        sx={{ variant: 'contact' }}
        {...inviewProps}
        {..._(sanitize(props))}
      >
        <hr sx={{ gridArea: 'hr' }} />

        {!!with_columns && (
          <div sx={{ variant: 'contact.navs', gridArea: 'a' }}>
            {!!show_location && (
              <p>
                <NewlineText text={location} id={'contact-address' + _uid} />
              </p>
            )}

            {nav_blocks.map((nav, i) => (
              <Fragment key={'contact-nav-block' + i}>{nav}</Fragment>
            ))}
          </div>
        )}

        <div sx={{ gridArea: 'b' }}>
          <Rte content={text} indented={true} />

          {member_blocks}
        </div>

        {!!link_text && !!link && (
          <Cta
            {...multilink(link)}
            sx={{ mt: 1, width: [null, 'fit-content'], gridArea: 'c' }}
            size={null}
            className="Cta"
            id="book-a-meeting"
          >
            {link_text}
          </Cta>
        )}
      </Section>
    </SbEditable>
  )
}

export default ContactSection
