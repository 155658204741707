import { inset } from '@monobits/core'

export default {
  __default: {
    overflow: 'hidden',
    position: 'fixed',
    zIndex: '9',
    ...inset(0),

    p: [1, null, 'calc(var(--padding-x) * 1.5)'],
    bg: 'background',
    pointerEvents: 'none',

    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
  },
}
