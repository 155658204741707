import TextItem from './TextItem'
import { NewlineText } from '../atoms'

const Copyrights = ({ blok, ...props }) => {
  const { text } = blok ?? {}

  return (
    <TextItem {...props}>
      ©{new Date().getFullYear()} <NewlineText text={text} />
    </TextItem>
  )
}

export default Copyrights
