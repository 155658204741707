import { snakeCase } from 'lodash'

export const snakeCaseReducer = (obj) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...{ [snakeCase(key)]: value },
    }),
    {}
  )

export const parseContent = (content) => (typeof content === 'string' ? JSON.parse(content) : content)
