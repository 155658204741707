import { ArticleJsonLd } from 'gatsby-plugin-next-seo'
import { useUnmountEffect } from '@react-hookz/web'
import { Container } from '@monobits/components'
import { getSmoother } from '@boiler/smoother'
import { localize } from '@boiler/utils'
import { gsap } from 'gsap'

import { MapBloks } from '../../bloks'
import { ProjectHead } from '../../components'
import MoreProjects from '../../components/Projects/MoreProjects'

const smoothPaused = (bool) => getSmoother()?.paused(bool)

const Project = ({
  blok,
  source: { slug = '', ...bulrb } = { source: { slug: '' } },
  tags = [],
  locales,
  location,
  preview_mode = false,
  sanitize = (p) => p,
  ...props
}) => {
  const { body, title, theme, featured_image, m3u8, mp4, _uid, other_projects, credits, ...rest } = blok ?? {}
  const { mission, year, external_link } = rest ?? {}

  const _tag = (value) => localize(value, locales?.tags ?? {})
  const _string = (value) => localize(value, locales?.project ?? {})

  const tagsList = (tags ?? [])
    ?.sort((a, b) => _tag(a).localeCompare(_tag(b)))
    ?.map((v, ii) => _tag(v) + (ii !== tags?.length - 2 ? (ii !== tags?.length - 1 ? ', ' : '') : '\u00A0&\u00A0'))
    .join('')

  const related_projects = !!(other_projects ?? []).length ? (
    <MoreProjects _uid={_uid} projects={other_projects?.[0]?.projects} locales={locales} />
  ) : null

  const content = !!body && (
    <MapBloks
      id={_uid}
      items={body}
      projectInfo={{
        localize: _string,
        credits,
        tags,
        tagsList,
        title,
        mission,
        year,
        _uid,
      }}
      {...sanitize(props)}
    />
  )

  const gallery = body?.filter((i) => i?.component === 'gallery')?.[0] ?? { body: [] }

  useUnmountEffect(() => {
    gsap.to('header[data-is-sticky]', { autoAlpha: 1, duration: 0.65, ease: 'circ.out' })
    smoothPaused(false)
  })

  return (
    <>
      <Container
        sx={{
          overflow: 'hidden',
          bg: 'background',
          mb: [4, null, 'calc(var(--padding-l) + var(--padding-x))'],

          '& .Gallery:first-of-type': {
            mt: 1,
          },
        }}
        variant="box"
      >
        <ProjectHead
          title={title}
          year={year}
          link={external_link}
          locale={locales?.project ?? {}}
          amount={gallery?.body?.length}
        />
        {content}
      </Container>

      {related_projects}

      <ArticleJsonLd
        url={'https://monolith.agency/' + bulrb?.full_slug ?? ''}
        headline={title}
        images={[rest?.meta_image?.filename]}
        datePublished={bulrb?.first_published_at}
        dateModified={bulrb?.published_at}
        authorName="Monolith Agency"
        authorType="Organization"
        description={rest?.description}
        overrides={{
          '@type': 'BlogPosting', // set's this as a blog post.
        }}
      />
    </>
  )
}

export default Project
