// https://www.gatsbyjs.com/docs/adding-pagination/
import { Link } from 'gatsby'
import useStaticPagination from './useStaticPagination'

/**
 * Pages need to be created dynamically for this component to work.
 *
 * @example
 * <StaticPagination
 *   slug={slug}
 *   range={3}
 *   labels={{ prev: <Icons name="arrow-left" />, next: <Icons name="arrow-right" /> }}
 *   {...omit(pagination, ['data'])}
 * />
 */
const StaticPagination = (props) => {
  const {
    total,
    numbers,
    buttons: { first, prev, next, last },
  } = useStaticPagination(props)

  const body =
    !!total &&
    numbers.map((node) => (
      <Link
        key={node.id}
        to={node.to}
        data-current={node.current}
        sx={{
          p: '0.25em 0.5em',
          boxSizing: 'border-box',
          '&[data-current=true]': { borderBottom: '1px solid currentColor' },
        }}
      >
        {node.index}
      </Link>
    ))

  const prepend = [
    !!first.length && <Link to={first[1]}>{first[0]}</Link>,
    !!prev.length && <Link to={prev[1]}>{prev[0]}</Link>,
  ].filter(Boolean)

  const append = [
    !!next.length && <Link to={next[1]}>{next[0]}</Link>,
    !!last.length && <Link to={last[1]}>{last[0]}</Link>,
  ].filter(Boolean)

  return (
    <div sx={{ mt: 2, display: 'flex', gap: 1, svg: { width: '1.5em' } }}>
      {prepend}
      {body}
      {append}
    </div>
  )
}

export default StaticPagination
