export default {
  __default: {
    zIndex: '8',

    transform: 'translateX(calc(var(--cta-size, 6rem) + (var(--padding-x) * 3))) rotate(120deg)',

    'html[data-scroll-position="bottom"] &[data-cta="footer"]': {
      transform: 'translateX(0) rotate(0deg)',
    },
    'html[data-scroll-position="top"][data-transition=false][data-rendered=true] &[data-cta="top"],html[data-scroll-position="top"][data-transition=false][data-rendered=true] &[data-cta="404"]':
      {
        transform: 'translateX(0) rotate(0deg)',
      },

    '&[data-cta="footer"]': {
      transition: 'transform var(--cta-duration) var(--cta-ease) 0.6s',
    },

    '&[data-cta="footer"],&[data-cta="menu"]': {
      position: 'absolute',
      top: 'calc((var(--padding-x) + var(--cta-size)) * -1)',
      right: 0,
    },

    '&[data-cta="top"]': {
      position: 'absolute',
      bottom: 0,
      right: 0,

      transition: 'transform var(--cta-duration) var(--cta-ease)',
    },
    '&[data-cta="404"]': {
      position: 'absolute',
      bottom: 1,
      right: 1,

      transition: 'transform var(--cta-duration) var(--cta-ease)',
    },
  },
}
