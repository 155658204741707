import SbEditable from 'storyblok-react'
import { localize } from '@boiler/utils'

import Table from './Table'

const FeaturedMembers = ({ blok, sx, locales, sanitize = (p) => p, ...props }) => {
  const { members, _uid } = blok ?? {}

  return (
    <SbEditable content={blok} key={_uid}>
      <Table id={_uid} sx={{ ...sx }} {...sanitize(props)}>
        {members?.map((node, i) => (
          <Table.Cell
            title={[node?.content?.name ?? '', localize(node?.content?.job_title ?? '', locales?.global)]}
            id={_uid + node.uuid + i}
            key={_uid + node.uuid + i}
            showIcon={false}
            isStatic={true}
          />
        ))}
      </Table>
    </SbEditable>
  )
}

export default FeaturedMembers
