import SbEditable from 'storyblok-react'

import Table from './Table'

const OtherProjects = ({ blok, sx, sanitize = (p) => p, locales = {}, all_nodes_slugs = [], ...props }) => {
  const { projects, _uid } = blok ?? {}

  const isValidSlug = (slug = '') => !!all_nodes_slugs?.some((i) => i === slug)

  return (
    <SbEditable content={blok} key={_uid}>
      <Table id={_uid} sx={{ ...sx }} {...sanitize(props)}>
        {projects?.map((node, i) => {
          const isOk = isValidSlug(node?.full_slug ?? '')
          const _status = node?.content?.status ?? 'active'
          const status = _status === 'active' && !!!isOk ? 'coming-soon' : _status
          const external_link = node?.content?.external_link ?? null
          const label = status !== 'active'

          return (
            <Table.Cell
              title={[node?.content?.title ?? '', !!label && (locales?.locales?.[status] ?? status)]}
              link={node?.full_slug && !!!label ? '/' + node?.full_slug : null}
              href={!!label ? external_link : null}
              linkState={{ to: node?.content?.component ?? 'preview' }}
              id={_uid + node.uuid + i}
              key={_uid + node.uuid + i}
              showIcon={!!label && !!external_link ? true : null}
              disabled={!!label}
            />
          )
        })}
      </Table>
    </SbEditable>
  )
}

export default OtherProjects
