import { useThemeUI } from 'theme-ui'
import { useRef, useState, useMemo } from 'react'
import { useUpdateEffect } from '@react-hookz/web'
import { Observer } from 'gsap/Observer'

import LottiePlayer from 'lottie-react'
import { flatten } from 'lottie-colorify'

import json from '../../../assets/lottie/logo-invert.json'

const LottieLogo = ({ fallback, watch, ...props }) => {
  let lottieRef = useRef()
  const [ready, setReady] = useState(false)
  const {
    colorMode,
    theme: { rawColors },
  } = useThemeUI() ?? {}

  // const { ip: firstFrame, op: lastFrame } = json ?? {}

  const isTouch = !!Observer?.isTouch

  const playthrough = (dir = 1) => {
    lottieRef.current?.setDirection(dir)
    lottieRef.current?.play()
  }

  useUpdateEffect(() => playthrough(-1), [watch])

  const lottie = useMemo(
    () => {
      const _hex = rawColors?.text ?? '#000000'
      const hex = _hex.length === 4 ? '#' + _hex.split('#')[1] + _hex.split('#')[1] : _hex
      const colorized = flatten(hex, json)

      return !!json ? (
        <>
          {!ready && <div>{fallback}</div>}

          <LottiePlayer
            lottieRef={lottieRef}
            animationData={colorized}
            autoplay={false}
            loop={false}
            onMouseEnter={!!!isTouch ? () => playthrough(1) : null}
            onMouseLeave={!!!isTouch ? () => playthrough(-1) : null}
            onDOMLoaded={() => setReady(true)}
            sx={{
              variant: 'icon.variants',
              ...(!ready && { position: 'absolute', visibility: 'hidden' }),
            }}
            {...props}
          />
        </>
      ) : null
    },
    [ready, colorMode] // eslint-disable-line
  )

  return lottie
}

export default LottieLogo
