import { forwardRef } from 'react'
import { Link } from 'gatsby'
import { Button } from '@monobits/components'
import { Transition } from '@boiler/framer'
import { localize } from '@boiler/utils'

import { useData } from '../hooks'

const HireUsButton = forwardRef(({ sx, wrapper = [], ...props }, ref) => {
  const { settings = {}, locales = {} } = useData() ?? { settings: {}, locales: {} }

  const { children, url } = {
    url: localize(settings?.hire_us_form ?? '', locales?.typeforms, '/'),
    children: settings?.hire_us_title ?? '',
  }

  const body = (
    <Button as="a" href={url} className="hire-us-cta" target="_blank" rel="noreferrer noopener" size="cta">
      <span>{children}</span>
    </Button>
  )

  return children ? (
    <>
      <div ref={ref} sx={{ variant: 'hire_us', ...sx }} {...props}>
        <Transition as="div" type="freeze" watch={({ lang }) => lang?.iso ?? {}}>
          {body}
        </Transition>
      </div>
    </>
  ) : null
})

const HomeButton = ({ sx, to, wrapper = [], ...props }) => {
  const { locales = {} } = useData() ?? { locales: {} }

  const text = locales?.locales?.home

  const body = (
    <Button as={Link} to={to} size="cta">
      <span>{text}</span>
    </Button>
  )

  return text ? (
    <>
      <div sx={{ variant: 'hire_us', ...sx }} {...props}>
        <Transition as="div" type="freeze" watch={({ lang }) => lang?.iso ?? {}}>
          {body}
        </Transition>
      </div>
    </>
  ) : null
}

HireUsButton.Home = HomeButton

export default HireUsButton
