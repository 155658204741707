export const four = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 60.709 80.493" style="enable-background:new 0 0 60.709 80.493;" xml:space="preserve">
<path d="M49.85596,51.77747V-0.00048H39.568L0.00001,51.21247v9.15704h40.35999v20.12396h9.49597V60.36951h10.85303v-8.59204
  H49.85596z M40.35999,51.77747H9.94898l30.41101-39.34198V51.77747z"/>
</svg>
`

export const zero = `
<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.33 84.11"><path d="M0,42.06C0,22.39,5.31,0,29.16,0c4.53,0,6.44,4,6.76,5,0,0-5.07,3.61-6.76,3.61C16.39,8.59,10,19.79,10,42.06S16.4,75.52,29.17,75.52,48.39,64.33,48.39,42.06,41.94,8.59,29.17,8.59c-1.68,0-7-3-7-3,.65-1.34,2.49-5.55,7-5.55C53,0,58.34,22.39,58.34,42.06S53,84.11,29.17,84.11,0,61.73,0,42.06Z"/></svg>
`
