import { Icons, Link } from '../atoms'

const SocialItem = ({ blok, component = 'nav_item', ...props }) => {
  const { link, icon } = blok ?? {}

  return (
    <Link className="link" link={link} title={icon ?? null} aria-label={icon ?? null} {...props}>
      {typeof icon === 'string' && <Icons name={icon} />}
    </Link>
  )
}

export default SocialItem
