import { gsap } from 'gsap'
import { CustomEase } from 'gsap/CustomEase'
import { TextPlugin } from 'gsap/TextPlugin'

gsap.registerPlugin(TextPlugin, CustomEase)

CustomEase.create('progress', 'M0,0 C0.104,0.204 0.054,0.624 1,1')

gsap.registerEffect({
  name: 'userSelect',
  effect: (targets, config = false) => {
    if (config.value !== true) {
      return gsap.set(targets, { '--user-select': config.value })
    } else {
      return gsap.set(targets, { clearProps: '--user-select' })
    }
  },
  extendTimeline: true,
})
