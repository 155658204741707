import { Container } from '@monobits/components'

import { Physics } from '../components'
import { useComponent, useData } from '../hooks'

const Home = ({ blok }) => {
  const { text } = blok ?? {}
  const { head } = useComponent() ?? {}

  const { rendered: renderState } = useData() ?? {}
  const rendered = (renderState ?? [])?.[0]

  return (
    <section sx={{ variant: 'head.physics' }}>
      <Container as="div" variant="box" sx={{ variant: 'head.physics.content' }}>
        {head({ text, 'data-fluid-height': true })}
      </Container>

      <div sx={{ variant: 'head.physics.container' }}>
        {!!rendered && <Physics scene={blok} sx={{ variant: 'head.physics.canvas' }} />}
      </div>
    </section>
  )
}

export default Home
