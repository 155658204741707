import { inset } from '@monobits/core'

export default {
  __default: {
    overflow: 'hidden',

    position: 'relative',
    video: {
      size: '100%',
      objectFit: 'var(--object-fit)',
      objectPosition: 'var(--object-position)',
    },

    '&[data-ready] video': {
      opacity: 0,
      position: 'absolute',
      ...inset(0),
    },
    '&[data-ready=true] video': {
      opacity: 'var(--strict-opacity, 1)',
    },

    '&[data-with-transition=true]>video': {
      transition: 'var(--transition, none)',
    },
    '&[data-inset=true]': {
      position: 'absolute',
      ...inset(0),
    },
  },
}
