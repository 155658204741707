import { useRecoilState } from 'recoil'
import { useConditionalEffect, useUpdateEffect } from '@react-hookz/web'
// import { useTween } from '@monobits/gsap'

import Footer from './Footer'
import { menuOpenState, projectBgPlayState } from '../states'
import { useTween } from '../hooks'

const Menu = ({ watch = [], cta = null, ...props }) => {
  const [isOpen, setIsOpen] = useRecoilState(menuOpenState)
  const [bgIsPlaying, setBgIsPlaying] = useRecoilState(projectBgPlayState)

  const [tween, element] = useTween(({ gsap, node }) => {
    return {
      open: () =>
        gsap
          .timeline({ paused: true, defaults: { overwrite: 'auto' } })
          .set('[data-gsap="menu-d"]', { clearProps: 'all' })
          .set('[data-gsap="burger"]', { pointerEvents: 'none' })
          .add('start')
          .to(node, { autoAlpha: 1, duration: 0.65, ease: 'circ.out' }, 'start')
          .add('mid')
          .to('[data-gsap="menu-a"]', { opacity: 1, y: 0, stagger: 0.05, duration: 0.65, ease: 'circ.out' }, '>25%')
          .to('[data-gsap="menu-b"]', { opacity: 1, y: 0, stagger: 0.05, duration: 0.65, ease: 'circ.out' }, '<25%')
          .to('[data-gsap="menu-c"]', { opacity: 1, y: 0, duration: 0.35, ease: 'circ.out' }, '<25%')
          .to('[data-gsap="menu-d"]', { opacity: 1, x: 0, rotation: 0, duration: 1.2, ease: 'circ.out' }, 'mid+=0.4')
          .set('[data-gsap="burger"]', { clearProps: 'pointer-events' }),
      close: () =>
        gsap
          .timeline({
            paused: true,
            defaults: { overwrite: 'auto' },
            onComplete: () => {
              setBgIsPlaying(true)
            },
          })
          .set('[data-gsap="burger"]', { pointerEvents: 'none' })
          .fromTo(
            '[data-gsap="menu-a"],[data-gsap="menu-b"],[data-gsap="menu-c"],[data-gsap="menu-d"]',
            {
              opacity: 1,
            },
            {
              opacity: 0,
              duration: 0.7,
              ease: 'circ.out',
            }
          )
          .fromTo(node, { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.6, ease: 'circ.out' }, '+=0.1')
          .set(
            [
              node,
              '[data-gsap="menu-a"],[data-gsap="menu-b"],[data-gsap="menu-c"],[data-gsap="menu-d"],[data-gsap="burger"]',
            ],
            {
              clearProps: 'all',
            }
          ),
    }
  })

  useConditionalEffect(
    () => {
      if (!!isOpen) tween?.open()?.play()
      if (!!!isOpen) tween?.close()?.play()

      !!bgIsPlaying && setBgIsPlaying(false)

      document.documentElement?.setAttribute('data-menu-open', !!isOpen)
    },
    [isOpen],
    [tween != null],
    undefined,
    useUpdateEffect
  )

  useConditionalEffect(
    () => {
      setIsOpen(false)
    },
    watch,
    [!!isOpen],
    undefined,
    useUpdateEffect
  )

  return (
    <section ref={element.ref} sx={{ variant: 'menu.overlay' }} aria-hidden={!!!isOpen} {...props}>
      <Footer freeze as="div" withGSAP cta={cta} />
    </section>
  )
}

export default Menu
