import { useSmoothEffect } from '@boiler/smoother'
import { useBreakpoint } from '@boiler/utils'
import { Rte } from '../../atoms'

const GalleryBase = ({ children, blok: { fit, size, direction, smooth = {}, content } = {}, sx, ...props }) => {
  const { index } = useBreakpoint()

  const [ref, attributes] = useSmoothEffect([], { speed: index > 1 ? smooth?.value : null })

  const cleanup = content?.content?.filter((i) => 'content' in i)
  const hasContent = !!content && !!cleanup?.length
  const prevent_scope = ['full', 'half'].every((i) => size !== i) && !!!hasContent

  const body = (
    <div
      ref={ref}
      {...attributes}
      sx={sx}
      data-fit={fit}
      data-size={size}
      data-scroll-direction={direction}
      data-has-content={hasContent}
      {...props}
    >
      {typeof children === 'function' ? children(prevent_scope) : children}

      <Rte content={content} variant="text" />
    </div>
  )

  return body
}

export default GalleryBase
