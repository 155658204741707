import { createContext, useContext } from 'react'
import { useScrollSmoother } from '.'
import { COMPONENTS } from '../__consts'

export const SmootherContext = createContext()
export const useSmoother = () => useContext(SmootherContext)

const Smoother = ({ children, id = 'root', prefix = '', configs = {}, wrapper: wr = null, content: ct = null }) => {
  const ids = { wrapper: `#${id}-wrapper`, content: `#${id}-content` }

  const get = (key = 'content') => (ids?.[key] != null ? document?.querySelector(ids?.[key]) : null)

  return (
    <SmootherContext.Provider
      value={{
        id,
        ids,
        prefix,
        configs,
        get,
        wrapper: makeComponent(wr, 'wrapper'),
        content: makeComponent(ct, 'content'),
      }}
    >
      {typeof children === 'function' ? children({ get, id, ids, prefix }) : children}
    </SmootherContext.Provider>
  )
}

const makeComponent = (array, key = 'content') => {
  const [Component = 'div', props = {}] = array ?? COMPONENTS?.[key] ?? ['div', {}]
  return { Component, props }
}

const SmootherWrapper = ({ children, watch = null }) => {
  const { id, wrapper, content } = useSmoother() ?? {}

  useScrollSmoother(true, { watch, predicates: [watch != null] })

  return (
    <wrapper.Component id={`${id}-wrapper`} data-smoother-wrapper {...wrapper?.props}>
      <content.Component id={`${id}-content`} data-smoother-content {...content?.props}>
        {children}
      </content.Component>
    </wrapper.Component>
  )
}

Smoother.Provider = Smoother
Smoother.Wrapper = SmootherWrapper

export default Smoother
