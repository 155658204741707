import { forwardRef } from 'react'
import { useRecoilState } from 'recoil'
import { Burger } from '@monobits/components'
import { useModes } from '@monobits/color-transitions'
import Component from './Header.component'

import { useData } from '../../hooks'
import { Icons } from '../../atoms'
import { Bloks, LanguageSwitcher } from '../../bloks'

import LottieLogo from './LottieLogo'
import { menuOpenState } from '../../states'

const Header = forwardRef(({ themed, location, ...props }, ref) => {
  const {
    lang,
    settings = {},
    locales: { locales = {} },
  } = useData() ?? { settings: {}, locales: {}, lang: {} }
  const context = useModes()

  const [menuIsOpen, setMenuIsOpen] = useRecoilState(menuOpenState)

  const { menu } = settings ?? {}

  const homePath = lang?.nullish ? '/' + lang?.nullish : '/'
  const nav =
    menu &&
    menu.map((item, i) => (
      <Bloks
        style={{ order: i + 1 }}
        className="-show-desktop-block"
        data-nav-item="item"
        inverted
        offset
        allowSpecial
        blok={item}
        key={item._uid}
      />
    ))

  const ThemeSwitcher = ({ mode, context, ...props }) => {
    return (
      <button
        data-nav-item="theme"
        sx={{
          display: themed ? 'none' : 'flex',
          flexDirection: 'column',

          justifyContent: 'center',
          alignItems: 'center',

          m: 'var(--magic)',
          size: 'var(--switch-out)',

          '::after': {
            content: '""',
            size: 'var(--switch-in)',
            borderRadius: '50%',
            'html[theme-mode="light"] &': { bg: 'dark' },
            'html[theme-mode="dark"] &': { bg: 'light' },
            bg: mode,
          },
        }}
        onClick={() => context.setTarget(mode)}
        title={mode + ' mode'}
        {...props}
      />
    )
  }

  const lang_switcher = <LanguageSwitcher {...lang} data-nav-item="lang" location={location} />

  return (
    <Component
      ref={ref}
      logo={<LottieLogo watch={location?.key} fallback={<Icons name="logo-invert" variant="scale" />} />}
      logoAriaLabel={locales?.['back-to-home'] ?? 'Back to home'}
      homePath={homePath}
      {...props}
    >
      <div data-nav="secondary">
        <ThemeSwitcher mode={context?.toggle} context={context} />
        {lang_switcher}
      </div>

      <div data-nav="primary">
        <ThemeSwitcher sx={{ mr: [0, null, '6rem'] }} mode={context?.toggle} context={context} />
        {nav}

        <div className="-show-desktop-block" style={{ order: (menu ?? []).length - 1 }}>
          {lang_switcher}
        </div>
      </div>

      <div data-tool="footer">
        {lang_switcher}
        <ThemeSwitcher mode={context?.toggle} context={context} />
      </div>

      <Burger
        className="-hide-desktop"
        data-gsap="burger"
        aria-label={locales?.[!!!menuIsOpen ? 'open' : 'close'] + ' ' + locales?.menu ?? 'Menu'}
        active={!!menuIsOpen}
        onClick={() => setMenuIsOpen((prev) => !!!prev)}
      />
    </Component>
  )
})

export default Header
