import SbEditable from 'storyblok-react'
import { useBreakpoint } from '@boiler/utils'

import { MapBloks } from '../../bloks'
import GalleryBase from './GalleryBase'

const inviewProps = { 'data-scroll-offset': '25%,0' }

const GalleryBlock = ({
  blok,
  sx,
  cycleStyles = {},
  autoplay = false,
  index: i = 0,
  sanitize = (p) => p,
  ...props
}) => {
  const {
    asset,
    content,
    scope = [],
    size,
    direction,
    fit,
    ratio,
    multi_ratio,
    align_x,
    align_y,
    mobile_push_back,
    _uid,
  } = blok ?? {}
  const { breakpoint } = useBreakpoint()

  const cleanup = content?.content?.filter((i) => 'content' in i)
  const hasContent = !!content && !!cleanup?.length
  const media_type = asset?.[0]?.component
  const scope_media_type = scope?.[0]?.component

  const list = multi_ratio?.list ?? []
  const get_ratio = list?.map((i) => (i?.text === 'default' ? null : i?.text))?.filter(Boolean)
  const has_ratio = !!get_ratio?.length
  const ratios = has_ratio ? get_ratio : !!ratio ? ratio : null
  const position = `${align_x ?? 'center'} ${align_y ?? 'center'}`

  const hasScope = Array.isArray(scope) && !!scope?.length
  const prevent_scope = ['full', 'half'].every((i) => size !== i) && !!!hasContent

  const newOrderValue = !!mobile_push_back
    ? +mobile_push_back < 0
      ? +mobile_push_back - 1
      : +mobile_push_back + 1
    : null
  const newOrder = breakpoint?.name != null && breakpoint?.name !== 'desktop' ? newOrderValue : null

  const body = (
    <GalleryBase
      style={{ order: newOrder != null ? `calc(var(--order) + ${newOrder})` : null }}
      blok={blok}
      {...sanitize(props)}
    >
      <div sx={cycleStyles} data-media-type={media_type} data-inview="media" {...inviewProps}>
        {!!asset && (
          <MapBloks
            id={_uid}
            items={asset}
            childProps={{
              image: { fit, ratio: ratios, position },
              video: {
                fit,
                ratio: ratios,
                position,
                autoplay: true,
                eager: i === 0,
                lazy: { play: true, once: false },
              },
              lottie: { autoplay: true, loop: true },
            }}
          />
        )}
      </div>
    </GalleryBase>
  )

  return (
    <SbEditable content={blok} key={_uid}>
      {!!hasScope && prevent_scope && breakpoint !== 'mobile' ? (
        <div
          style={{ '--span': 3, order: newOrder != null ? `calc(var(--order) + ${newOrder})` : null }}
          sx={cycleStyles}
          data-scoped-section
          data-from-size={size}
          data-from-direction={direction}
        >
          {body}

          <div data-is-scoped>
            <MapBloks
              wrapperProps={{
                'data-media-type': scope_media_type,
                'data-inview': 'media',
                ...inviewProps,
              }}
              items={scope}
              id={_uid}
            />
          </div>
        </div>
      ) : (
        body
      )}
    </SbEditable>
  )
}

export default GalleryBlock
