import { createContext, useContext } from 'react'
import { getDocument } from 'ssr-window'
import { omit } from 'lodash'

export const ImageContext = createContext()
export const useImage = () => useContext(ImageContext)

export const document = getDocument()

const ImageProvider = ({ children, value }) => <ImageContext.Provider value={value}>{children}</ImageContext.Provider>

export default ImageProvider
