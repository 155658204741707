import { forwardRef } from 'react'
import { Button as Atom, Text } from '@monobits/components'
import { setVariantString } from '@monobits/theme-core'

import Icons from './Icons'

const Button = forwardRef(({ icon, children, layout, sx, font = { variant: 'caption' }, ...props }, ref) => {
  const alignment = icon ? 'withicon' : layout

  return (
    <Atom
      ref={ref}
      sx={{ '&': { variant: setVariantString('button.layout', alignment) }, ...sx }}
      data-with-icon={!!icon}
      {...props}
    >
      <div>{!!icon && (typeof icon === 'string' ? <Icons name={icon} /> : icon)}</div>
      {!!children && (
        <Text as="span" {...font}>
          {children}
        </Text>
      )}
    </Atom>
  )
})

export default Button
