import { atom } from 'recoil'

export const menuOpenState = atom({
  key: 'menuOpenState',
  default: false,
})

export const projectBgData = atom({
  key: 'projectBgData',
  default: [],
})

export const projectBgPlayState = atom({
  key: 'projectBgPlayState',
  default: false,
})

export const projectBgDisplayState = atom({
  key: 'projectBgDisplayState',
  default: false,
})
