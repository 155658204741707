import { Link } from 'gatsby'
import { useComponent } from '../hooks'

const LanguageSwitcher = ({ location, alternates, iso: lang, variant = 'primary', sx, ...props }) => {
  const { language_switcher } = useComponent() ?? {}
  if (!alternates) return null

  const params = location?.search ?? ''
  const current_state = location?.state ?? {}

  return Object.entries(alternates)
    .filter((iso) => iso[0] !== lang)
    .map(([key, value]) => {
      const to = value || '/'

      return language_switcher({
        as: Link,
        to: !!params ? to + '/' + params : to,
        key: 'lang' + key,
        value: key,
        state: { from: current_state?.from, to: current_state?.from },
        ...props,
      })
    })
}

export default LanguageSwitcher
