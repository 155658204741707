import { memo } from 'react'
import SbEditable from 'storyblok-react'
import { useComponent } from '../hooks'
import { HireUsButton } from '../components'

const Head = ({ blok, sanitize = (p) => p, ...props }) => {
  const { text } = blok ?? {}
  const { head } = useComponent() ?? {}

  return (
    <SbEditable content={blok} key={blok._uid}>
      {head({ text, children: <HireUsButton data-cta="top" />, ...sanitize(props) })}
    </SbEditable>
  )
}

export default memo(Head)
