import { createContext, useContext } from 'react'
import { omit } from 'lodash'
import { CONFIGS } from '../__consts'

export const ObserverContext = createContext()
export const useObserverContext = () => useContext(ObserverContext)

const ObserverProvider = ({ children, options = {}, inview = {} }) => {
  const inview_configs = { ...(CONFIGS?.inview ?? {}), ...inview }
  const options_configs = { ...(CONFIGS?.options ?? {}), ...options }
  const { offset } = options_configs ?? {}

  return (
    <ObserverContext.Provider
      value={{
        inview: inview_configs,
        options: omit(
          {
            ...options_configs,
            rootMargin: !!!('offset' in options_configs) ? options_configs?.rootMargin : `-0% 0% ${offset} 0%`,
          },
          ['offset']
        ),
      }}
    >
      {children}
    </ObserverContext.Provider>
  )
}

export default ObserverProvider
