import { useRef } from 'react'
import { useConditionalEffect, useUpdateEffect } from '@react-hookz/web'

const useHlsPlayer = (effect = null, dependencies = []) => {
  let playerRef = useRef(null)
  const player = playerRef?.current ?? {}

  useConditionalEffect(
    () => effect(playerRef?.current ?? {}),
    [JSON.stringify({ effect, ...dependencies })],
    [effect != null],
    undefined,
    useUpdateEffect
  )

  return [playerRef, player]
}

export default useHlsPlayer
