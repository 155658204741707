import { useEffect } from 'react'
import { Container } from '@monobits/components'
import { useScrollSmoother } from '@boiler/smoother'
import { useViewAttribute } from '@boiler/intersection-observer'
import { useIsPresent } from 'framer-motion'

import { Bloks } from '../bloks'
import NotFound from './404'
import Home from './Home'
import Page from './Page'
import Project from './Project/Project'
import Projects from './Projects'

import Footer from '../components/Footer'
import HireUsButton from '../components/HireUsButton'

const Template = ({ component, ...props }) => {
  const Component =
    {
      global: Bloks,
      'not-found': NotFound,
      home: Home,
      page: Page,
      project: Project,
      projects: Projects,
    }[component] || Bloks

  const isPresent = useIsPresent()

  const hasFooter = !!!['home', 'not-found'].some((i) => i === component)

  useScrollSmoother(true)

  // in-view active on all templates
  useViewAttribute({}, [isPresent], [!!isPresent])

  useEffect(() => {
    document.documentElement.setAttribute('data-transition', !!!isPresent)
  }, [isPresent])

  if (component === 'global') {
    return (
      <Container variant="box">
        <Component {...props} />
      </Container>
    )
  }

  return (
    <>
      <Component {...props} />
      {!!hasFooter && <Footer freeze cta={<HireUsButton data-cta="footer" />} />}
    </>
  )
}

export default Template
