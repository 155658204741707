import { MapBloks } from '../../bloks'
import Motion from './Asset.motion'

const Asset = ({ blok, wrapperProps = {}, childProps = {}, ...props }) => {
  const { component, asset, _uid, ratio, multi_ratio, fit, align_x, align_y } = blok ?? {}

  const list = multi_ratio?.list ?? []
  const get_ratio = list?.map((i) => (i?.text === 'default' ? null : i?.text))?.filter(Boolean)
  const has_ratio = !!get_ratio?.length
  const ratios = has_ratio ? get_ratio : !!ratio ? ratio : null
  const position = `${align_x ?? 'center'} ${align_y ?? 'center'}`

  const element = !!asset && (
    <MapBloks
      id={_uid}
      items={asset}
      childProps={{
        image: { fit, ratio: ratios, position },
        player: { fit, ratio: ratios, position },
        video: { fit, ratio: ratios, position, lazy: { play: true, once: false } },
        lottie: { autoplay: true, loop: true },
        ...(childProps ?? {}),
      }}
      {...props}
    />
  )

  if (!!(component === 'asset-plus') && blok?.distance?.value !== 0) {
    return (
      <Motion distance={blok?.distance?.value} json={blok?.json ?? '{}'}>
        {element}
      </Motion>
    )
  }

  return <div {...wrapperProps}>{element}</div>

  // return breakpoint > 1 && blok?.speed?.value !== 0 ? (
  //   <Locomotion speed={blok?.speed?.value}>
  //     <div {...wrapperProps}>{element}</div>
  //   </Locomotion>
  // ) : (
  //   <div {...wrapperProps}>{element}</div>
  // )
}

export default Asset
