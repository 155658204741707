import { makeFluids } from '@monobits/scales'

const fluid = makeFluids(
  {
    'padding-s': ['16px', '24px', false],
    'padding-x': ['20px', '40px'],
    'padding-m': ['60px', '90px'],
    'padding-l': ['80px', '248px'],
    'padding-xl': ['120px', '460px'],
    'header-height': ['30px', '40px', false],
  },
  ['375px', '2500px']
)

export const rootScaleStyles = {
  ...fluid.properties,
  '--stroke-x': '1px',
  '--stroke-s': '1px',
  '--stroke-m': '2px',
  '--main-gap': '1.25em',
  '--icon-width': '1.5em',
}

export default {
  space: fluid.scale,
  borderWidths: [0, 1, 2],
  radii: [0, 'var(--padding-s)'],
  sizes: {
    icon: '3.5rem',
  },
  zIndices: {
    below: -1,
    default: 1,
    subnav: 9,
    nav: 10,
    popup: 20,
    panel: 30,
    modal: 40,
    scrollbar: 50,
  },
}
