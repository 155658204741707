import { useDebouncedState } from '@react-hookz/web'
import { useObserver, useObserverContext } from '.'

const useViewState = (selectors = [], { options = {}, inview = {} } = {}, dependencies = [], predicates = []) => {
  const { inview: global_options } = useObserverContext() ?? {}
  const { once, disabled } = { ...global_options, ...inview }

  const watch = JSON.stringify({ once, disabled, ...dependencies })

  const [intersecting, setIntersecting] = useDebouncedState(inview?.eager || null, 600)

  const ref = useObserver(
    selectors,
    (entry, obs) => {
      if (!!!once) setIntersecting(!!entry?.isIntersecting)
      if (!!once && !!entry?.isIntersecting) {
        setIntersecting(true)
        obs?.unobserve(entry?.target)
      }
    },
    options,
    [watch],
    [!!!disabled, ...predicates]
  )

  return [ref, intersecting]
}

export default useViewState
