import { useState } from 'react'
import { useUpdateEffect } from '@react-hookz/web'
import { willChangeEvents } from '@monobits/gsap'
import { Heading } from '@monobits/components'
import { useBreakpoint } from '@boiler/utils'
import { gsap } from 'gsap'

import { Underline, Icons } from '../../atoms'
import { useTween } from '../../hooks'

const Filters = ({ initialActive, location, locale, caption, tags, check, selected, dispatch, count }) => {
  const [active, setActive] = useState(initialActive)
  const { index } = useBreakpoint()

  const clear = () => {
    dispatch('all')
    !!location?.search && window.history.replaceState({}, null, location.pathname)
  }

  const duration = 0.45

  const isMobile = index != null && index <= 1
  const mobile = [{ height: 0 }, { height: 'auto' }][initialActive ? 0 : 1]

  const desktop = [
    { autoAlpha: 0, y: '0.8rem' },
    { autoAlpha: 1, y: 0 },
  ][initialActive ? 0 : 1]

  const [tween, element] = useTween(
    ({ gsap, node }) => {
      let tl = gsap
        .timeline({
          paused: true,
          [!!!initialActive ? 'onReverseComplete' : 'onComplete']: clear,
          ...(isMobile ? willChangeEvents(node, 'height') : {}),
        })
        .to(node, { ...(isMobile ? mobile : desktop), duration, ease: 'power1.inOut' })

      if (isMobile) tl.to(node, { clearProps: 'willChange', duration: 0 })

      return tl
    },
    [isMobile]
  )

  useUpdateEffect(() => {
    setActive(false)
    element.current != null && gsap.set(element.current, { clearProps: 'all' })
  }, [isMobile])

  useUpdateEffect(() => {
    if ((!!!initialActive && !!active) || (!!initialActive && !active)) {
      tween?.play()
    } else {
      tween?.reverse()
    }
  }, [active])

  return (
    <div
      sx={{ variant: 'filters' }}
      data-breakpoint={isMobile ? 'mobile' : 'desktop'}
      aria-expanded={!!active}
      data-initial={!!initialActive}
    >
      <Underline
        as="button"
        variant="text"
        sx={{ variant: 'filters.trigger' }}
        inverted
        onClick={() => setActive((prev) => !prev)}
      >
        {caption}{' '}
        <span data-icon="expand">
          <Icons name="plus" />
        </span>
      </Underline>

      <div ref={element.ref} data-nav>
        <Underline as="button" variant="text" onClick={clear} data-active={!!!selected?.length} inverted>
          {locale?.all ?? 'All'}
        </Underline>

        {tags
          .sort((a, b) => {
            const loc = (key) => locale?.[key.handle] ?? key.name
            return loc(a).localeCompare(loc(b))
          })
          .map((node, i) => (
            <Underline
              as="button"
              variant="text"
              key={node.handle + i}
              onClick={() => dispatch(node.technical_name)}
              data-active={check(node.technical_name)}
              inverted
            >
              <span>{node.name}</span>
              {/*
                NOTE: This shows the sum count of both projects & experiments
                <sup>({node.taggings_count})</sup>
              */}
            </Underline>
          ))}
      </div>

      {!!(count ?? 0) && (
        <Heading as="span" variant="text" sx={{ variant: 'filters.counter' }}>
          ({count})
        </Heading>
      )}
    </div>
  )
}

export default Filters
