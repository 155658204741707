import { useObserver, useObserverContext } from '.'

const useViewAttribute = ({ options = {}, ...props } = {}, dependencies = [], predicates = []) => {
  const { inview: global_options } = useObserverContext() ?? {}
  const { key, once, disabled } = { ...global_options, ...props }

  useObserver(
    [`[${key}]`],
    (entry, obs) => {
      entry?.target?.setAttribute(`${key}-visible`, !!entry?.isIntersecting)
      if (!!once && !!entry?.isIntersecting) obs?.unobserve(entry?.target)
    },
    options,
    [once, disabled, ...dependencies],
    [!!!disabled, ...predicates]
  )
}

export default useViewAttribute
