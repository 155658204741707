/**
 * Take a width and height and return the lowest ratio
 *
 * @example
 * console.log(lowestRatio(110, 120)); // Output: [11, 12]
 */
export const lowestRatio = (width, height) => {
  const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b))

  if (!Number.isInteger(width) || !Number.isInteger(height)) {
    return [width, height]
  }

  const divisor = gcd(width, height)
  const lowestRatio = [width / divisor, height / divisor]

  const gcdRatio = gcd(...lowestRatio)

  return lowestRatio.map((value) => value / gcdRatio)
}

export const ogRatioPlugin = (player) => {
  player.ready(() => {
    player.one('loadedmetadata', () => {
      const element = player.el()
      const parent = element.parentNode.parentNode

      const [width, height] = [player.videoWidth(), player.videoHeight()]

      if (parent && !!width) {
        parent.style.setProperty('--og-aspect-ratio', lowestRatio(width, height).join('/'))
      }
    })
  })
}
