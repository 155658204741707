import { useRef, useState, useEffect } from 'react'
import { useConditionalEffect, useResizeObserver, useDebouncedState } from '@react-hookz/web'
import { gsap } from 'gsap'
import { Observer } from 'gsap/Observer'

const $ = (obj) => obj?.current
const OPTIONS = {
  lerp: 0.5,
  threshold: 100,
  debounce: 1000,
  fps: [45, 100],
}

const utils = {
  lerp: (value, delta) => (value === 1 || value === 0 ? 1.0 : 1.0 - Math.pow(1.0 - value / 10, delta)),
  distance: (x1, y1, x2, y2) => Math.hypot(x2 - x1, y2 - y1),
}

const useCustomCursor = (
  [container, element],
  { options: overwrites = {}, axis: [x, y] = [true, true], setter, defaultHover } = {}
) => {
  const options = { ...OPTIONS, ...overwrites }

  const isTouch = !!Observer?.isTouch

  const [hover, setHover] = useState(defaultHover)
  const [resize, setResize] = useDebouncedState({}, options.debounce)
  useResizeObserver(container, (e) => setResize(e.contentRect))

  let pos = useRef(null)
  let mouse = useRef(null)
  let cached = useRef(null)

  useEffect(() => {
    let observer = Observer.create({
      target: container,
      type: 'pointer',
      onMove: (self) => {
        const rect = container.getBoundingClientRect()
        mouse.current.x = self.x - rect.left
        mouse.current.y = self.y - rect.top
      },
    })

    return () => !!observer && observer?.kill()
  }, [container])

  useConditionalEffect(
    () => {
      pos.current = { x: 0, y: 0 }
      mouse.current = { ...$(pos) }
      cached.current = { ...$(mouse) }

      if (element) {
        gsap.set(element, {
          xPercent: x ? -50 : null,
          yPercent: y ? -50 : null,
        })
      }

      const _set = (value, unit = 'px') =>
        element ? (setter ? setter({ gsap, ref: element, value }) : gsap.quickSetter(element, value, unit)) : null

      gsap.ticker.add(() => {
        gsap.ticker.fps(options.fps[1])

        const amt = utils.lerp(options.lerp, gsap.ticker.deltaRatio())

        $(pos).x += ($(mouse).x - $(pos).x) * amt
        $(pos).y += ($(mouse).y - $(pos).y) * amt

        if (element && x) _set('x')($(pos).x)
        if (element && y) _set('y')($(pos).y)
      })
    },
    [resize, isTouch],
    [!isTouch]
  )

  return {
    pos: $(pos),
    mouse: $(mouse),
    cached: $(cached),
    hover: [hover, setHover],
    is: { touch: isTouch, mouse: !isTouch },
  }
}

export default useCustomCursor
