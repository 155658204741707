import { useRef } from 'react' // eslint-disable-line
import { detect } from 'detect-browser'

import { useMediaQuery, useConditionalEffect } from '@react-hookz/web'
import { _set, _asArray, useDebounceRect } from '@monobits/core'

import { polyfill } from './viewport-units-polyfill'

const Sizes = ({ callbacks = [], debounce = 300, ...props }) => {
  let set = useRef(false)
  const [ref, { height = null }] = useDebounceRect(debounce)

  const isMobile = useMediaQuery('(hover: none)', false)
  const watch = [..._asArray(callbacks), isMobile]

  const browser = detect()

  // Update on height change
  // useConditionalEffect(() => _CSSVH(height, 'x'), [...watch, height], [height != null, !!isMobile])
  useConditionalEffect(polyfill, [...watch, height], [height != null, !!isMobile])

  useConditionalEffect(
    () => {
      document.documentElement?.setAttribute('data-browser', browser?.name)
    },
    [browser],
    [browser != null]
  )

  // Update only once
  useConditionalEffect(
    () => {
      set.current = true
      _CSSVH(height)
    },
    [...watch, height],
    [height != null, !!!set.current, !!isMobile]
  )

  // refresh on watch
  useConditionalEffect(() => _CSSVH(height), [...watch], [!!set.current, !!isMobile])

  return <div ref={ref} sx={styles} {...props} />
}

const _CSSVH = (value, key) => _set.cssvar(`--vh${key ? '-' + key : ''}`, `${value * 0.01}px`)

// prettier-ignore
const styles = {
  position: 'fixed',
  top: 0, left: 0, right: 0, bottom: 0,
  pointerEvents: 'none',
  userSelect: 'none',
  zIndex: '-10',
}

export default Sizes
