import { useRef, useState, useEffect } from 'react'
import { useThemeUI } from '@theme-ui/core'
import { getWindow } from 'ssr-window'

const window = getWindow()

export const useBreakpoint = ({ defaultIndex = null, quiet = false } = {}) => {
  const context = useThemeUI()
  const theme = context?.theme ?? {}
  const breakpoints = context?.theme?.breakpoints ?? ['43em', '62em', '82em']
  const breakpointsMap = context?.theme?.breakpointsMap ?? ['mobile', 'tablet', 'desktop']

  const getIndex = () =>
    breakpoints.filter((bp) => window?.matchMedia(`screen and (min-width: ${bp})`)?.matches)?.length

  const isTouch = () => window?.matchMedia('(hover: none)')?.matches

  const matchMap = (array, index) =>
    index == null ? undefined : array?.[index >= array.length ? array.length - 1 : index]

  const getBreakpoint = (_index = null) => {
    const index = _index ?? defaultIndex
    const touch = isTouch()

    return index == null
      ? {
          index: undefined,
          name: undefined,
          device: undefined,
          touch: undefined,
          mouse: undefined,
        }
      : {
          index,
          name: matchMap(breakpointsMap, index),
          device: !!touch ? 'touch' : 'mouse',
          touch: !!touch,
          mouse: !!!touch,
        }
  }

  const getValue = (values, value = defaultIndex) => {
    const array = typeof values === 'function' ? values(theme) : values
    return matchMap(array, value)
  }

  const [value, setValue] = useState(defaultIndex)
  const quietValue = useRef(null)

  const onResize = () => {
    const newValue = getIndex()
    if (value !== newValue) setValue(newValue)
  }

  const indexListener = (effect = () => {}) => {
    const onResizeQuiet = () => {
      const newValue = getIndex()
      if (quietValue.current !== newValue) {
        quietValue.current = newValue
        effect(newValue, (array) => getValue(array, newValue))
      }
    }

    onResizeQuiet()
    window.addEventListener('resize', onResizeQuiet)
    return { removeIndexListener: () => window.removeEventListener('resize', onResizeQuiet) }
  }

  useEffect(() => {
    if (!!!quiet) {
      value === null && onResize()
      window.addEventListener('resize', onResize)
      return () => window.removeEventListener('resize', onResize)
    }
  }, [breakpoints, value])

  return {
    index: value,
    breakpoint: getBreakpoint(value),
    getIndex,
    getBreakpoint,
    indexListener,
    getValue: (values) => getValue(values, value),
  }
}

export const useResponsive = (values, options = {}) => {
  const { theme } = useThemeUI()
  const array = typeof values === 'function' ? values(theme) : values
  const [index] = useBreakpoint(options)

  return array[index >= array.length ? array.length - 1 : index]
}
