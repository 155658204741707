import { useEffect, useRef, createRef } from 'react'
import Hls from 'hls.js'

const HlsPlayer = ({ hlsConfig, playerRef = createRef(), src, mp4, autoPlay, onUnmount = () => null, ...props }) => {
  let hls = useRef(null)

  useEffect(() => {
    const _initPlayer = () => {
      if (hls.current != null) hls.current.destroy()

      if (Hls.isSupported()) {
        const newHls = new Hls({
          enableWorker: false,
          startLevel: 4,
          ...hlsConfig,
        })

        if (playerRef.current != null) {
          newHls.attachMedia(playerRef.current)
        }

        newHls.on(Hls.Events.MEDIA_ATTACHED, () => {
          newHls.loadSource(src)

          newHls.on(Hls.Events.MANIFEST_PARSED, () => {
            if (autoPlay) {
              playerRef?.current
                ?.play()
                .catch(() => console.log('Unable to autoplay prior to user interaction with the dom.'))
            }
          })
        })

        newHls.on(Hls.Events.ERROR, function (event, data) {
          if (data.fatal) {
            switch (data.type) {
              case Hls.ErrorTypes.NETWORK_ERROR:
                newHls.startLoad()
                break
              case Hls.ErrorTypes.MEDIA_ERROR:
                newHls.recoverMediaError()
                break
              default:
                _initPlayer()
                break
            }
          }
        })

        hls.current = newHls
      } else {
        playerRef.current.src = mp4
      }
    }

    // Check for Media Source support
    _initPlayer()

    return () => {
      onUnmount()
      hls.current != null && hls.current.destroy()
    }
  }, [autoPlay, hlsConfig, playerRef, src, mp4])

  // Fallback to using a regular video player if HLS is supported by default in the user's browser
  return <video ref={playerRef} autoPlay={autoPlay} {...props} />
}

export default HlsPlayer
