import { useStaticQuery, graphql } from 'gatsby'
import { pull, kebabCase } from 'lodash'

const useTags = (locale, [selection, setSelection], whitelist = [], { location }) => {
  const all_tags = useStaticQuery(graphql`
    query {
      tags: allStoryblokTag {
        edges {
          node {
            name
            taggings_count
          }
        }
      }
    }
  `)?.tags?.edges

  const tags = all_tags
    .map(({ node }) => {
      const handle = kebabCase(node.name)

      return {
        ...node,
        technical_name: node.name,
        name: locale?.[handle] ?? node.name,
        handle,
      }
    })
    .filter((i) => whitelist.some((a) => a === i?.technical_name?.toLowerCase()))

  const selectors = tags.map((i) => i.technical_name)

  return {
    tags,
    locale,
    selected: selection,
    check: (key) => selection?.some((i) => i === key),
    dispatch: (string) => {
      if (string === 'all') {
        setSelection([])
        window.history.replaceState({}, null, location.pathname)
      } else if (selectors.some((i) => i === string)) {
        setSelection((prev) => {
          const values = !!prev.some((i) => i === string) ? [...pull(prev, string)] : [...prev, string]
          const searchParams = new URLSearchParams({
            tags: values.map((ii) => ii.split(' ').join('-')).join('+'),
          })
          window.history.replaceState({}, null, location.pathname + '?' + searchParams.toString())
          return values
        })
      }
    },
  }
}

export default useTags
