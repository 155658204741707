import { useRef } from 'react'
import { useConditionalEffect } from '@react-hookz/web'
import { observer, useObserverContext } from '.'

const useObserver = (selectors = [], onUpdate = () => {}, options = {}, dependencies = [], predicates = []) => {
  const ref = useRef(null)
  const watch = JSON.stringify({ selectors, options, ...dependencies })

  const { options: global_options } = useObserverContext() ?? {}

  useConditionalEffect(
    () => {
      const feed = [...selectors, ref.current]?.filter(Boolean)
      const { disconnect } = observer(feed, { auto: true, onUpdate, options: { ...global_options, ...options } })

      return disconnect
    },
    [watch],
    [...predicates]
  ) // eslint-disable-line

  return ref
}

export default useObserver
