import SbEditable from 'storyblok-react'
import { makeFallbacki18n } from '@boiler/utils'

import { NewlineText } from '../atoms'
import { useComponent } from '../hooks'

const Heading = ({ blok, children, lang = {}, ...props }) => {
  const { is_head, _uid } = blok ?? {}
  const { heading } = useComponent() ?? {}

  const i18n = makeFallbacki18n(lang ?? {})
  const title = i18n.string('title', blok ?? {})

  return (
    <SbEditable content={blok} key={_uid}>
      {heading({
        value: children || <NewlineText text={title} id={_uid} />,
        anchor: <span className="anchor" data-anchor={_uid} />,
        wrapperProps: { 'data-handle': _uid },
        is_head,
        ...props,
      })}
    </SbEditable>
  )
}

export default Heading
