import { transitions } from 'polished'

const x = {
  height: 'var(--header-height, 100px)',
  padding: 'var(--padding-x)',
}

const in_transition = transitions(['transform', 'opacity'], 'var(--ui-duration) var(--ui-ease) var(--ui-duration)')
const out_transition = transitions(['transform', 'opacity'], 'var(--ui-duration) var(--ui-ease)')

const hide_nav_selectors = [
  'html:not([data-rendered]) & [data-gsap="burger"]',
  'html:not([data-scroll-position][data-rendered]) & [data-nav]',
  'html[data-scroll-position="top"]:not([data-menu-open=true]) & [data-nav="secondary"]',
  'html[data-scroll-position="scrolled"]:not([data-menu-open=true]) & [data-nav="secondary"]',
  'html[data-scroll-position="bottom"] & [data-nav="primary"]',
  'html[data-scroll-position="bottom"] & [data-nav="secondary"]',
  'html[data-scroll-position="bottom"] & [data-gsap="burger"]',
  'html[data-menu-open=true] & [data-nav="primary"]',
  'html:not([data-scroll-position="bottom"]) & [data-tool="footer"]',
]?.join(',')

export default {
  container: {
    '--height': x.height,
    '--speed': '0.65s',
    '--delay': 'var(--ui-duration, 0s)',
    '--ease': 'ease',

    '--switch-out': '2.25em',
    '--switch-in': '0.9em',
    '--magic': 'calc((var(--switch-out) - var(--switch-in)) * -1)',

    [hide_nav_selectors]: {
      opacity: 0,
      transform: 'translateY(1rem)',
      pointerEvents: 'none',
      ...out_transition,
    },

    '[data-tool="footer"]': {
      position: 'absolute',
      top: 0,
      right: 'calc(var(--magic) * -1)',

      height: 'var(--header-height, 100px)',

      display: 'flex',
      alignItems: 'center',
      gap: '8rem',

      ...in_transition,
    },
  },
  cell: {
    height: x.height,
    alignItems: 'center',
    position: 'absolute',
    top: 0,
  },
  logo: {
    '--logo-ratio': '35/52.5',
    '--height': `calc(${x.height} * 1.5)`,

    'html:not([data-rendered=true]) &': {
      pointerEvents: 'none',
    },

    width: 'calc(var(--height) * var(--logo-ratio))',
    height: 'var(--height)',
    position: 'absolute',
    top: 0,
    left: 0,

    '>div': {
      alignItems: 'flex-start',
      display: 'flex',
    },
  },
  nav: {
    variant: 'header.cell',
    right: 0,
    gridAutoFlow: 'column',
    gridColumnGap: 'clamp(3rem, 5vw, 10rem)',

    '[data-nav]': {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      gap: 'inherit',

      gridColumn: '1/2',
      gridRow: '1/2',

      ...in_transition,
    },
    '[data-gsap="burger"]': in_transition,
    '[data-nav="secondary"]': {
      gap: '8rem',
    },
  },
}
