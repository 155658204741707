import { Image } from '@boiler/image'
import { Video as VideoJS } from '@boiler/video'
import { useBreakpoint } from '@boiler/utils'
import { Lazy } from '@boiler/intersection-observer'

const Video = ({ blok, ratio, fit, options = {}, className }) => {
  const { m3u8 = '', mp4 = '', image, use_static_fallback = [] } = blok ?? {}

  const { breakpoint } = useBreakpoint()
  const disable_video =
    breakpoint?.name != null
      ? use_static_fallback?.some((i) => i === breakpoint?.name || i === breakpoint?.device)
      : false

  return (
    <>
      {!disable_video ? (
        <VideoJS
          mode="fluid"
          preset="passive"
          objectFit={fit}
          className={className}
          aspectRatio={ratio ?? undefined}
          options={{
            sources: [
              { src: m3u8, type: 'application/x-mpegURL' },
              { src: mp4, type: 'video/mp4' },
            ],
            ...options,
          }}
        >
          {image?.filename ? (
            <Image
              data={image}
              // params={{
              //   quality: 10,
              //   blur: 10,
              //   placeholder: true,
              // }}
              object={{ fit: 'cover', position: 'center center' }}
              inview={true}
              fitted
              inset
              // sx={{
              //   filter: 'blur(10px)',
              //   transform: 'scale(1.1)!important',
              // }}
            />
          ) : null}
        </VideoJS>
      ) : image?.filename ? (
        <Lazy inview={{ once: true }}>
          {({ ref, inview }) => (
            <Image ref={ref} data={image} object={{ fit: 'cover', position: 'center center' }} inview={inview} />
          )}
        </Lazy>
      ) : null}
    </>
  )
}

export default Video
