import { Box } from 'theme-ui'
import { Heading, Marquee } from '@monobits/components'
import { localize, makeFallbacki18n } from '@boiler/utils'
import SbEditable from 'storyblok-react'

import Table from './CollapsiblesComponent'
import { Rte } from '../atoms'

const FeaturedJobs = ({ blok, sx, locales = {}, lang = {}, sanitize = (p) => p, ...props }) => {
  const { jobs, _uid } = blok ?? {}

  const locale_ui = (key) => localize(key, locales?.locales)
  const locale_titles = (key) => localize(key, locales?.global)
  const locale_forms = (key, fallback) => localize(key, locales?.typeforms, fallback)

  const i18n = makeFallbacki18n(lang ?? {})

  return (
    <SbEditable content={blok} key={_uid}>
      <Table auto_close={true} {...sanitize(props)}>
        {jobs?.map((node, i) => {
          const { job_status, location, title, form } = node?.content ?? {}
          const { content, requirements, experience } = i18n.array(
            ['content', 'requirements', 'experience'],
            node?.content ?? {}
          )

          const form_link = locale_forms(form ?? 'job-application', null)

          const apply_parent = {
            ...(!!form_link && {
              as: 'a',
              href: form_link + '#job_title=' + locale_titles(title),
              target: '_blank',
              rel: 'noreferrer noopener',
            }),
          }
          const apply = {
            // ...(!!form_link && {
            //   as: 'a',
            //   href: form_link + '#job_title=' + locale_titles(title),
            //   target: '_blank',
            //   rel: 'noreferrer noopener',
            // }),
            children: locale_ui('apply-now'),
            sx: { py: 1, display: 'inline-flex' },
          }

          return (
            <Table.Cell
              title={[locale_titles(title)]}
              sx={{ variant: 'collapsible.jobs_list' }}
              key={_uid + node.uuid + i}
              id={_uid + node.uuid + i}
            >
              {(oppened) => (
                <>
                  <div data-element="list" data-column>
                    <Heading as="h4" variant="text">
                      {locale_ui('job-status')}: {locale_ui(job_status)}
                    </Heading>
                    <Heading as="h4" variant="text">
                      {locale_ui('experience')}: {experience}
                    </Heading>
                    <Heading as="h4" variant="text">
                      {locale_ui('location')}: {locale_ui(location)}
                    </Heading>
                  </div>

                  <div data-element="content" data-column>
                    <Rte content={content} indented variant="text" />
                    <div data-element="sep" />
                    <Rte content={requirements} variant="text" />
                  </div>

                  <Marquee
                    id={'accordion-' + node.uuid}
                    {...apply_parent}
                    paused={!!!oppened}
                    speed="20s"
                    multiplier={3}
                    sx={{
                      gridColumn: '1/-1!important',
                      '._hover >*': {
                        transform: 'skew(-12deg)',
                      },
                      '>div>*': {
                        transition: 'transform var(--ui-duration) cubic-bezier(0,0,0,1)',
                      },
                    }}
                  >
                    <Heading variant="title" cropped={false} sx={{ mr: '1em' }}>
                      <Box {...apply} />
                    </Heading>
                  </Marquee>
                </>
              )}
            </Table.Cell>
          )
        })}
      </Table>
    </SbEditable>
  )
}

export default FeaturedJobs
