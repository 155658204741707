import { useEffect, useState } from 'react'
import { useViewState } from '@boiler/intersection-observer'
import { Heading, Marquee, Section } from '@monobits/components'
import { useDynamicPagination } from '@boiler/components'
import { localize } from '@boiler/utils'
import SbEditable from 'storyblok-react'

import Table from '../Table'
import useTags from './useTags'
import Filters from './Filters'
import Card from './Card'
import { MAX_FEATURES, RATIOS } from '../../__consts'

const ListComponent = ({
  blok,
  sx,
  sanitize = (p) => p,
  locales = {},
  pagination = {},
  lang,
  location,
  all_nodes_slugs = [],
  ...props
}) => {
  const { view, _uid } = blok ?? {}

  const { childComponent, starts_with, ...rest } = pagination ?? {}

  const CACHED_VALUE = decodeURIComponent(location.search).replace('?tags=', '').split('+')
  const CACHED = !!CACHED_VALUE[0] ? CACHED_VALUE.map((ii) => ii.split('-').join(' ')) : []

  const { data, increment, available, count, tags } = useDynamicPagination([], {
    lang,
    location,
    by_tags: CACHED,
    ...rest,
    call: {
      sort_by: 'position:asc',
      filter_query: { component: { in: childComponent } },
      starts_with,
    },
  })

  const _tags = useTags(
    locales?.tags ?? {},
    tags,
    [
      'branding',
      'marketing',
      'photo',
      'social media',
      'video',
      'web',
      'web3',
      //
    ],
    { location }
  )

  const allowed = 'data' in pagination

  const isValidSlug = (slug = '') => !!all_nodes_slugs?.some((i) => i === slug)

  const entry_info = (node) => {
    const isOk = isValidSlug(node?.full_slug ?? '')
    const _status = node?.status ?? 'active'
    const status = _status === 'active' && !!!isOk ? 'coming-soon' : _status
    const external_link = node?.external_link ?? null
    const label = status !== 'active'

    return { status, external_link, label }
  }

  const FEATURED = data?.filter((a, i) => {
    const { status } = entry_info(a)
    return status === 'active' && i < MAX_FEATURES
  })
  const LISTED = data?.filter((a) => {
    return !FEATURED.some((b) => b.slug === a.slug)
  })

  return (
    <SbEditable content={blok} key={_uid}>
      <Table
        sx={{ mt: 3, visibility: 'visible', minHeight: 'calc(var(--svh, 1vh) * 75)', ...sx }}
        id={_uid}
        data-view={view}
        {...sanitize(props)}
      >
        <Filters
          {..._tags}
          caption={locales?.locales?.filters ?? 'Filters'}
          location={location}
          initialActive={!!CACHED?.length}
          count={count}
        />

        {!!FEATURED.length && (
          <div
            sx={{
              mb: 3,
              display: 'grid',
              alignItems: 'start',
              gridTemplateColumns: ['1fr', '1fr 1fr'],
              columnGap: 1,
              rowGap: [2, null, 3],
            }}
          >
            {allowed
              ? FEATURED?.map((node, i) => {
                  const { status, label } = entry_info(node)
                  const _tag = (value) => localize(value, locales?.tags ?? {})

                  const tagsList = (node?.tag_list ?? [])
                    ?.sort((a, b) => _tag(a).localeCompare(_tag(b)))
                    ?.map(
                      (v, ii) =>
                        _tag(v) +
                        (ii !== node?.tag_list?.length - 2
                          ? ii !== node?.tag_list?.length - 1
                            ? ', '
                            : ''
                          : '\u00A0&\u00A0')
                    )
                    .join('')

                  return (
                    <Card
                      id={_uid + node?.uuid + i}
                      key={_uid + node?.uuid + i + JSON.stringify(data)}
                      title={[node?.title ?? '', !!label && (locales?.locales?.[status] ?? status)]}
                      link={node?.full_slug && !!!label ? '/' + node?.full_slug : null}
                      image={node?.featured_image}
                      tags={tagsList}
                      cta_text={locales?.locales?.['view-project'] ?? 'View Project'}
                      ratio={['2/3', null, RATIOS[i].ratio]}
                      sx={{ gridColumn: ['1/-1', null, RATIOS[i].column] }}
                      m3u8={node?.m3u8}
                      mp4={node?.mp4}
                    />
                  )
                })
              : 'No data available'}
          </div>
        )}
        {allowed
          ? LISTED?.map((node, i) => {
              const { status, external_link, label } = entry_info(node)

              return (
                <Cell
                  _index={i}
                  withInview={false}
                  title={[node?.title ?? '', !!label && (locales?.locales?.[status] ?? status)]}
                  link={node?.full_slug && !!!label ? '/' + node?.full_slug : null}
                  href={!!label ? external_link : null}
                  linkState={{ to: node?.component ?? 'preview' }}
                  id={_uid + node?.uuid + i}
                  key={_uid + node?.uuid + i + JSON.stringify(data)}
                  disabled={!!label}
                  showIcon={!!label && !!external_link ? true : null}
                  image={node?.featured_image}
                />
              )
            })
          : 'No data available'}

        {/* <Transition as="div" type="quickfade" watch={JSON.stringify(data)} exitBeforeEnter></Transition> */}
      </Table>

      {allowed && available && (
        <Section as="div" sx={{ overflow: 'hidden', mx: 'calc(var(--padding-x) * -1)' }}>
          <Marquee
            onClick={increment}
            paused={false}
            speed="20s"
            multiplier={3}
            sx={{
              cursor: 'progress',
              '._hover >*': { transform: 'skew(-12deg)' },
              '>div>*': { transition: 'transform var(--ui-duration) cubic-bezier(0,0,0,1)' },
            }}
          >
            <Heading as="span" variant="title" cropped={false} sx={{ mr: '1em', lineHeight: '1.2', mb: '-0.08em' }}>
              {locales?.locales?.['load-more'] ?? 'Load More'}
            </Heading>
          </Marquee>
        </Section>
      )}
    </SbEditable>
  )
}

const List = (props) => {
  const [mounted, setMounted] = useState(false)

  useEffect(() => setMounted(true), [])

  return mounted ? <ListComponent {...props} /> : <div sx={{ height: '100vh' }} />
}

const Cell = ({ _index = 0, ...props }) => {
  const [ref, intersecting] = useViewState([], {
    inview: { once: true, disabled: false },
    options: { rootMargin: '60px' },
  })

  return (
    <div
      ref={ref}
      sx={{
        '& + &>div>hr.-top': {
          display: 'none',
        },
      }}
    >
      <Table.Cell data-inview="list" data-inview-visible={!!intersecting} {...props} />
    </div>
  )
}

export default List
