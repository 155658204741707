const height = (property) => `calc((var(--height, 100) * ${property} - var(--offset, 0px))`

export default {
  __default: {
    '--unit': 'var(--vh, 1vh)',

    'html[data-browser="crios"][data-device="touch"] &': {
      '--unit': 'var(--svh, 1vh)',
    },
    'html[data-browser="ios"][data-device="touch"] &': {
      '--unit': ['var(--svh, 1vh)', 'var(--vh, 1vh)'],
    },
    '&[data-fluid-height=true]': {
      '--unit': 'var(--vh-x, 1vh)',
    },

    my: 0,
    '--calc': height('var(--unit, 1vh))'),
    minHeight: 'var(--calc)',

    '--height': 100,
    '--offset': 'calc(var(--padding-x) * 2)',

    textAlign: 'left',
    placeItems: ['flex-start flex-start', 'flex-end flex-start'],

    position: 'relative',
  },
  container: {
    pt: [
      'calc(var(--header-height, 100px) + (var(--padding-x) * 2.5))',
      'calc(var(--header-height, 100px) + (var(--padding-x) * 2))',
    ],
    pb: 1,

    '>[data-cropped]': {
      maxWidth: 'var(--heading-max-width, unset)',
    },
  },
  physics: {
    width: '100%',
    position: 'relative',

    content: {
      position: 'relative',
      zIndex: 2,
      pointerEvents: 'none',
      userSelect: 'none',
    },
    container: {
      position: 'absolute',
      top: '-3rem',
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
    },
    canvas: {
      size: '100%',
    },
  },
}
