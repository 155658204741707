import { BigPlayButton } from './BigPlayButton'

export const FallbackPlay = () => (
  <div
    className="FallbackPlay"
    sx={{
      inset: 0,
      zIndex: 4,
      position: 'absolute',

      display: 'grid',
      placeContent: 'end end',

      pointerEvents: 'none',
      userSelect: 'none',
    }}
  >
    <BigPlayButton sx={{ mr: 1, mb: 1 }} />
  </div>
)

export default FallbackPlay
