module.exports = {
  homeSlug: 'home',
  includeLinks: true,
  resolveLinks: 'story',
  resolveRelations: [
    'use-global.reference',
    'featured-jobs.jobs',
    'featured-members.members',
    'featured-projects.projects',
    'featured-services.services',
    'other-projects.projects',
    'contact-section.members',
  ],
  locales: {
    locales: ['locales', 'job-status', 'locations', 'member', 'jobs'],
    categories: ['job-titles', 'services'],
    project: ['project'],
    tags: ['tags'],
    typeforms: ['typeforms'],
  },
  langs: ['en', 'fr'],
}
