export const CONFIGS = {
  smooth: 1.6,
  smoothTouch: false,
  effects: false,
  normalizeScroll: true,
  ignoreMobileResize: true,
  ease: 'expo',
}

export const COMPONENTS = {
  wrapper: ['main', {}],
  content: ['div', {}],
}

export const EFFECTS = { speed: 1, lag: 0 }

export const BOTTOM = { percent: 0, offset: ['-', '0px'] }
