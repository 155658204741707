import { useRef } from 'react'
import { Heading, Text, Marquee, Section } from '@monobits/components'
import { Image as StoryblokImage } from '@boiler/image'
import { Menu as Molecule } from '@boiler/molecules'
import { Transition } from '@boiler/framer'
import { localize } from '@boiler/utils'
import SbEditable from 'storyblok-react'

import { Bloks } from '../bloks'
import { NewlineText } from '../atoms'
import { useData } from '../hooks'
import Copyrights from './Copyrights'

const Freeze = ({ children, freeze = false }) => {
  const wrapperProps = { as: 'div', type: 'freeze', watch: ({ lang }) => lang?.iso ?? {} }

  return !!freeze ? <Transition {...wrapperProps}>{children}</Transition> : children
}

const Footer = ({ as = 'footer', withGSAP = false, freeze = false, cta = null, ...props }) => {
  const { settings, component, locales } = useData() ?? {}

  const index_images = settings?.cycling_images ?? []
  const index_images_i = () => Math.floor(Math.random() * index_images.length)
  const img_index = useRef(index_images_i())
  const index_image = index_images?.[img_index.current]?.image ?? settings?.meta_image

  const wrapChildren = !!freeze && !!withGSAP
  const wrapComponent = !!freeze && !!!withGSAP

  if (!settings) return null

  const { menu, contact_nav, location, social_nav, copyrights } = settings ?? {}

  const copyright = copyrights?.[0]?.text ?? ''[('email-link', 'phone-link')]

  const navigation =
    social_nav &&
    social_nav.map((item) => (
      <div
        key={item._uid}
        data-gsap={!!withGSAP ? 'menu-b' : null}
        data-nav-type={item?.component}
        data-wrap-children={!!wrapChildren}
        sx={{
          width: '1.15rem',
          '&[data-wrap-children=false][data-nav-type="social-item"]': {
            display: 'inline-flex',
          },
          '&[data-wrap-children=true][data-nav-type="social-item"]': {
            display: 'inline-flex',
            '>div': { display: 'inherit' },
          },
        }}
      >
        <Freeze freeze={!!wrapChildren}>
          <Bloks blok={item} />
        </Freeze>
      </div>
    ))

  const items =
    menu &&
    menu.map((item) => (
      <div key={item._uid} data-gsap={!!withGSAP ? 'menu-a' : null}>
        <Freeze freeze={!!wrapChildren}>
          <Bloks blok={item} component="menu_item" />
        </Freeze>
      </div>
    ))

  const about = !!contact_nav && (
    <div
      sx={{
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.8rem',
        mt: '0.8rem',
      }}
    >
      <Text data-gsap={!!withGSAP ? 'menu-b' : null} as="p" sx={{ lineHeight: '1.2' }}>
        <NewlineText text={location} id="agency-address" />
      </Text>

      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5em',
        }}
      >
        {contact_nav.map((item) => (
          <div
            key={item._uid}
            data-gsap={!!withGSAP ? 'menu-b' : null}
            data-nav-type={item?.component}
            data-wrap-children={!!wrapChildren}
            sx={{
              '&[data-wrap-children=false][data-nav-type="nav-item"]': {
                display: 'inline-flex',
              },
              '&[data-wrap-children=true][data-nav-type="nav-item"]': {
                display: 'inline-flex',
                '>div': { display: 'inherit' },
              },
            }}
          >
            <Freeze freeze={!!wrapChildren}>
              <Bloks blok={item} component="nav_item" inverted offset sx={{ width: 'fit-content' }} />
            </Freeze>
          </div>
        ))}
      </div>
    </div>
  )

  const body = (
    <Molecule
      as={as}
      items={items}
      about={about}
      data-component={component}
      cta={cta}
      copyrights={
        copyright && (
          <Heading as="h6" variant="caption" data-gsap="menu-c">
            <Freeze freeze={!!wrapChildren}>
              <Copyrights blok={{ text: copyright }} />
            </Freeze>
          </Heading>
        )
      }
      withGSAP={withGSAP}
      {...props}
    >
      {navigation}
    </Molecule>
  )

  return (
    <SbEditable content={settings} key={settings._uid}>
      <Freeze freeze={!!wrapComponent}>
        {!!wrapComponent && (
          <Section sx={{ overflow: 'hidden' }}>
            <Marquee
              as="a"
              id="footer-marquee"
              href={localize(settings?.hire_us_form ?? '', locales?.typeforms, '/')}
              target="_blank"
              paused={false}
              speed="20s"
              multiplier={3}
              sx={{
                cursor: 'alias',
                '._hover .Text': { transform: 'skew(-12deg)' },
                '.Text': {
                  transition: 'transform var(--ui-duration) cubic-bezier(0,0,0,1)',
                },
              }}
            >
              <Heading
                variant="title"
                cropped={false}
                sx={{ mr: '1em', display: 'flex', alignItems: 'center', gap: '1em', lineHeight: '1.2' }}
              >
                {!!settings?.meta_image && (
                  <div sx={{ width: 'clamp(60px, 1em, 200px)' }}>
                    <StoryblokImage
                      sx={{ img: { borderRadius: 1 } }}
                      data={index_image}
                      inview={true}
                      object={{ fit: 'cover' }}
                      ratio="1/1"
                      lazyload={false}
                      fitted={true}
                    />
                  </div>
                )}
                <div className="Text">{locales?.locales?.['start-your-project'] ?? 'Start your project'}</div>
              </Heading>
            </Marquee>
          </Section>
        )}

        {body}
      </Freeze>
    </SbEditable>
  )
}

export default Footer
