import { transitions, transparentize, easeInOut } from 'polished'

const mediaClasses = (display, { prepend = true } = {}) => {
  const pre = !prepend ? '' : '-' + display
  return {
    ['.-hide-mobile' + pre]: { display: ['none', display] },
    ['.-hide-tablet' + pre]: { display: [null, 'none', display] },
    ['.-hide-desktop' + pre]: { display: [null, null, 'none'] },
    ['.-show-mobile' + pre]: { display: [null, 'none'] },
    ['.-show-tablet' + pre]: { display: ['none', display, 'none'] },
    ['.-show-desktop' + pre]: { display: ['none', null, display] },
  }
}

export default {
  ...mediaClasses('grid'),
  ...mediaClasses('block'),
  ...mediaClasses('flex', { prepend: false }),

  hr: {
    borderTopWidth: 1,
    borderTopStyle: 'solid',
    borderTopColor: 'text',
  },

  '[data-inview="fade"]': {
    ...transitions(['opacity', 'transform'], 'var(--inview-duration) var(--inview-ease)'),

    '&[data-inview-visible=false]': {
      opacity: 0,
      transform: 'translateY(var(--padding-m))',
    },
  },
  '[data-inview="running"]': {
    '>*': {
      ...transitions(['opacity', 'transform'], 'var(--inview-duration) var(--inview-ease)'),
    },
    '>hr': {
      transformOrigin: 'left',
    },
    '>div:nth-of-type(2)': {
      transitionDelay: 'var(--inview-stagger)',
    },

    '&[data-inview-visible="false"]': {
      '>div': {
        opacity: 0,
        transform: 'translateY(var(--padding-m))',
      },
      '>hr': {
        transform: 'scaleX(0)',
      },
    },
  },
  '[data-inview="project-info"]': {
    '>*': {
      ...transitions(['opacity', 'transform'], 'var(--inview-duration) var(--inview-ease)'),
    },
    '>hr': {
      transformOrigin: 'left',
    },
    // '>h1': {},
    '>[data-area="expertise"]': { transitionDelay: ['var(--inview-stagger)', 'revert'] },

    '>[data-area="year"]': { transitionDelay: [null, 'var(--inview-stagger)'] },
    '>[data-area="mission"]': { transitionDelay: 'var(--inview-stagger)' },

    '&[data-inview-visible="false"]': {
      '>*': {
        opacity: 0,
        transform: 'translateY(var(--padding-m))',
      },
      '>hr': {
        transform: 'scaleX(0)',
      },
    },
  },
  '[data-inview="list"]': {
    ...transitions(['opacity', 'transform'], 'var(--inview-duration) var(--inview-ease)'),
    '>hr': {
      ...transitions(['transform'], 'var(--inview-duration) var(--inview-ease)'),
      transformOrigin: 'left',
    },

    '&[data-inview-visible=false]': {
      opacity: 0,
      transform: 'translateY(var(--padding-m))',

      '>hr': {
        transform: 'scaleX(0)',
      },
    },
  },
  '[data-inview="project-bar"]': {
    '>hr': {
      ...transitions(['transform'], '2s var(--inview-ease) 0.5s'),
      transformOrigin: 'left',
    },

    '[data-area="title"]': {
      ...transitions(['opacity', 'transform'], 'var(--inview-duration) var(--inview-ease) 0.5s'),
    },
    '[data-area="year"]': {
      ...transitions(['opacity', 'transform'], 'var(--inview-duration) var(--inview-ease) 0.75s'),
    },
    '[data-area="amount"]': {
      ...transitions(['opacity', 'transform'], 'var(--inview-duration) var(--inview-ease) 0.95s'),
    },
    '[data-area="live"]': {
      ...transitions(['opacity', 'transform'], 'var(--inview-duration) var(--inview-ease) 1.5s'),
    },

    '&[data-inview-visible=false]': {
      '[data-area]': {
        opacity: 0,
        transform: 'translateY(1em)',
      },

      '>hr': {
        transform: 'scaleX(0)',
      },
    },
  },
  '[data-inview="media"]': {
    overflow: 'hidden',
    '>*': transitions(['transform'], 'var(--inview-duration) var(--inview-ease)'),

    '--strict-opacity': 1,
    '&[data-inview-visible=false]': {
      '>*': {
        transform: 'scale(1.12)',
      },
      '--strict-opacity': 0,
    },
  },

  '[data-column]': {
    display: 'flex',
    flexDirection: 'column',
  },

  '[data-underline]': {
    '--to': (t) => transparentize(1, t.rawColors.text),
    '--from': (t) => t.rawColors.text,

    '--stroke': '0.02em',
    '--spacing': '88%',

    textDecoration: 'none',
    backgroundImage: 'linear-gradient(var(--from), var(--from)), linear-gradient(var(--to), var(--to))',
    backgroundPosition: '100% var(--spacing), 0 var(--spacing)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% var(--stroke), 0 var(--stroke)',
    transition: `background-size var(--ui-duration) ${easeInOut('cubic')}`,

    '@media (hover: hover)': {
      '&:not(._active):hover': {
        backgroundSize: '0 var(--stroke), 100% var(--stroke)',
      },
    },
    '&._active': {
      backgroundSize: '0 var(--stroke), 100% var(--stroke)',
    },
  },

  '.-aspect-ratio, [data-aspect-ratio]': {
    aspectRatio: 'unset!important',
    '::after': {
      content: '""',
      pb: 'calc(100% / (var(--aspect-ratio)))',
      display: 'flex',
    },
  },
}
