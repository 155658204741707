import { useRef } from 'react'
import { Heading } from '@monobits/components'
import { useConditionalEffect, useIsomorphicLayoutEffect as useLayoutEffect } from '@react-hookz/web'
import { getSmoother, refreshAll } from '@boiler/smoother'
import { gsap } from 'gsap'

const Intro = ({ setRendered = null, is404, ...props }) => {
  let init = useRef(false)
  let percent = useRef({ value: 0 })
  let ref = useRef(null)

  const onComplete = () => {
    const smoother = getSmoother()
    smoother?.paused(false)
    document.documentElement?.setAttribute('data-rendered', true)
    setRendered(2)
    refreshAll()
  }

  useConditionalEffect(
    () => {
      const smoother = getSmoother()
      smoother?.paused(true)
      init.current = true
    },
    [],
    [!!!init.current]
  )

  useLayoutEffect(() => {
    const node = ref.current
    const text = node?.childNodes?.[0]

    gsap
      .timeline({ onComplete, delay: 1 })
      .set('#main', { opacity: 0, y: '3rem' })
      .to(text, { opacity: 1, y: 0, duration: 0.5, ease: 'circ.out' })
      .fromTo(
        percent.current,
        { value: 0 },
        {
          value: 100,
          duration: 1,
          ease: 'progress',
          onUpdate: () => gsap.set(text, { text: '%' + ~~percent.current.value }),
        }
      )
      .add('loaded')
      .set('#main', { opacity: 0, y: '3rem' })
      .add(() => setRendered(1), 'loaded+=0.85')
      .to(text, { opacity: 0, y: '3rem', duration: 0.65, ease: 'circ.in' }, 'loaded+=0.5')
      .add('ready')
      .to(node, { autoAlpha: 0, duration: 0.65, ease: 'circ.out' }, 'ready+=0.5')
      .to('#main', { opacity: 1, y: 0, duration: 0.65, ease: 'circ.out' }, 'ready+=0.5')
      .set('#main', { clearProps: 'all' })
  }, []) // eslint-disable-line

  return (
    <section ref={ref} sx={{ variant: 'intro' }} {...props}>
      <Heading
        as="span"
        variant="title"
        aria-label="loading percentage"
        sx={{ opacity: 0, transform: 'translateY(3rem)' }}
      >
        %0
      </Heading>
    </section>
  )
}

export default Intro
