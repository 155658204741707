import { Flex, Grid } from 'theme-ui'

/**
 * This is the Menu component
 */
const Menu = ({ children, items, about, copyrights, variant, sx, withGSAP = false, cta, ...props }) => (
  <Flex sx={{ variant: 'menu.container', ...sx }} {...props}>
    <Grid as="div" sx={{ variant: 'menu.wrapper' }}>
      <Grid as="nav" sx={{ variant: 'menu.main' }}>
        {items}
      </Grid>

      {!!about && (
        <Grid as="div" sx={{ variant: 'menu.about' }}>
          {about}
        </Grid>
      )}
    </Grid>

    {children && (
      <Grid as="nav" data-block={!!!withGSAP ? 'foot' : null} sx={{ variant: 'menu.second' }}>
        <hr data-gsap={!!withGSAP ? 'menu-b' : null} />
        {!!cta && cta}
        <div>{children}</div>
        {copyrights && copyrights}
      </Grid>
    )}
  </Flex>
)

export default Menu
