export default {
  __default: {
    mb: [2, 3],
    pt: 1,
    borderTop: 'var(--stroke-x) solid currentColor',

    display: 'flex',
    flexWrap: 'wrap',

    justifyContent: 'space-between',

    columnGap: 2,
    rowGap: '2rem',

    '&[data-breakpoint="mobile"]': {
      flexDirection: 'column',
      alignItems: 'flex-start',

      '[data-nav]': {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, auto)',

        height: 'fit-content',

        overflow: 'hidden',
        width: '100%',

        columnGap: '1rem',
        rowGap: '1.5rem',

        '>[data-active=false]:not(:hover)': {
          opacity: 0.3,
        },
        '>[data-active]': {
          width: 'fit-content',
        },
      },

      '&[data-initial=false] [data-nav]': {
        height: 0,
      },
    },
    '&[data-breakpoint="desktop"]': {
      '&[data-initial=false] [data-nav]': {
        transform: 'translateY(0.5rem)',
        visibility: 'hidden',
        opacity: 0,
      },

      '&[aria-expanded] [data-nav]': {
        display: 'flex',
        flexWrap: 'wrap',
        columnGap: '3rem',
        rowGap: '1rem',

        '>[data-active=false]:not(:hover)': {
          opacity: 0.3,
        },
      },
    },

    '&[aria-expanded=true] [data-icon="expand"]': {
      transform: 'rotate(-135deg)',
    },
  },

  trigger: {
    '[data-icon="expand"]': {
      ml: '0.1em',
      display: 'inline-flex',
      fontSize: '118%',
      transition: 'transform var(--ui-duration) var(--ui-ease)',

      svg: {
        width: 'unset',
        height: '1ex',
      },
    },
  },

  counter: {
    position: 'absolute',
    top: 'calc(var(--padding-x) * -1)',
    right: 0,
    zIndex: 'default',
    transform: 'translateY(-100%)',
  },
}
