import SbEditable from 'storyblok-react'
import { localize } from '@boiler/utils'

import Card from './Projects/Card'
import Table from './Table'
import { RATIOS, MAX_FEATURES } from '../__consts'

const FeaturedProjects = ({ blok, sx, sanitize = (p) => p, locales = {}, all_nodes_slugs = [], ...props }) => {
  const { projects, view, _uid } = blok ?? {}

  const isValidSlug = (slug = '') => !!all_nodes_slugs?.some((i) => i === slug)

  const entry_info = (node) => {
    const isOk = isValidSlug(node?.full_slug ?? '')
    const _status = node?.content?.status ?? 'active'
    const status = _status === 'active' && !!!isOk ? 'coming-soon' : _status
    const external_link = node?.content?.external_link ?? null
    const label = status !== 'active'

    return { status, external_link, label }
  }

  const views = {
    list: (
      <Table id={_uid} sx={{ ...sx }} {...sanitize(props)}>
        {(projects ?? [])?.map((node, i) => {
          const { status, external_link, label } = entry_info(node)

          return (
            <Table.Cell
              title={[node?.content?.title ?? '', !!label && (locales?.locales?.[status] ?? status)]}
              link={node?.full_slug && !!!label ? '/' + node?.full_slug : null}
              href={!!label ? external_link : null}
              linkState={{ to: node?.content?.component ?? 'preview' }}
              id={_uid + node.uuid + i}
              key={_uid + node.uuid + i}
              showIcon={!!label && !!external_link ? true : null}
              disabled={!!label}
              image={node?.content?.featured_image}
            />
          )
        })}
      </Table>
    ),
    cards: (
      <div
        sx={{
          mb: 3,
          display: 'grid',
          alignItems: 'start',
          gridTemplateColumns: ['1fr', '1fr 1fr'],
          columnGap: 1,
          rowGap: [2, null, 3],
        }}
      >
        {(projects ?? [])?.map((node, i) => {
          const { status, label } = entry_info(node)
          const _tag = (value) => localize(value, locales?.tags ?? {})

          const tagsList = (node?.tag_list ?? [])
            ?.sort((a, b) => _tag(a).localeCompare(_tag(b)))
            ?.map(
              (v, ii) =>
                _tag(v) +
                (ii !== node?.tag_list?.length - 2 ? (ii !== node?.tag_list?.length - 1 ? ', ' : '') : '\u00A0&\u00A0')
            )
            .join('')

          return (
            <Card
              id={_uid + node?.uuid + i}
              key={_uid + node?.uuid + i}
              title={[node?.content?.title ?? '', !!label && (locales?.locales?.[status] ?? status)]}
              link={node?.full_slug && !!!label ? '/' + node?.full_slug : null}
              image={node?.content?.featured_image}
              tags={tagsList}
              cta_text={locales?.locales?.['view-project'] ?? 'View Project'}
              ratio={['1/1', null, RATIOS[i % MAX_FEATURES].ratio]}
              sx={{ gridColumn: ['1/-1', null, RATIOS[i % MAX_FEATURES].column] }}
              data-inview="fade"
              m3u8={node?.content?.m3u8}
              mp4={node?.content?.mp4}
            />
          )
        })}
      </div>
    ),
  }

  return (
    <SbEditable content={blok} key={_uid}>
      {views?.[view]}
    </SbEditable>
  )
}

export default FeaturedProjects
