export default {
  __default: {
    gridTemplateAreas: [
      `
        "hr"
        "b"
        "a"
        "c"
      `,
      `
        "hr ."
        "a b"
        "c ."
      `,
    ],
  },
  navs: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    gap: '1.8rem',
    mt: '-0.3rem',
  },
  location: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75em',
  },
  social: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: 'fit-content',
    gap: '1.25rem',
    mt: '1.25rem',
  },

  members: {
    mt: 1,
    lineHeight: '1.4',

    display: 'flex',
    flexDirection: 'column',
    gap: '1.5em',

    small: {
      opacity: 0.5,

      display: 'inline-flex',
      width: '100%',
      mb: '0.25em',
    },
  },
}
