import { memo, useMemo, forwardRef } from 'react'
import { Link } from 'gatsby'
import { Flex, Grid, Box } from 'theme-ui'
import { Text } from '@monobits/components'
import { Image as StoryblokImage } from '@boiler/image'
import { useBreakpoint } from '@boiler/utils'
import { Lazy } from '@boiler/intersection-observer'

import { Video as VideoJS } from '@boiler/video'

import { Cta } from '../../atoms'

export const Card = ({ sx, title, link, id, image, tags, eager, ratio = '1/1', cta_text, m3u8, mp4, ...props }) => {
  const { breakpoint } = useBreakpoint()
  const with_video = !!(!!m3u8 || !!mp4) && !!breakpoint.mouse

  return (
    <Lazy inview={{ once: !with_video }} eager={eager}>
      {({ ref, inview }) => {
        return (
          <CardComponent
            ref={ref}
            inview={inview}
            image={image}
            ratio={ratio}
            m3u8={m3u8}
            mp4={mp4}
            title={title}
            tags={tags}
            cta_text={cta_text}
            with_video={with_video}
            link={link}
            {...props}
            //
          />
        )
      }}
    </Lazy>
  )
}

export const CardComponent = memo(
  forwardRef(
    ({ inview, with_video, sx, title, link, id, image, tags, ratio = '1/1', cta_text, m3u8, mp4, ...props }, ref) => {
      const linked = !!link && !title?.[1] ? { as: Link, to: link } : {}

      const img = useMemo(
        () => (
          <StoryblokImage
            data={image}
            inview={inview}
            object={{ fit: 'cover' }}
            ratio={!!ratio ? ratio : null}
            lazyload={true}
            fitted={true}
          />
        ),
        [image?.filename, inview] //eslint-disable-line
      )

      return (
        <Flex ref={ref} sx={{ variant: 'card', ...sx }} data-size="full" {...props}>
          <Box {...linked} className="Asset">
            {img}

            {with_video && (
              <VideoJS
                mode="fluid"
                preset="passive"
                objectFit="cover"
                aspectRatio={ratio ?? undefined}
                allowFallbackControls={false}
                options={{
                  sources: [
                    { src: m3u8, type: 'application/x-mpegURL' },
                    { src: mp4, type: 'video/mp4' },
                  ],
                }}
                sx={{
                  borderRadius: 1,
                  overflow: 'hidden',
                  transform: 'translateZ(0px)',

                  position: 'absolute',
                  inset: 0,
                }}
              />
            )}
            {/* {with_video && (
              <Hls
                sx={{
                  borderRadius: 1,
                  overflow: 'hidden',
                  transform: 'translateZ(0px)',

                  position: 'absolute',
                  inset: 0,
                }}
                src={!!inview && m3u8}
                mp4={!!inview && mp4}
                styles={{ fit: 'cover', fade: true }}
                options={{
                  autoplay: true,
                  playsinline: true,
                  controls: false,
                  loop: true,
                  pip: false,
                }}
              />
            )} */}
          </Box>

          <Grid sx={{ variant: 'card.info' }}>
            <Text as="h3" rte={false}>
              {title?.[0] ?? 'ERROR'}
            </Text>
            <Text as="p" rte={false} sx={{ maxWidth: '18rem' }}>
              {tags}
            </Text>
            <Cta
              {...linked}
              size={null}
              sx={{
                width: ['100%', null, 'fit-content'],
                justifySelf: 'end',
                gridColumn: ['1/-1', null, '3/-1'],
                pointerEvents: !!title?.[1] && 'none',
              }}
            >
              {title?.[1] || cta_text}
            </Cta>
          </Grid>
        </Flex>
      )
    }
  )
)

export default memo(Card)
