import { createContext, useContext } from 'react'
import { useAsync, useMountEffect } from '@react-hookz/web'
import 'pathseg'

export const MatterContext = createContext()

/**
 * useMatter, Hook containing Matter Library
 *
 * @returns {Object}
 * @returns {Object} Matter, Matter.js instante
 * @returns {String} status, the load status of the Matter.js library, ('loading' | 'success' | 'error' | 'not-executed')
 */
export const useMatter = () => useContext(MatterContext)

/**
 * Matter.js Provider,
 * the library will be loaded asynchronously and store in the context.
 *
 * @param {*} children
 * @returns children node
 *
 * @example
 * return <MatterProvider><main>...</main></MatterProvider>
 */
const MatterProvider = ({ children, ...props }) => {
  const [state, actions] = useAsync(() => new Promise((resolve) => resolve(import('matter-js'))), null)
  useMountEffect(actions.execute)

  const Matter = state?.result ?? {}

  return (
    <MatterContext.Provider
      value={{
        Matter,
        status: state?.status ?? '',
        ...props,
      }}
    >
      {children}
    </MatterContext.Provider>
  )
}

export default MatterProvider
