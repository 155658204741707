import { graphql } from 'gatsby'
import { pick } from 'lodash'

import BaseEntry from './base-entry'

const ProjectsEntry = ({ data, ...props }) => {
  return (
    <BaseEntry
      pagination={{
        ...pick(props.pageContext, ['limit', 'totalPages', 'childComponent', 'startsWith', 'starts_with', 'count']),
        data: data?.projects?.edges,
      }}
      {...props}
    />
  )
}

export const projectsQuery = graphql`
  query getProjects($limit: Int!, $childComponent: String!, $startsWith: String!, $iso: String!) {
    projects: allStoryblokEntry(
      limit: $limit
      sort: { fields: position, order: ASC }
      filter: {
        field_component: { eq: $childComponent }
        default_full_slug: { regex: $startsWith }
        lang: { eq: $iso }
      }
    ) {
      edges {
        node {
          field_component
          tag_list
          content
          name
          lang
          uuid
          full_slug
        }
      }
    }
  }
`

export default ProjectsEntry
