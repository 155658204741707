import { createElement } from 'react'
import { Box } from 'theme-ui'

import { useLink } from '../hooks'
import NewlineText from './NewlineText'

const Link = ({ children, link, params, state, forwardedAs, ...props }) => {
  let text
  if (Array.isArray(children)) text = children[0]

  const multilink = useLink()

  return createElement(
    forwardedAs || Box,
    { ...multilink(link, { params, state }), ...props },
    <NewlineText text={text || children} />
  )
}
export default Link
