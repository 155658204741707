import { useState, useEffect } from 'react'
import { useSafeState, useUpdateEffect } from '@react-hookz/web'
import { omit, get } from 'lodash'

import { parseContent } from '@boiler/utils'
import StoryblokClient from 'storyblok-js-client'

const Storyblok = new StoryblokClient({
  accessToken: process.env.GATSBY_STORYBLOK_TOKEN,
  cache: { clear: 'auto', type: 'memory' },
})

const arrayUniqueByKey = (array, path = 'prefix') => [...new Map(array.map((item) => [get(item, path), item])).values()]

const reduceQuery = (data) => data.map(({ node }) => node)

const parseContents = (data) => data.map((node) => ({ ...omit(node, ['content']), ...parseContent(node.content) }))

const useDynamicPagination = (
  initialData = {},
  { call = {}, limit = 1, totalPages = 1, location, lang = {}, by_tags = [] }
) => {
  const initial = reduceQuery(initialData)
  const [datas, setDatas] = useSafeState(initial)
  const [withTags, setWithTags] = useState(by_tags)
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false)

  const [available, setAvailable] = useSafeState(page < totalPages)
  const [total, setTotal] = useSafeState(0)

  const parsed = !!withTags?.length
    ? parseContents(datas).filter((node) => someArrays(withTags, node.tag_list))
    : parseContents(datas)

  async function loadPage(i, write = false) {
    setLoading(true)
    await Storyblok.get(`cdn/stories`, {
      version: process.env.GATSBY_STORYBLOK_VERSION,
      per_page: limit,
      page: i ? i : page + 1,
      language: lang?.nullish,
      with_tag: !!withTags?.length ? withTags : null,
      ...call,
    }).then(({ data, headers }) => {
      const _total = +(headers?.total ?? 0)

      if (write) {
        data.stories && setDatas([...data.stories])
        setTotal(_total)
        setAvailable(data.stories.length < _total)
        setPage(1)
        setLoading(false)
      } else {
        const newValue = arrayUniqueByKey([...datas, ...data.stories], 'full_slug')
        data.stories && setDatas(newValue)
        if (newValue?.length === total) setAvailable(false)
        setLoading(false)
      }
    })
  }

  const increment = () => !!available && setPage((prev) => prev + 1)

  useEffect(() => {
    if (location?.search?.includes('_storyblok')) loadPage(1)
  }, []) // eslint-disable-line

  useUpdateEffect(() => {
    loadPage(page, false)
  }, [JSON.stringify({ page })]) // eslint-disable-line

  useEffect(() => {
    loadPage(1, true)
  }, [JSON.stringify({ withTags })]) // eslint-disable-line

  return {
    page,
    increment,
    available,
    count: total,
    tags: [withTags, setWithTags],
    data: parsed,
    loading,
  }
}

function someArrays(a, b) {
  return a.some((_a) => b.some((_b) => _a === _b))
}

export default useDynamicPagination
