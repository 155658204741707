export default {
  indent: {
    __default: {
      display: 'inline-block',
      textIndent: 'var(--text-indent, 1em)',

      'b, strong': {
        color: 'primary',
        fontWeight: '300!important',
      },
    },
  },
  burger: {
    __default: {
      '--gap': ['3.5', null, '3'],
      '--stroke': 'var(--stroke-x, 0.07em)',
      '--speed': 'var(--ui-duration)',
      '--ease': 'var(--ui-ease)',
      color: 'text',
      width: '3rem',
    },
  },
  icon: { size: { '--stroke-absolute': (t) => t.borderWidths[1] + 'px' } },
  colorswitch: {
    variants: {
      bullet: {
        '--size': 'var(--icon-root-width, 0.65rem)',
        mt: '0.1rem',
      },
    },
    types: {
      inverted: {
        '--size': 'calc(var(--icon-root-width, 0.65rem) + (var(--stroke-x) * 2))',
        border: 'var(--stroke-x) solid var(--bg)',
        bg: 'var(--color)',
      },
    },
  },
  link: {
    underlined: {
      variant: 'text.text',
    },
    menu: {
      variant: 'text.menu',
      '--from': '0.1deg',
      '--to': '-12deg',
      '--sup-scale': '0.65',
      '--speed': 'var(--ui-duration)',
      '--ease': 'cubic-bezier(0,0,0,1)',

      display: 'inline-block',
      pb: '0.2em',

      '&[data-cropped=false]::before,&[data-cropped=false]::after': {
        display: 'none!important',
      },

      '&._active': { transform: `skew(var(--to))` },
    },
  },
  layout: {
    section: {
      small: { my: '4%' },
      regular: { my: 3 },
      large: { my: '8%' },
      running: {
        my: 3,
        display: 'grid',
        gridTemplateColumns: ['1fr', '1fr 1fr'],
        gap: 1,

        '&[data-multiple=false] > *': { gridColumn: '1/-1' },
        '&[data-multiple=true] > .Cta': { gridColumn: ['1/-1', '2/-1'] },
        hr: { gridColumn: '1/-1' },
      },
      heading: {
        '--section-margin': 'calc(var(--padding-l) - var(--padding-x))',

        '&[data-head=true]': {
          mt: ['calc(var(--section-margin) + var(--header-height) + var(--padding-x))', null, 'var(--section-margin)'],
          mb: 'var(--section-margin)',
        },
        '&[data-head=false]': {
          mt: 4,
          mb: 'var(--section-margin)',
        },

        '>[data-cropped]': {
          maxWidth: 'var(--heading-max-width, unset)',
          pb: 1,
          mb: 'calc(var(--padding-x) * -1)',
        },
      },
      hero: {
        my: 0,
        minHeight: 'calc((var(--height, 100) * var(--vh, 1vh)) - var(--offset, 0px))',
      },
      vh: {
        my: 0,
        minHeight: 'calc((var(--height, 100) * var(--vh-x, 1vh)) - var(--offset, 0px))',
      },
      __default: {
        variant: 'layout.section.regular',
      },
    },
  },
  button: {
    styles: {
      fontSize: 1,
      // textTransform: 'uppercase',
      '&::before': {
        borderRadius: '50%',
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'var(--border-color)',
      },

      '@media (hover: hover)': {
        '&:hover::before': {
          transform: 'scale(1.05)',
        },
      },
      '&[data-with-icon=true]>div': {
        width: '60%',
      },
    },
    variants: {
      primary: {
        '--text': (t) => t.colors.dark,
        '--text-hover': (t) => t.colors.dark,
        '--bg': (t) => t.colors.primary,
        '--bg-hover': (t) => t.colors.primary,
        '--border-color': (t) => t.colors.primary,
      },
      secondary: {
        '--text': (t) => t.colors.background,
        '--text-hover': (t) => t.colors.background,
        '--bg': (t) => t.colors.text,
        '--bg-hover': (t) => t.colors.text,
        '--border-color': (t) => t.colors.text,
      },
      inverted: {
        '--text': (t) => t.colors.text,
        '--text-hover': (t) => t.colors.text,
        '--bg': (t) => t.colors.background,
        '--bg-hover': (t) => t.colors.background,
        '--border-color': (t) => t.colors.background,
      },
    },
    sizes: {
      cta: { size: 'var(--cta-size, 3.5rem)', p: '0.5em' },
      sm: { size: '3.5rem', p: '0.5em' },
      md: { size: '6em', p: '0.5em' },
      lg: { size: '8em', p: '0.65em' },
      __default: { variant: 'button.sizes.md' },
    },
  },
}
