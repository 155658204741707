const select = (selector = '') => document?.querySelector(selector)
const selectAll = (selector = '') => document?.querySelectorAll(selector)

const observer = (selectors = [], { auto = false, onUpdate = () => {}, options = {} }) => {
  const _root = options?.root
  const nodes = selectors?.map((i) => (typeof i === 'string' ? Array.from(selectAll(i)) : i))?.flat() ?? []

  const isTouch = () => window?.matchMedia('(hover: none)')?.matches

  const intersectionObserver = new IntersectionObserver(
    (entries, instance) => {
      entries?.forEach((entry) => onUpdate(entry, instance))
    },
    { ...options, root: !!isTouch() ? null : typeof _root === 'string' ? select(_root) : _root }
  )

  const observe = () => nodes?.forEach((node) => intersectionObserver.observe(node))

  if (!!!nodes?.length) return { nodes: [], observe: () => {}, disconnect: () => {} }

  !!auto && observe()

  return {
    nodes,
    observe,
    disconnect: () => intersectionObserver.disconnect(),
  }
}

// NOTE: Babel `iterableIsArray` might break this in other environnements
// https://babeljs.io/docs/en/assumptions#iterableisarray

export default observer
