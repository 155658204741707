import { CONNECTION_THRESHOLD } from '../__consts'

const getOptimalRootMargin = () => {
  const navigator_ssr = typeof navigator === 'undefined' ? {} : navigator
  const connection = navigator_ssr?.connection ||
    navigator_ssr?.mozConnection ||
    navigator_ssr?.webkitConnection || { effectiveType: '4g', saveData: false }

  const is_fast = connection?.effectiveType === '4g' && !!!connection?.saveData

  return CONNECTION_THRESHOLD?.[is_fast ? 'FAST' : 'SLOW']
}

export default getOptimalRootMargin
