import { useScrollTrigger } from '../../hooks'

const Motion = ({ children, distance, json, ...props }) => {
  const watch = [distance, json]

  const [ref] = useScrollTrigger(
    ({ gsap, node }) => gsap.to(node, { y: distance + 'rem', ...parse(json) }),
    ({ node, isSmooth }) => ({
      trigger: node,
      start: 'top bottom',
      end: 'bottom top',
      scrub: isSmooth ? 0.6 : true,
    }),
    0,
    watch
  )

  return (
    <div data-column ref={ref} {...props}>
      {children}
    </div>
  )
}

function parse(json) {
  let obj
  try {
    obj = JSON.parse(json)
  } catch {
    obj = {}
  }

  return obj
}

export default Motion
