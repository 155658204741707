import useStoryblok from '../layouts/useStoryblok'
import Template from '../templates'

const NotFoundPage = ({ location, pageContext }) => {
  let { story, ...props } = useStoryblok(null, location, pageContext?.langKey) ?? {}

  const preview_mode = !!location?.search?.includes('_storyblok')

  let content = ''
  if (story && 'content' in story)
    content = (
      <Template preview_mode={!!preview_mode} component={story?.content?.component} blok={story.content} {...props} />
    )
  return content
}

export default NotFoundPage
