import { useRef } from 'react'
import { useConditionalEffect, useIsomorphicLayoutEffect as useLayoutEffect } from '@react-hookz/web'
import { clamp, debounce } from 'lodash'
import { fit } from 'object-fit-math'

import { isBrowser, isNum, updatePixelRatio, getDevicePixelRatio } from './utils'

const sourceWidth = ({ element, fitted: [fitted, type], sizes: [width, height], max_width, dpr = 1 }) => {
  const min = 400
  const max = max_width || 4000

  const height_ratio = height / width

  const nodes = {
    document: document.documentElement,
    parent: element?.parentElement,
    element,
  }

  let sum
  if (!!fitted) {
    const sizes = [
      { width: nodes.element?.clientWidth, height: nodes.element?.clientHeight },
      { width: nodes.element?.clientWidth, height: height_ratio * nodes.element?.clientWidth },
    ]
    sum = clamp(fit(...sizes, type)?.width * dpr, min, max)
  } else {
    sum = clamp(nodes.parent?.clientWidth * dpr, min, max)
  }

  return Math.ceil(sum)
}

const getSize = ({ element, steps, fitted, sizes, max_width }) => {
  let resizeValue = sourceWidth({ element, fitted, sizes, max_width, dpr: getDevicePixelRatio() })

  if (isNum(steps)) {
    resizeValue = Math.ceil(resizeValue / steps) * steps
  } else if (Array.isArray(steps)) {
    resizeValue = steps.reduce((prev, curr) =>
      Math.abs(curr - resizeValue) < Math.abs(prev - resizeValue) ? curr : prev
    )
  }
  return clamp(resizeValue, 0, max_width)
}

export const useResponsive = (
  initialWidth = 0,
  {
    steps,
    fitted = [false, 'cover'],
    sizes = [1, 1],
    max_width = null,
    transformer = (p) => p,
    placeholder_node = null,
  },
  predicates = []
) => {
  let ref = useRef(null)

  const x = (element) => getSize({ element, steps, fitted, sizes, max_width })

  if (!isBrowser()) return [ref, initialWidth]

  const handleInit = (ref) => {
    const initialDpr = getDevicePixelRatio()

    const _width = x(ref?.current)
    const _placeholder = placeholder_node(ref?.current)

    ref.current.__width = _width
    ref.current.src = transformer(_width)?.src
    !!_placeholder && (_placeholder.src = transformer(_width)?.placeholder)

    updatePixelRatio(() => {
      const _width = x(ref?.current)
      const _x = transformer(_width)?.src

      if (initialDpr < getDevicePixelRatio()) {
        if (ref.current?.__width < _width) {
          ref.current.__width = _width
          ref.current.src = _x
        }
      }
    })
  }

  const handleResize = debounce((ref) => {
    const _width = x(ref?.current)
    const _x = transformer(_width)?.src

    if (ref.current?.__width < _width) {
      ref.current.__width = _width
      ref.current.src = _x
    }
  }, 500)

  const handleInitDisabled = (ref) => {
    const initialDpr = getDevicePixelRatio()
    updatePixelRatio(() => {
      const dpi = getDevicePixelRatio()
      if (initialDpr < dpi) {
        ref.current.src = transformer(initialWidth * dpi)?.src
      }
    })
  }

  useConditionalEffect(
    () => {
      handleInit(ref)
      window.addEventListener('resize', () => handleResize(ref))
      return () => {
        window.removeEventListener('resize', () => handleResize(ref))
      }
    },
    [initialWidth, ...predicates],
    [!!!initialWidth, predicates?.every((i) => !!i)],
    undefined,
    useLayoutEffect
  )

  useConditionalEffect(
    () => handleInitDisabled(ref),
    [initialWidth, ...predicates],
    [!!initialWidth, predicates?.every((i) => !!i)],
    undefined,
    useLayoutEffect
  )

  return ref
}
