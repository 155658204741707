/**
 * Grabs the duration of the video
 * and adds `.vjs-minutes-long` or `.vjs-hours-long` on the player
 *
 * @example
 * import './plugins/DisallowFullscreenPlugin'
 * // ...
 * player.DisallowFullscreenPlugin()
 */
export const disallowFullscreenPlugin = (player) => {
  player.ready(() => {
    player.one('loadedmetadata', () => {
      if (!player.supportsFullScreen() || player.options_.fullscreen === false) {
        player.addClass('vjs-nofull')
      } else {
        player.removeClass('vjs-nofull')
      }
    })
  })
}
