import { inset } from '@monobits/core'

export default {
  position: 'relative',
  width: '100%',

  img: {
    size: '100%',
    position: 'absolute',
    userSelect: 'none',
    ...inset(0),
  },

  '>[data-image]': {
    objectFit: 'var(--object-fit)',
    objectPosition: 'var(--object-position)',
    opacity: 0,
  },

  '&[data-with-transition=true]>[data-image]': {
    transition: 'var(--transition, none)',
  },

  '>[data-placeholder]': {
    objectFit: 'cover',
    objectPosition: 'var(--object-position)',
    filter: 'blur(10px)',
    transform: 'scale(1.075)',
  },
  '&[data-loaded=true]': {
    '>[data-image]': { opacity: 'var(--strict-opacity, 1)' },
  },
  '&[data-inset=true]': {
    position: 'absolute',
    ...inset(0),
  },
}
