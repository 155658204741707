import { merge } from 'theme-ui'
import { preset } from '@monobits/preset-base'
import { smootherStyles } from '@boiler/smoother'
import { easeInOut, easeOut } from 'polished'
import { omit } from 'lodash'

import config from './config'
import styles from './styles'
import colors from './colors'
import overrides from './overrides'
import transitions from './transitions'
import fonts, { rootFontStyles } from './fonts'
import scales, { rootScaleStyles } from './scales'
import * as variants from './variants'

import './gsap'

const theme = (modes, _config) =>
  merge(omit(preset, ['colors']), {
    config: config(_config),
    colors: colors(modes, _config?.initialColorModeName),
    transitions,

    breakpoints: ['43em', '62em', '82em'],
    breakpointsMap: ['mobile', 'tablet', 'desktop'],

    styles: {
      root: {
        ...styles,
        ...smootherStyles,
        ...rootFontStyles,
        ...rootScaleStyles,

        '--icon-root-width': '0.6rem',
        '--icon-height': '1.2em',
        '--icon-width': '1.25em',

        '--ui-duration': '0.45s',
        '--ui-ease': 'ease',

        '--inview-duration': '1s',
        '--inview-ease': easeOut('quart'),
        '--inview-delay': '0s',
        '--inview-stagger': '0.2s',

        '--cta-size': '6.5rem',
        '--cta-duration': '1.2s',
        '--cta-ease': easeInOut('circ'),

        '--bg-ease': easeOut('expo'),

        '--heading-max-width': ['unset', 'calc(100% - var(--cta-size) - var(--padding-x))', '18ch'],
        '--text-indent': ['2.5em', '3.5em', '4.5em'],
        '--footer-offset': 'calc(var(--header-height) + (var(--padding-x) * 2))',

        body: { bg: 'background' },
      },
    },
    motion: {
      collapsible: {
        transition: {
          duration: 0.5,
          ease: 'circ.inOut',
        },
        bezier: 'ease',
      },
    },

    ...fonts,
    ...scales,
    ...variants,
    ...overrides,
  })

export default theme
