import { useState, createContext, useContext } from 'react'
import { useThemeUI } from 'theme-ui'
import { useSet } from '@react-hookz/web'
import { withHover } from '@monobits/core'
import { Section, Collapsible as Base } from '@monobits/components'
import SbEditable from 'storyblok-react'

import { MapBloks } from '../bloks'
import { useComponent, useLink } from '../hooks'

const CollapseContext = createContext()

const Collapsible = withHover(
  ({ def, children, component = '', title, id, link = false, sx, hover, ...props }) => {
    const {
      bezier,
      transition: { duration },
    } = useThemeUI().theme?.motion.collapsible ?? {}
    const {
      set,
      auto,
      state: [state, setState],
    } = useContext(CollapseContext)

    const opened = auto ? state === id : !!set.has(id)

    const hasLink = !!link && !!link?.cached_url
    const multilink = useLink()

    return def?.head({
      sx: { '--speed': `${duration}s`, '--ease': bezier, ...sx },
      props: {
        wrapper: {
          'data-handle': id,
          'data-selected': !!hover || opened,
          'aria-expanded': opened,
          'data-static': false,
          ...props,
        },
        button: {
          collapsible: {
            as: 'button',
            onClick: () => {
              if (auto) setState(opened ? '' : id)
              return opened ? set.delete(id) : set.add(id)
            },
          },
          'collapsible-link': hasLink ? multilink(link) : { as: 'div' },
          'collapsible-static': { as: 'div' },
        }[component],
      },
      content: <Base onTrigger={!opened}>{children}</Base>,
      withContent: component === 'collapsible',
      value: title,
      showIcon: component === 'collapsible' || !!hasLink,
      // hasLink,
      link,
      id,
    })
  },
  { mouse: true }
)

const Collapsibles = ({ blok, children, sanitize = (p) => p, ...props }) => {
  const { items, auto_close, _uid } = blok ?? {}
  const { collapsible, columns_text } = useComponent() ?? {}
  const set = useSet([])
  const state = useState('')

  const auto = parse(auto_close) ?? collapsible?.auto ?? true

  return (
    <SbEditable content={blok} key={_uid}>
      <CollapseContext.Provider value={{ set, state, auto }}>
        <Section {...collapsible?.section} data-single={!!((items?.length ?? 1) === 1)} {...sanitize(props)}>
          <div>
            {items?.map((item, i) => (
              <SbEditable content={item} key={item._uid}>
                <Collapsible
                  def={collapsible}
                  title={item?.title?.list ?? item?.title ?? ''}
                  link={item?.link ?? {}}
                  component={item?.component}
                  id={item._uid}
                  key={item._uid}
                >
                  {columns_text({
                    value: <MapBloks items={item?.body} id={_uid} variant="text" />,
                    multiple: item?.body?.length !== 1,
                    ...collapsible.rte,
                  })}
                </Collapsible>
              </SbEditable>
            ))}
          </div>
        </Section>
      </CollapseContext.Provider>
    </SbEditable>
  )
}

function parse(value) {
  return value ? { true: true, false: false }[value] : null
}

export default Collapsibles
