import { useRef } from 'react'
import { useSmoother, refresh } from '.'
import { EFFECTS } from '../__consts'

const useFX = (preset = {}, autoRefresh = false) => {
  let init = useRef(false)
  const { prefix: prefix_key } = useSmoother() ?? {}
  const prefix = !!prefix_key ? '-' + prefix_key : ''

  return (props = {}) => {
    !!init.current && !!autoRefresh && setTimeout(refresh, 20)
    init.current = true

    return ['speed', 'lag']?.reduce((acc, i) => {
      const value = props?.[i] ?? preset?.[i]
      return { ...acc, ...(value != null && value !== EFFECTS?.[i] && { [`data${prefix}-${i}`]: value }) }
    }, {})
  }
}

export default useFX
