import { Grid, Box, Flex } from 'theme-ui'
import { Section, Heading, SkewLink } from '@monobits/components'
import { Image as StoryblokImage } from '@boiler/image'
import { Head } from '@monobits/molecules'
import { omit, pick } from 'lodash'
import { Underline, Cta } from './atoms'
import icons from './icons'

const inviewProps = { 'data-scroll-offset': '16%,0' }

// Func, sanitizes the props to prevent DOM side-effects.
const _ = (obj) => omit(obj, ['lang'])

/**
 * Define the components independently from their logic.
 */
export default {
  links: ({ value, sx, ...props }) => (
    <div sx={{ display: 'grid', gridGap: 'var(--nav-gap)', ...sx }} {..._(props)}>
      {value}
    </div>
  ),
  language_switcher: ({ value, sx, ...props }) => (
    <Underline sx={{ textTransform: 'capitalize', ...sx }} inverted offset {..._(props)}>
      {value}
    </Underline>
  ),
  nav_item: ({ value, is_special, allowSpecial, uid, ...props }) => {
    return !!is_special && !!allowSpecial ? (
      <Cta
        size="fit"
        id={'nav-item-' + uid}
        {...pick(props, ['as', 'to', 'className', 'data-nav-item', 'state', 'style'])}
      >
        {value}
      </Cta>
    ) : (
      <Underline variant="text" id={'nav-item-' + uid} {..._(props)}>
        {value}
      </Underline>
    )
  },
  menu_item: ({ value, is_special, allowSpecial, uid, className, ...props }) => (
    <SkewLink data-cropped="false" className={['menu-item-' + uid, className].join(' ')} {..._(props)}>
      {value}
    </SkewLink>
  ),
  running_text: ({ value, multiple = false, ...props }) => (
    <Section variant="running" data-multiple={multiple} data-inview="running" {...inviewProps} {..._(props)}>
      <hr />
      {value}
    </Section>
  ),
  columns_text: ({ value, multiple = false, ...props }) => (
    <div data-multiple={multiple} {..._(props)}>
      {value}
    </div>
  ),
  heading: ({ value, is_head, anchor, wrapperProps = {}, ...props }) => (
    <Section variant="heading" data-head={!!is_head} {..._(wrapperProps)} data-inview="fade" {...inviewProps}>
      {anchor}
      <Heading as={is_head ? 'h1' : 'h2'} variant="title" sx={{ transform: 'translateZ(0px)' }} {..._(props)}>
        {value}
      </Heading>
    </Section>
  ),
  gallery: ({ value, sx, ...props }) => (
    <Section sx={{ variant: 'gallery', ...sx }} className="Gallery" {..._(props)}>
      {value}
    </Section>
  ),
  head: ({ text, children, ...props }) => (
    <Head {..._(props)}>
      {text && (
        <Heading as="h1" variant="title">
          {text}
        </Heading>
      )}
      {children}
    </Head>
  ),
  collapsible: {
    auto: true,
    head: ({ value, withInview = true, content, withContent, showIcon = true, image, init, id, sx, props }) => {
      const multiple = Array.isArray(value)

      return (
        <div sx={{ variant: 'collapsible', ...sx }} data-inview={!!withInview ? 'list' : null} {...props.wrapper}>
          {!!image && !!image?.filename && (
            <aside className="Featured">
              <StoryblokImage
                data={image}
                inview={init}
                object={{ fit: 'cover' }}
                lazyload={true}
                fitted={true}
                //
              />
            </aside>
          )}

          <hr className="-top" />
          <div>
            <Grid
              sx={{ variant: 'collapsible.head', '--length': multiple ? value.length : 1 }}
              data-multiple={multiple ? !!(value.length > 1) : false}
              data-standalone={false}
              {...props.button}
            >
              {multiple ? (
                value?.map((v, i) => (
                  <Box key={id + i} as="h3" sx={{ variant: 'collapsible.text' }}>
                    {v?.text ?? v}
                  </Box>
                ))
              ) : (
                <Box as="h3" sx={{ variant: 'collapsible.text' }}>
                  {value}
                </Box>
              )}

              <Flex sx={{ variant: 'collapsible.icon', visibility: !!!showIcon && 'hidden' }}>
                <Flex as="aside" />
              </Flex>
            </Grid>
          </div>
          {!!withContent && content}
          <hr className="-bottom" />
        </div>
      )
    },
    section: { variant: 'regular' },
    rte: { sx: { variant: 'collapsible.rte' } },
    services: { variant: 'collapsible.service_list' },
  },
  obfuscate: {
    component: ({ value, ...props }) => <Underline {..._(props)}>{value}</Underline>,
    email: { pre: null },
    phone: { pre: null },
  },
  icons,
}
