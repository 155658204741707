import { inset } from '@monobits/core'

export default {
  container: {
    variant: 'layout.section.vh',
    '&': { variant: 'layout.container.box' },

    '--offset': 'calc(var(--header-height) + (var(--padding-x) * 2))',

    flexDirection: 'column',
    justifyContent: 'space-between',

    '@media (hover: none)': {
      '--grid-auto-flow': 'row',
      '--grid-template-columns': 'unset',
      '--grid-template-rows': 'unset',
    },
    '@media (hover: hover)': {
      '--grid-auto-flow': ['row', null, 'column'],
      '--grid-template-columns': ['unset', null, `repeat(2, 1fr)`],
      '--grid-template-rows': ['unset', null, `repeat(3, auto)`],
    },

    overflow: 'hidden',

    gap: 1,

    mt: 'var(--mt)',
    '--mt': 'var(--padding-l)',
  },
  wrapper: {
    gridTemplateColumns: ['1fr', null, '1fr 2fr'],
    gap: '3rem',
  },
  main: {
    gap: '0em',
    // gridColumnGap: [2, '4em'],

    // gridAutoFlow: 'var(--grid-auto-flow)',
    // gridTemplateColumns: 'var(--grid-template-columns)',
    // gridTemplateRows: 'var(--grid-template-rows)',

    justifyItems: 'start',
  },
  second: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',

    pt: 1,

    hr: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
    },

    '>div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      width: 'fit-content',
      gap: '1.75rem',
    },
    '>h6': {
      display: ['none', 'flex'],
    },
  },

  overlay: {
    position: 'fixed',
    zIndex: 'subnav',
    ...inset(0),

    bg: 'background',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',

    '>div': { mt: 0 },

    visibility: 'hidden',
    opacity: 0,

    '[data-gsap]:not([data-gsap="menu-d"])': {
      opacity: 0,
      transform: 'translateY(3rem)',
    },
  },
}
