import { useThemeUI } from 'theme-ui'
import { useConditionalEffect } from '@react-hookz/web'

const ScrollBridge = () => {
  const { exit } = useThemeUI().theme?.transitions ?? {}

  useConditionalEffect(
    () => {
      window._scroll_delay_ms = 0.4 * 1000
    },
    [exit],
    [exit != null]
  )

  return null
}

export default ScrollBridge
