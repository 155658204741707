export default (overrides) => ({
  useLocalStorage: true,
  useColorSchemeMediaQuery: false,

  initialColorModeName: 'light',

  useStickyHeader: true,
  useShyHeader: true,

  colorTransitions: {
    duration: 0.6,
    ease: 'none',
  },

  pageTransitions: {
    duration: 1,
  },

  locomotive: {
    smooth: true,
    multiplier: 1.15,
    lerp: 0.075,

    scrollFromAnywhere: false,
    tablet: { breakpoint: 992 },
    extends: {
      scrub: false,
      rootPercent: 10,
      scrollDuration: 800,
    },
  },

  medias: {
    steps: 400,
    params: { quality: 92 },
    placeholder: false,
    placeholder_modifiers: '/1x1/filters:quality(1)',
    transition: 'var(--ui-duration) var(--ui-ease)',
    fade: true,
  },

  images: {
    steps: 400,
    params: { quality: 92 },
    object: { fit: 'cover', position: 'center center' },
    transition: 'var(--ui-duration) var(--ui-ease)',
    placeholder: false,
    placeholder_modifiers: '/1x1/filters:quality(1)',
    fade: true,
    observer: {
      rootMargin: '800px 0px 1250px 0px',
      threshold: 0.0,
    },
  },

  ...overrides,
})
