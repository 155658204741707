import Placeholder from './Placeholder'
import GlobalComponent from './GlobalComponent'

import * as X from '../components'

import Links from '../atoms/Links'
import MultilineLink from '../atoms/MultilineLink'

const Components = {
  global: GlobalComponent,
  'use-global': GlobalComponent,
  'nav-item': X.NavItem,
  'social-item': X.SocialItem,
  'text-item': X.TextItem,
  'copyrights-mention': X.Copyrights,
  'email-link': X.Obfuscate.Email,
  'phone-link': X.Obfuscate.Phone,
  'multiline-link': MultilineLink,
  heading: X.Heading,
  navigation: Links,
  collapsibles: X.Collapsibles,
  gallery: X.Gallery,
  'gallery-block': X.GalleryBlock,
  'project-info': X.ProjectInfo,
  asset: X.Asset,
  'asset-plus': X.Asset,
  'media-plus': X.Asset,
  image: X.Image,
  lottie: X.Lottie,
  video: X.Video,
  player: X.VideoPlayer,
  rte: X.RteItem,
  head: X.Head,
  'running-text': X.RunningText,
  'featured-jobs': X.FeaturedJobs,
  'featured-members': X.FeaturedMembers,
  'featured-projects': X.FeaturedProjects,
  'featured-services': X.FeaturedServices,
  'other-projects': X.OtherProjects,
  'contact-section': X.ContactSection,
  'project-list': X.ProjectList,
}

const DynamicComponent = ({ blok, source, ...props }) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return <Component blok={blok} key={blok._uid} {...props} />
  }

  return blok.component ? <Placeholder componentName={blok.component} /> : null
}

export default DynamicComponent
