import { createContext, memo } from 'react'
// import { Script } from 'gatsby'
import { getWindow, getDocument } from 'ssr-window'
import { useThemeUI, Box } from 'theme-ui'
import { useSessionStorageValue, useConditionalEffect } from '@react-hookz/web'
import { ColorModeTransition } from '@monobits/color-transitions'
import { ImageProvider } from '@boiler/image'
import { VideoProvider } from '@boiler/hls'
import { MergedComponent } from '@boiler/utils'
import { MatterProvider } from '@boiler/matter'
import { Transition } from '@boiler/framer'
import { ObserverProvider } from '@boiler/intersection-observer'
import Smoother from '@boiler/smoother'

import useStoryblok from './useStoryblok'
import definitions from '../definitions'

import Intro from '../components/Intro'
import HireUsButton from '../components/HireUsButton'
import Header from '../components/Header/Header'
import Menu from '../components/Menu'

import Sizes from './Sizes'
import ScrollBridge from './ScrollBridge'
import { useSetResponsiveAttr } from '../hooks'

const window = getWindow()
const document = getDocument()
export const ComponentsContext = createContext()
export const StoryblokContext = createContext()

const Root = ({ children, pageContext, location }) => {
  const { transitions = {} } = useThemeUI().theme ?? {}
  const { settings, locales = {} } = useStoryblok(pageContext, location)
  const { lang, story } = pageContext ?? {}

  const [rendered, setRendered, removeRendered] = useSessionStorageValue('app-rendered', 0, {
    handleStorageEvent: true,
    isolated: false,
  })

  // const slug = story?.slug ?? ''

  window.onunload = () => removeRendered()

  useSetResponsiveAttr()

  // const isHome = !!(slug === 'home')
  // const isNotFound = !!(slug === 'page-not-found')
  const inEditor = !!location?.search?.includes('_storyblok')
  const is404 = pageContext == null || !!!(pageContext ?? {})?.config

  // useConditionalEffect(
  //   () => {
  //     setRendered(2)
  //     document.documentElement?.setAttribute('data-rendered', true)
  //   },
  //   undefined,
  //   []
  // )
  useConditionalEffect(
    () => {
      setRendered(2)
      document.documentElement?.setAttribute('data-rendered', true)
    },
    undefined,
    [!!inEditor]
  )

  return (
    <>
      {/* <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GTAG}"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />
      <Script id="gtag-config" strategy="off-main-thread" forward={[`gtag`]}>
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.GATSBY_GTAG}');
        `}
      </Script> */}
      {/* <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.GATSBY_GTAG_GA4}`}
        strategy="off-main-thread"
      />
      <Script id="gtag-config" strategy="off-main-thread" forward={[`gtag`]}>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.GATSBY_GTAG_GA4}', { page_path: location ? location.pathname + location.search + location.hash : undefined })
        `}
      </Script> */}

      <ColorModeTransition />
      <Sizes callbacks={location} />
      <ScrollBridge location={location} />

      <Smoother.Provider
        id="root"
        prefix=""
        wrapper={[Box, { as: 'main' }]}
        content={['div']}
        configs={{
          smooth: 1.2,
          effects: true,
          normalizeScroll: { allowNestedScroll: true },
          ignoreMobileResize: true,
          onUpdate: (self, { set, get }) => {
            set.scrollDirection(self)
            set.scrollPosition(self, { get, rootMargin: '28%' })
          },
        }}
      >
        {({ ids }) => (
          <ObserverProvider
            options={{
              root: ids?.wrapper,
              offset: '-12%',
            }}
            inview={{ once: true, disabled: !!inEditor }}
          >
            <StoryblokContext.Provider
              value={{
                settings,
                locales,
                lang,
                component: story?.field_component ?? 'preview',
                rendered: [rendered, setRendered],
              }}
            >
              <MergedComponent
                components={[ImageProvider, VideoProvider]}
                value={{
                  steps: 400,
                  params: { quality: 92 },
                  placeholder: false,
                  placeholder_modifiers: '/1x1/filters:quality(1)',
                  transition: 'var(--ui-duration) var(--ui-ease)',
                  fade: true,
                }}
              >
                <Transition.Provider lang={lang} transitions={transitions}>
                  {rendered !== 2 && !!!inEditor && <Intro setRendered={setRendered} is404={is404} />}

                  <ComponentsContext.Provider value={definitions}>
                    <Menu watch={[location?.key ?? '']} cta={<HireUsButton data-gsap="menu-d" data-cta="menu" />} />

                    <Header location={location} />

                    <Smoother.Wrapper>
                      <MatterProvider>
                        <Transition.Page as="div" location={location}>
                          <div id="main" sx={{ '>div>*:last-child': { mb: '0!important' } }}>
                            {children}
                          </div>
                        </Transition.Page>
                      </MatterProvider>
                    </Smoother.Wrapper>
                  </ComponentsContext.Provider>
                </Transition.Provider>
              </MergedComponent>
            </StoryblokContext.Provider>
          </ObserverProvider>
        )}
      </Smoother.Provider>
    </>
  )
}

export default memo(Root)
