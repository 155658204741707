import { useObserver, useObserverContext } from '.'

const useViewEffect = (
  selectors = [],
  onUpdate = () => {},
  { options = {}, inview = {} } = {},
  dependencies = [],
  predicates = []
) => {
  const { inview: global_options } = useObserverContext() ?? {}
  const { once, disabled } = { ...global_options, ...inview }

  const watch = JSON.stringify({ once, disabled, ...dependencies })

  const ref = useObserver(
    selectors,
    (entry, obs) => {
      onUpdate(entry, obs)
      if (!!once && !!entry?.isIntersecting) obs?.unobserve(entry?.target)
    },
    options,
    [watch],
    [!!!disabled, ...predicates]
  )

  return ref
}

// const inviewRef = useViewEffect(
//   (entry) => {
//     const visible = !!entry?.isIntersecting
//     gsap.to(entry?.target, { backgroundColor: !!visible ? '#36FD86' : '#FD36C8' })
//   },
//   {
//     options: { rootMargin: '-20%' },
//     inview: { once: false },
//   }
// )

export default useViewEffect
