const filterify = (obj = {}) =>
  '/filters:' +
  Object.entries(obj)
    .map(([key, value]) => (!!value ? `${key}(${value})` : false))
    .filter(Boolean)
    .join(':')

export const getImageProps = ({ filename = '', fieldtype = '', id = 0, ...props }) => {
  const path = filename?.replace(/(https?:)?\/\/a.storyblok.com/i, '')

  const sizes = path ? path.split('/')[3].split('x') : [0, 0]
  const [x, y] = sizes.map((i) => +i)

  return {
    id,
    width: x,
    height: y,
    aspect_ratio: x / y,
    filename,
    fieldtype,
    props,
  }
}

export const sdk = (
  { filename = '', fieldtype = '', id = 0, ...props },
  { width = 0, height = 0, placeholder_modifiers = '', ...filters }
) => {
  const base = 'https://a.storyblok.com'
  const path = filename?.replace(/(https?:)?\/\/a.storyblok.com/i, '')

  const resize = [width, height].join('x')
  const result = `${path}/m/${resize}/${filterify(filters)}`.replace('//', '/')
  const low = `${path}/m${placeholder_modifiers}`

  return {
    src: `${base}${result}`,
    placeholder: `${base}${low}`,
    props,
  }
}
