import SbEditable from 'storyblok-react'
import Bloks from './Bloks'

const GlobalComponent = ({ blok, ...props }) => {
  const { global: globalBlok } = blok.reference?.content || blok || {}

  const content = globalBlok?.length
    ? globalBlok.map((childBlok) => <Bloks blok={childBlok} key={childBlok._uid} {...props} />)
    : null

  return (
    <SbEditable content={blok} key={blok._uid}>
      {content}
    </SbEditable>
  )
}

export default GlobalComponent
