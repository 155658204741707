import canAutoPlay from 'can-autoplay'

import { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { useStateRef } from './useStateRef'
import { getOptimalRootMargin } from '@boiler/intersection-observer'
import { all as merge } from 'deepmerge'
import { useInView } from 'framer-motion'
import useEvent from 'react-use-event-hook'

import videojs from 'video.js'
import './video.css'

import { BigPlay } from './components/BigPlay'
import { FallbackPlay } from './components/FallbackPlay'
import { useClassListObserver } from './useClassListObserver'
import { useComponents } from './video.components'
import { COMMON, MODES } from './video.defaults'
import { video, styles } from './Video.variant'
export { video }

export const Video = forwardRef(
  (
    {
      mode,
      playing,
      preset = 'player',
      lazyload = true,
      lazypause = true,
      allowFallbackControls = true,
      allowFallbackMedia = true,
      options: _options,
      withBlendMode = false,
      playButtonText = undefined,
      aspectRatio,
      objectFit,
      overlay,
      onReady,
      onMetadataReady,
      sx,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const tapped = useRef(false)
    const playedOnce = useRef(false)
    const classList = useRef([])
    const mounted = useRef(false)
    const viewRef = useRef(null)
    const videoRef = useRef(null)
    const mountRef = useRef(null)
    const playerRef = useRef(null)
    const [hasStarted, setHasStarted] = useState(false)
    const [isReady, setIsReady] = useState(false)

    if (preset === 'player') {
      allowFallbackControls = false
    }

    const [isPrevented, setIsPrevented, isPreventedRef] = useStateRef(null)

    useImperativeHandle(ref, () => viewRef.current)

    const options = merge([COMMON, MODES?.[preset], _options])

    const isAutoplay = options?.autoplay ?? false

    const watch = JSON.stringify({
      playButtonText,
      poster: !!children,
    })

    const getVideoElement = useEvent(() => {
      const player = playerRef.current

      if (!player) return {}
      return player.el().childNodes?.[0]
    })

    const handleComponents = useComponents({ children, playButtonText }, [watch])

    // Play on first click
    const handleSuspence = useEvent(() => {
      if (playerRef.current && !tapped.current) {
        const player = playerRef.current
        player.ready(() => {
          !!player.paused() && player.play()
          tapped.current = true
        })
      }
    })

    /**
     * Set the max width of the progress bar.
     *
     * - Makes sure the tooltip (duration) stay's contained
     */
    const handleResize = useEvent(() => {
      const video = videoRef.current
      const player = playerRef.current

      if (player && video) {
        const progressBarNode = video.querySelector('.vjs-progress-control')
        const tooltipNode = video.querySelector('.vjs-play-progress .vjs-time-tooltip')

        const offsetLeft = progressBarNode?.offsetLeft ?? 0
        const maxWidth = video.clientWidth - offsetLeft - (tooltipNode?.clientWidth ?? 0)

        progressBarNode.style.setProperty('--max-width', maxWidth + 'px')
      }
    })

    const handlePlaying = useEvent(() => {
      const player = playerRef.current

      if (playing != null && player) {
        if (!playing) {
          !player.paused() && player.pause()
        } else if (isAutoplay && !isPrevented) {
          !!player.paused() && player.play()
        }
      }
    })

    const handleInView = useEvent(() => {
      const player = playerRef.current

      if (player && !!lazypause && playing == null) {
        if (!isInViewStrict) {
          !player.paused() && player.pause()
        } else if (isAutoplay && !isPreventedRef.current) {
          !!player.paused() && player.play()
        }
      }
    })

    const handlePlay = useEvent(() => {
      if (!playedOnce.current) {
        setHasStarted(true)
        handlePlaying()
        handleInView()
        handleResize()
      }

      const videoElement = getVideoElement()
      if (videoElement && isPreventedRef.current && isAutoplay) {
        videoElement.controls = false
        setIsPrevented(false)
      }

      playedOnce.current = true
    })

    const handleFallshort = useEvent(() => {
      const videoElement = getVideoElement()
      if (videoElement && isAutoplay) {
        videoElement.controls = isPreventedRef.current
      }
    })

    useEffect(() => {
      canAutoPlay.video({ timeout: 10_000, inline: true, muted: true }).then(({ result }) => {
        if (result === true) {
          setIsPrevented(false)
        } else {
          setIsPrevented(true)
        }
      })
    }, []) // eslint-disable-line

    /**
     * Clone `video-js` classList onto the parent of this component
     */
    const { NewClassListObserver } = useClassListObserver(
      playerRef.current,
      (list) => {
        const video = videoRef.current

        if (video) {
          const cloneList = list.filter((i) => i !== 'video-js').map((i) => i.replace('vjs', 'player'))
          video.parentNode.classList = [...classList.current, ...cloneList].join(' ')
        }
      },
      () => {
        const video = videoRef.current
        if (video) {
          classList.current = [...video.parentNode.classList].filter((i) => !i.startsWith('player'))
        }
      }
    )

    const MARGIN = getOptimalRootMargin()
    // render player when soon to be in viewport
    const isInView = useInView(mountRef, {
      margin: `${MARGIN} 0px ${MARGIN} 0px`,
      once: false,
    })
    // pause when player is out of viewport
    const isInViewStrict = useInView(viewRef, {
      margin: typeof lazypause === 'string' ? lazypause : '0px',
      once: false,
    })

    const shouldRender = lazyload ? isInView : true

    useEffect(() => {
      const player = playerRef.current
      if (player && player.isReady_) {
        handlePlaying()
      }
    }, [JSON.stringify({ playing })]) // eslint-disable-line

    useEffect(() => {
      const player = playerRef.current
      if (player && player.isReady_) {
        handleInView()
      }
    }, [JSON.stringify({ options, lazypause, isInViewStrict })]) // eslint-disable-line

    useEffect(() => {
      const classListObserver = NewClassListObserver()
      const resizeObserver = new ResizeObserver(handleResize)

      if (videojs.browser.IS_IOS && isAutoplay && allowFallbackMedia && preset !== 'player') {
        options.sources = options.sources.filter((i) => i.type !== 'application/x-mpegURL')
      }

      if (!playerRef.current && shouldRender) {
        window['VIDEOJS_NO_DYNAMIC_STYLE'] = true

        const videoElement = document.createElement('video-js')
        videoRef.current.appendChild(videoElement)

        const player = (playerRef.current = videojs(videoElement, options, () => {
          typeof onReady === 'function' && onReady(player)

          player.one('loadedmetadata', () => {
            typeof onMetadataReady === 'function' && onMetadataReady(player)
            handleFallshort()
            setIsReady(true)
          })
        }))

        player.on('play', handlePlay)

        mounted.current = true

        handleComponents(player)
        classListObserver.observe(player.el(), { attributes: true })
        resizeObserver.observe(videoRef.current)
      } else if (shouldRender) {
        const player = playerRef.current

        player.on('play', handlePlay)
        player.autoplay(options.autoplay)
        player.src(options.sources)

        handleComponents(player)

        setHasStarted(false)
        player.one('play', handlePlay)
        classListObserver.observe(player.el(), { attributes: true })
        resizeObserver.observe(videoRef.current)
      }

      const player = playerRef.current
      const video = videoRef.current

      return () => {
        if (player && video) {
          player.off('play', handlePlay)
          classListObserver.disconnect()
          resizeObserver.unobserve(video)
          playedOnce.current = false
          setIsReady(false)
        }
      }
    }, [JSON.stringify(options), watch, shouldRender, videoRef]) // eslint-disable-line

    useEffect(() => {
      const player = playerRef.current
      const videoElement = videoRef.current

      return () => {
        if (player && !player.isDisposed() && !!mounted.current) {
          setHasStarted(false)
          // prevent flash of pink (from video stream)
          !!videoElement && (videoElement.style.visibility = 'none')

          player.dispose()
          playerRef.current = null
        }
      }
    }, [shouldRender, playerRef])

    return (
      <>
        <div
          ref={mountRef}
          sx={{
            position: 'absolute',
            inset: 0,
            zIndex: '0',
            pointerEvents: 'none',
          }}
        />
        <div
          ref={viewRef}
          onClick={handleSuspence}
          className={[
            className,
            'VideoPlayer',
            !!isReady && '-is-ready-fade',
            !!isPrevented && '-is-prevented',
            !!children && '-has-poster',
            !!aspectRatio && '-has-aspect-ratio',
            !!withBlendMode && '-with-blend-mode',
            !!isAutoplay && !allowFallbackControls ? '-disallow-fallback-controls' : null,
          ]
            .filter(Boolean)
            .join(' ')}
          style={{ '--aspect-ratio': aspectRatio }}
          data-object-fit={objectFit ?? undefined}
          data-preset={preset}
          sx={{
            ...styles,
            variant: ['video.modes', mode].filter(Boolean).join('.'),
            '>.Video>video-js': {
              zIndex: 2,
            },
            '>.Video>*:not(video-js)': {
              pointerEvents: 'none',
              transform: 'scale(1.006)',
              zIndex: 1,
            },
            '&.-disallow-fallback-controls': {
              pointerEvents: 'none',
            },
            ...sx,
          }}
          {...props}
        >
          {typeof overlay === 'function' && overlay(hasStarted)}

          {preset === 'player' && <BigPlay player={playerRef.current} />}

          <div ref={videoRef} className="Video video-js vjs-theme-mono">
            {children}
          </div>

          {!!isPrevented && !!allowFallbackControls && <FallbackPlay />}
        </div>
      </>
    )
  }
)

// videojs.time.setFormatTime(formatTime)

// export function formatTime(seconds, guide = seconds) {
//   seconds = seconds < 0 ? 0 : seconds
//   let s = Math.floor(seconds % 60)
//   let m = Math.floor((seconds / 60) % 60)
//   let h = Math.floor(seconds / 3600)
//   // const gm = Math.floor((guide / 60) % 60)
//   const gh = Math.floor(guide / 3600)

//   // handle invalid times
//   if (isNaN(seconds) || seconds === Infinity) {
//     h = m = s = '-'
//   }
//   h = h > 0 || gh > 0 ? h + ':' : ''
//   m = (m < 10 ? '0' + m : m) + ':'
//   s = s < 10 ? '0' + s : s
//   return h + m + s
// }

Video.displayName = 'Video'

export default Video
