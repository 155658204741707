import { useUpdateEffect } from '@react-hookz/web'
import { useSmoother, refresh } from '.'
import { EFFECTS } from '../__consts'

const useSmoothFX = (props = {}) => {
  const { prefix: prefix_key } = useSmoother() ?? {}
  const prefix = !!prefix_key ? '-' + prefix_key : ''

  useUpdateEffect(refresh, [props])

  return ['speed', 'lag']?.reduce((acc, i) => {
    const value = props?.[i]
    return { ...acc, ...(value != null && value !== EFFECTS?.[i] && { [`data${prefix}-${i}`]: value }) }
  }, {})
}

export default useSmoothFX
