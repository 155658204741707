import { useMemo } from 'react'

// import { BigPlay } from './components/BigPlay'
// import { PlayToggle } from './components/PlayToggle'
// import { VolumeControl } from './components/VolumeControl'
// import { FullscreenToggle } from './components/FullscreenToggle'
// import { PaddingToggle } from './components/PaddingToggle'
// import { Loader } from './components/Loader'

import { ogRatioPlugin } from './plugins/ogRatioPlugin'
import { hourClassPlugin } from './plugins/hourClassPlugin'
import { disallowFullscreenPlugin } from './plugins/disallowFullscreenPlugin'
// import { vjsReactBigPlay } from './components/VjsReactComponent'

export const components =
  ({ children }) =>
  (player) => {
    ogRatioPlugin(player)

    if (player.getChild('LoadingSpinner')) {
      player.removeChild('LoadingSpinner')
    }

    if (children) player.removeChild('PosterImage')

    if (player.getChild('BigPlayButton')) {
      player.removeChild('BigPlayButton')
    }

    hourClassPlugin(player)
    disallowFullscreenPlugin(player)

    // player.removeChild('vjsReactLoader')
    // player.addChild('vjsReactLoader', {
    //   handle: 'vjs-custom-loader',
    //   children: (args) => <Loader {...args} />,
    // })

    // const theBigPlay = vjsReactBigPlay(player, {
    //   handle: 'vjs-custom-big-play-button',
    //   children: (args) => <BigPlay {...args} />,
    // })
    // player.removeChild(theBigPlay)
    // player.addChild(theBigPlay)

    // player.removeChild('vjsReactBigPlay')
    // player.addChild('vjsReactBigPlay', {
    //   handle: 'vjs-custom-big-play-button',
    //   children: (args) => <BigPlay {...args} text={playButtonText} />,
    // })

    // player.getChild('ControlBar').removeChild('vjsReactControls')
    // player.getChild('ControlBar').addChild(
    //   'vjsReactControls',
    //   {
    //     handle: 'vjs-custom-play-volume',
    //     children: (args) => (
    //       <div className="vjs-control-container">
    //         <PlayToggle {...args} />
    //         <VolumeControl {...args} />
    //       </div>
    //     ),
    //   },
    //   0
    // )

    // player.getChild('ControlBar').removeChild('vjsReactScreens')
    // player.getChild('ControlBar').addChild(
    //   'vjsReactScreens',
    //   {
    //     handle: 'vjs-custom-fullscreen',
    //     children: (args) => (
    //       <div className="vjs-control-container">
    //         <PaddingToggle {...args} />
    //         <FullscreenToggle {...args} />
    //       </div>
    //     ),
    //   },
    //   20
    // )
  }

export const useComponents = (props, watch) =>
  useMemo(
    () => components(props),
    [watch] // eslint-disable-line
  )
