import { BigPlayButton } from './BigPlayButton'
// import { useEvent } from 'react-use-event-hook'

export const BigPlay = ({ text = 'Play Video', el_: parentElement, ...props }) => {
  const player = props.player

  // if (player.getChild('BigPlayButton')) {
  //   player.removeChild('BigPlayButton')
  // }

  // const handleClick = useEvent(() => {
  //   if (player) {
  //     !!player.paused() && player.play()
  //   }
  // })

  return (
    <BigPlayButton
      className="-vjs-escape"
      // onClick={handleClick}
      pointerEvents="auto"
      size="5rem"
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: 3,

        transform: 'translate(-50%,-50%)',

        [[
          '.player-controls-disabled &',
          '.player-has-started &',
          '.player-using-native-controls &',
          '.player-error &',
          //
        ].join(', ')]: {
          display: 'none',
        },
      }}
    />
  )
}

export default BigPlay
