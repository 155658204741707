import { useThemeUI } from 'theme-ui'
import { useMatterEffect, parseSvg, select, parseResponsive, parseMatterProps } from '@boiler/matter'

import { four as f, zero as z } from './verticles.js'

const Matter404 = ({ scene, ...props }) => {
  const { text: color = '#000000' } = useThemeUI()?.theme?.rawColors ?? {}

  const x = parseMatterProps(scene) ?? {}
  const changedKey = JSON.stringify(scene ?? {})

  const [ref, status] = useMatterEffect(
    ({ Matter, mouseEvents, size: { width, height }, world }) => {
      Matter.Common.setDecomp(require('poly-decomp'))

      const thickness = 1000
      const offset = thickness * 0.5
      const wallProps = { isStatic: true, render: { visible: false } }

      const [four, zero] = [
        { paths: select(parseSvg(f), 'path'), getScale: (column_width = 0) => column_width / 60.71 },
        { paths: select(parseSvg(z), 'path'), getScale: (column_width = 0) => column_width / 58.33 },
      ]

      // WALLS
      Matter.Composite.add(world, [
        // Matter.Bodies.rectangle(width / 2, -offset, width, thickness, wallProps),
        Matter.Bodies.rectangle(width / 2, height + offset, width, thickness, wallProps),
        Matter.Bodies.rectangle(-offset, 0, thickness, height * 2, wallProps),
        Matter.Bodies.rectangle(width + offset, 0, thickness, height * 2, wallProps),
      ])

      const values = {
        side: 0,
        rows: 1,
        columns: 3,
      }

      const { side, rows, columns } = parseResponsive(width, values)

      const viewport_width = width + side
      const part_width = viewport_width / columns
      const stack_gap = part_width * 0.35
      const column_width = part_width - stack_gap

      const setup = (obj, column_width) =>
        obj?.paths?.map((path) => {
          const scale = obj?.getScale(column_width)
          const vert = Matter.Svg.pathToVertices(path, 3)
          return Matter.Vertices.scale(vert, scale, scale)
        })

      const stack = Matter.Composites.stack(-side, -(height * 0.8), columns, rows, stack_gap, stack_gap, (x, y, i) => {
        const v = {
          four: setup(four, column_width),
          zero: setup(zero, column_width),
        }

        return Matter.Bodies.fromVertices(
          x,
          y,
          [v?.four, v?.zero, v?.four]?.[i],
          {
            restitution: x?.restitution ?? 0,
            render: {
              fillStyle: color,
              strokeStyle: color,
              lineWidth: 1,
            },
          },
          true
        )
      })

      Matter.Composite.add(world, stack)

      mouseEvents({
        ignoreScroll: true,
        constraint: {
          stiffness: 0.2,
          render: { visible: false },
        },
      })
    },
    {
      responsive: true,
      options: {
        background: 'transparent',
        wireframes: !!x?.wireframe,
      },
      runner: { fps: 120 },
      engine: {
        positionIterations: x?.position_iterations,
        constraintIterations: x?.constraint_iterations,
        velocityIterations: x?.velocity_iterations,
        gravity: {
          scale: x?.gravity_scale,
          x: x?.gravity_x,
          y: x?.gravity_y,
        },
        timing: {
          timeScale: x?.timing_timescale,
          lastDelta: x?.timing_last_delta,
        },
      },
    },
    [color, changedKey]
  )

  return <div ref={ref} data-status={status} {...props} />
}

export default Matter404
