const themed = {
  position: 'relative',
  container: 'vjs / inline-size',
  overflow: 'hidden',

  'video-js,.video-js': { background: 'transparent' },

  '&.-has-poster': {
    '&.player-has-started': {
      'video,video-js,.video-js': {
        transition: 'opacity 0.15s var(--inview-ease)',
      },
    },
  },
  '&:not(.-has-poster)': {
    '&[data-preset="passive"].FallbackPlay': { display: 'none' },
    '&.player-has-started': {
      'video,video-js,.video-js': {
        transition: 'opacity var(--inview-duration) var(--inview-ease)',
      },
    },
  },

  '&.-has-poster:not(.player-has-started)': {
    video: { opacity: 0 },
  },

  '&:not(.-has-poster):not(.-is-prevented)': {
    video: { opacity: 0 },
  },
  '&:not(.-has-poster).-is-ready-fade.player-has-started': {
    'video,video-js,.video-js': { opacity: 1, transition: 'opacity var(--inview-duration) var(--inview-ease)' },
  },

  '.Video.vjs-theme-mono': {
    'video-js': {
      // '--vjs-container-padding': 'clamp(12px, 1.5cqi, 30px)',

      '--vjs-play-button-bg': 'var(--theme-ui-colors-background)',
      '--vjs-play-button-color': 'var(--theme-ui-colors-text)',

      '--vjs-progress-rgb': '255,255,255',
      '--vjs-progress-color': 'rgba(var(--vjs-progress-rgb), 1)',
      '--vjs-load-color': 'rgba(var(--vjs-progress-rgb), 0.2)',
      '--vjs-base-color': 'rgba(var(--vjs-progress-rgb), 0.1)',

      '--vjs-padding-s': 'clamp(12px, 1.5cqi, 30px)',

      '--vjs-size': '4em',
    },

    '.vjs-control-bar': {
      px: 'calc(var(--vjs-padding-s) * 0.25)',
      mx: 'var(--vjs-padding-s)',
      mb: 'var(--vjs-padding-s)',
      width: 'calc(100% - (var(--vjs-padding-s) * 2))',
      height: 'var(--vjs-size)!important',
      bg: 'var(--vjs-base-color)!important',
      borderRadius: '9999px',
      // bg: 'unset!important',

      '.vjs-icon-placeholder:before': {
        lineHeight: '2.25',
      },
    },

    '.vjs-volume-control .vjs-volume-bar': {
      bg: 'unset!important',
    },

    '.vjs-progress-holder': {
      height: '100%',
      // borderRadius: '9999px',
      bg: 'var(--vjs-base-color)',
      // overflow: 'hidden',
    },

    '.vjs-time-control': {
      lineHeight: 'var(--vjs-size)',
    },

    '.vjs-play-progress:before': {
      display: 'none',
    },

    '.vjs-play-progress>.vjs-time-tooltip': {
      color: 'black',
      background: 'rgba(255,255,255, 0.4)',

      '@supports (backdrop-filter: blur(1px))': {
        bg: 'rgba(255,255,255, 0.2)',
        backdropFilter: 'blur(20px)',
      },
    },
    '.vjs-mouse-display>.vjs-time-tooltip': {
      color: 'black',
      background: 'rgba(255,255,255, 0.8)',

      '@supports (backdrop-filter: blur(1px))': {
        bg: 'rgba(255,255,255, 0.6)',
        backdropFilter: 'blur(20px)',
      },
    },

    '.vjs-mouse-display': {
      bg: 'var(--vjs-progress-color)!important',
    },
    '.vjs-slider-horizontal .vjs-volume-level': {
      height: '1px',
    },
    '.vjs-slider-horizontal .vjs-volume-level:before': {
      // lineHeight: '1.5px',

      content: '""',
      height: '16px',
      width: '1px',
      backgroundColor: 'var(--vjs-progress-color)',

      right: '0',
      top: '-7px',
    },
    '.vjs-volume-horizontal .vjs-mouse-display': {
      height: '16px',

      right: '0',
      top: '-1px',
    },

    '.vjs-volume-bar .vjs-volume-tooltip': {
      color: 'black',
      background: 'rgba(255,255,255, 0.4)',

      '@supports (backdrop-filter: blur(1px))': {
        bg: 'rgba(255,255,255, 0.2)',
        backdropFilter: 'blur(20px)',
      },
    },

    'video-js.vjs-nofull .vjs-custom-fullscreen': { display: 'none' },
    '.vjs-play-progress.vjs-slider-bar .vjs-icon-placeholder.vjs-svg-icon': {
      display: 'none',
    },
    '.vjs-poster img': {
      objectFit: 'cover!important',
    },
    '&.-with-blend-mode .vjs-progress-control': {
      mixBlendMode: 'difference',
    },
    '.vjs-picture-in-picture-control[disabled="disabled"]': { display: 'none' },
    '.vjs-subs-caps-button': { display: 'none' },

    // ---

    '.vjs-progress-control .vjs-play-progress': {
      bg: 'unset!important',
      // overflowX: 'clip',

      // bg: 'var(--vjs-progress-color)',
      // borderTopLeftRadius: '9999px',
      // borderBottomLeftRadius: '9999px',
    },
    // '.vjs-progress-control .vjs-play-progress .vjs-time-tooltip': {
    //   transform: 'translateX(-50%)',
    // },
    '.vjs-progress-control .vjs-play-progress': {
      bg: 'var(--vjs-progress-color)',
      // borderTopLeftRadius: '9999px',
      // borderBottomLeftRadius: '9999px',
      pointerEvents: 'none',
    },
    '.vjs-progress-control .vjs-load-progress': {
      bg: 'unset!important',
    },
    '.vjs-progress-control .vjs-load-progress div': {
      bg: 'var(--vjs-load-color)',
      // borderRadius: '9999px',
    },

    // 'video-js>video': {
    //   transition: 'padding 0.2s var(--ui-ease)',
    // },
    // 'video-js.vjs-view-padded>video': {
    //   p: 'var(--vjs-padded-value) 0',
    // },

    // '.vjs-control-container': {
    //   height: '100%',
    //   display: 'flex',
    //   gap: 'var(--vjs-track-width)',
    // },
    // '.vjs-control-bar': {
    //   height: 'var(--vjs-bar-height)',
    //   gap: 'var(--vjs-container-padding)',

    //   bg: 'rgba(0,0,0,0)',
    //   p: 'var(--vjs-container-padding)',
    // },

    // '.vjs-big-play-button,.vjs-loading-spinner': {
    //   display: 'none',
    // },

    // '.vjs-button > .vjs-icon-placeholder::before': {
    //   lineHeight: 'var(--vjs-bar-height)',
    // },
    // '.vjs-remaining-time.vjs-time-control': {
    //   fontSize: '11px',
    //   fontFamily: 'unica',
    //   p: 0,
    //   display: 'flex',
    //   alignItems: 'center',
    //   lineHeight: '1',
    // },

    // '.vjs-volume-panel': { display: 'none' },

    // 'video-js.vjs-hours-long .vjs-progress-control .vjs-time-tooltip': {
    //   fontSize: '6.25cqi',
    // },

    // '.vjs-progress-control': {
    //   mr: 'auto',
    //   mt: 'calc(var(--vjs-container-padding) * -1)',
    //   height: 'calc(100% + (var(--vjs-container-padding) * 2))',
    //   maxWidth: 'var(--max-width, unset)!important',
    //   // flexGrow: 0,

    //   '::after': {
    //     content: '""',
    //     position: 'absolute',
    //     right: 'calc(var(--vjs-bar-inner-height) * -1)',

    //     height: '30%',
    //     width: 'var(--vjs-bar-inner-height)',
    //     bg: 'var(--vjs-buffering-color)',
    //   },
    // },

    // '.vjs-progress-control .vjs-play-progress:before, .vjs-progress-control .vjs-mouse-display:before': {
    //   position: 'absolute',
    //   content: '""',
    //   right: 0,
    //   bottom: 'calc(var(--vjs-bar-height) * -0.5)',

    //   height: '500vh',
    //   width: 'var(--vjs-line-width)',
    //   bg: 'var(--vjs-text-color)',

    //   pointerEvents: 'none',
    // },
    // '.vjs-progress-control .vjs-slider': {
    //   bg: 'rgba(0,0,0,0)',
    // },
    // '.vjs-progress-control .vjs-load-progress div': {
    //   bg: 'var(--vjs-buffering-color)',
    // },
    // '.vjs-progress-control .vjs-progress-holder': {
    //   margin: '0',
    //   height: 'var(--vjs-bar-inner-height)',
    //   bg: 'var(--vjs-buffering-color)',
    // },
    // '.vjs-progress-control .vjs-time-tooltip': {
    //   bg: 'rgba(0,0,0,0)',
    //   color: 'var(--vjs-text-color)',

    //   visibility: 'visible',
    //   float: 'unset',

    //   px: '0.2em',
    //   fontSize: 'var(--vjs-tooltip-font-size)',
    //   fontFamily: 'unica',

    //   transform: 'translate(100%, 50%)',
    //   top: 'unset',
    //   bottom: 'var(--vjs-tooltip-offset)',
    //   right: '0!important',
    // },
    // '.vjs-progress-control:hover': {
    //   '>div>.vjs-play-progress': {
    //     opacity: 'var(--vjs-tooltip-off-opacity)',
    //   },
    // },
    // '.vjs-progress-control .vjs-mouse-display .vjs-time-tooltip': {
    //   bg: 'rgba(0,0,0,0)',
    //   color: 'var(--vjs-text-color)',
    // },
  },

  '&[data-object-fit="contain"] video-js>video': {
    objectFit: 'contain',
  },
  '&[data-object-fit="cover"] video-js>video': {
    objectFit: 'cover',
  },
  'video-js:not(.vjs-has-started)>video': {
    objectFit: 'cover!important',
  },

  '&.player-has-started.player-user-inactive .-show-as-control': {
    opacity: 0,
    pointerEvents: 'none',
  },
  '&.player-has-started .-show-as-control': {
    transition: 'visibility 1s, opacity 1s',
  },
}

export const styles = {
  ...themed,
}

export const video = {
  variants: {
    // __default: { variant: 'toggle.variants.secondary' },
  },
  modes: {
    __default: { variant: 'video.modes.player' },
    scale: {
      width: '100%',
      height: 'auto',
      '.Video': {
        size: '100%',

        'video-js': {
          pt: '0!important',
          height: '100%!important',
        },
      },
      '&:not([data-object-fit]) video-js>video': {
        objectFit: 'contain',
      },
      aspectRatio: 'var(--og-aspect-ratio, var(--aspect-ratio, 16/9))',
    },
    fluid: {
      width: '100%',
      height: '100%',

      '.Video': {
        size: '100%',

        'video-js': {
          pt: '0!important',
          height: '100%!important',
        },
      },

      '&:not([data-object-fit]) video-js>video': {
        objectFit: 'cover',
      },
      aspectRatio: 'var(--aspect-ratio, var(--og-aspect-ratio, 16/9))',
      // '&.-has-aspect-ratio': {
      // },
    },
  },
}
