import ScrollTo from './ScrollTo'
import Button from './Button'

const BackToTop = ({ sx, children, ...props }) => (
  <Button as={ScrollTo} top icon="arrow-top" {...props}>
    {children}
  </Button>
)

export default BackToTop
