import { Heading } from '@monobits/components'

import { MapBloks } from '../../bloks'

export const ProjectInfo = ({ sx, cycleStyles, ...props }) => {
  const { localize = () => '', tags, tagsList, title, mission, year, credits, _uid } = props ?? {}

  return (
    <div
      sx={{
        variant: 'project.content',
        '&:last-child': {
          mb: '2.5rem',
        },
        ...cycleStyles,
      }}
    >
      <div data-inview="project-info">
        <hr sx={{ gridArea: 'a' }} />

        <Heading
          as="h1"
          data-area="title"
          variant="heading"
          sx={{
            pb: '0.2em',
            mb: '-0.2em',
          }}
        >
          {title}
        </Heading>

        <Heading data-area="year" variant="heading" as="div">
          {year}
        </Heading>

        <div
          data-area="expertise"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
          }}
        >
          {!!(tags ?? []).length && (
            <div data-chunk>
              <Heading as="h3" variant="text">
                {localize('expertise')}
              </Heading>

              <Heading as="p" variant="text" sx={{ maxWidth: '26rem' }}>
                {tagsList}
              </Heading>
            </div>
          )}

          {!!(credits ?? []).length && (
            <div data-chunk>
              <Heading as="h3" variant="text">
                {localize('credits')}
              </Heading>
              <nav>
                <MapBloks items={credits} variant="text" id={_uid} />
              </nav>
            </div>
          )}
        </div>

        <div data-area="mission" data-chunk>
          <Heading as="h3" variant="text">
            {localize('mission-challenge')}
          </Heading>

          <MapBloks items={mission} id={_uid} variant="text" />
        </div>
      </div>
    </div>
  )
}

export default ProjectInfo
