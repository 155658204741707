import { Matter404, HireUsButton } from '../components'
import { useData } from '../hooks'

const NotFound = ({ blok, lang = {} }) => {
  const { rendered: renderState } = useData() ?? {}
  const rendered = (renderState ?? [])?.[0]

  const root_path = lang?.internal === 'default' ? '/' : '/' + (lang?.internal ?? '')

  return (
    <section
      sx={{
        width: '100%',
        height: 'calc(var(--vh-x, 1vh) * 100)',
        display: 'flex',
        alignItems: 'flex-end',
      }}
    >
      <div
        sx={{
          width: '100%',
          height: 'calc(100% + 3rem)',
        }}
      >
        <HireUsButton.Home to={root_path} data-cta="404" />
        {!!rendered && <Matter404 scene={blok} />}
      </div>
    </section>
  )
}

export default NotFound
