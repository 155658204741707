import { useRef } from 'react'
import { useIsomorphicLayoutEffect as useLayoutEffect, useConditionalEffect } from '@react-hookz/web'
import { getSmoother, refreshAll, useSmoother } from '.'
import { EFFECTS } from '../__consts'

const useSmoothEffect = (
  selectors = [],
  { speed = EFFECTS.speed, lag = EFFECTS.lag } = {},
  dependencies = [],
  predicates = []
) => {
  let ref = useRef(null)
  let init = useRef(false)

  const { prefix: prefix_key } = useSmoother() ?? {}
  const prefix = !!prefix_key ? '-' + prefix_key : ''

  useConditionalEffect(
    () => {
      const smoother = getSmoother()

      setTimeout(() => {
        smoother?.effects(!!selectors?.length ? [...selectors, ref.current] : ref.current, {
          speed: speed ?? EFFECTS.speed,
          lag: lag ?? EFFECTS.lag,
        })

        init.current && refreshAll()
        init.current = true
      }, 100)

      return () => {
        const _effects = getSmoother()?.effects()
        !!_effects?.length && _effects.forEach((t) => t.kill())
      }
    },
    [speed, lag, ...dependencies],
    predicates,
    undefined,
    useLayoutEffect
  )

  const attributes = ['speed', 'lag']?.reduce((acc, i) => {
    const value = { speed, lag }?.[i]
    return { ...acc, ...(value != null && value !== EFFECTS?.[i] && { [`data${prefix}-${i}`]: value }) }
  }, {})

  return [ref, attributes]
}

export default useSmoothEffect
