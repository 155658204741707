export const MEDIA_DEFAULTS = {
  steps: 400,
  params: { quality: 92 },
  placeholder: false,
  placeholder_modifiers: '/1x1/filters:quality(1)',
  object: { fit: 'cover', position: 'center center' },
  fade: false,
}

export const VIDEO_BASE = {
  controls: false,
  loop: true,
  muted: true,
  autoplay: true,
  preload: 'auto',
}

export const AUTOPLAY = {
  autoPlay: true,
  muted: true,
}

export const PLAYINLINE = {
  playsInline: true,
  'webkit-playsinline': 'true',
}
