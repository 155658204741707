export const COMMON = {
  fill: true,
  fluid: true,
  responsive: true,
  playsinline: true,
  preload: 'metadata',

  controlBar: {
    remainingTimeDisplay: {
      displayNegative: false,
    },
  },
  // experimentalSvgIcons: true,
  disablePictureInPicture: true,
  inactivityTimeout: 1250,
  // inactivityTimeout: 125012501250,

  html5: {
    vhs: {
      useDevicePixelRatio: true,
      useNetworkInformationApi: true,
      cacheEncryptionKeys: true,
    },
  },
}

export const MODES = {
  player: {
    loop: true,
    controls: true,
    // muted: true,
    // autoplay: true,
  },
  passive: {
    loop: true,
    muted: true,
    // autoplay: false,
    autoplay: true,
    controls: false,
  },
}
