export default {
  __default: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 1,

    '&[data-size=full]': { width: '100%' },
    '&[data-size=fit]': { width: 'fit-content' },

    '>.Asset': {
      position: 'relative',

      borderRadius: 1,
      overflow: 'hidden',
      transform: 'translateZ(0px)',

      bg: 'highlight',

      '>*': {
        transition: 'transform var(--ui-duration) var(--ui-ease)',
      },
    },

    '@media (hover: hover)': {
      '&:hover>.Asset>*': {
        transform: 'scale(1.02)',
      },
    },
  },
  info: {
    alignItems: 'start',
    gridTemplateColumns: ['1fr 2fr', null, '1.5fr 2fr 2fr'],
    gap: '2rem',
  },
}
