import Smoother from './SmootherProvider'

export { default as useFX } from './useFX'
export { default as useSmoothFX } from './useSmoothFX'
export { default as useSmoothEffect } from './useSmoothEffect'
export { default as useScrollSmoother } from './useScrollSmoother'
export { default as smootherStyles } from './smootherStyles'
export { default as useSticky } from './useSticky'
export * from './useScrollSmoother'
export * from './SmootherProvider'

export default Smoother
