import Logo from '../assets/svg/logo.svg'
import LogoInvert from '../assets/svg/logo-invert.svg'
import Plus from '../assets/svg/plus.svg'

import Medium from '../assets/svg/medium.svg'
import Discord from '../assets/svg/discord.svg'
import Facebook from '../assets/svg/facebook.svg'
import Instagram from '../assets/svg/instagram.svg'
import Twitter from '../assets/svg/twitter.svg'
import LinkedIn from '../assets/svg/linkedin.svg'
import TikTok from '../assets/svg/tiktok.svg'

import Cross from '../assets/svg/cross.svg'
import ArrowUp from '../assets/svg/arrow-up.svg'

/**
 * Define the icons available to the `<Icons />` component
 */
export default {
  logo: Logo,
  'logo-invert': LogoInvert,
  plus: Plus,
  discord: Discord,

  facebook: Facebook,
  instagram: Instagram,
  twitter: Twitter,
  linkedin: LinkedIn,
  tiktok: TikTok,

  medium: Medium,

  cross: Cross,
  'arrow-up': ArrowUp,
}
