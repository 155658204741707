import { Heading, Marquee } from '@monobits/components'
import { localize, makeFallbacki18n } from '@boiler/utils'
import SbEditable from 'storyblok-react'

import Table from './CollapsiblesComponent'
import { Rte } from '../atoms'
import { useLink } from '../hooks'

const FeaturedServices = ({ blok, sx, settings = {}, locales = {}, lang = {}, sanitize = (p) => p, ...props }) => {
  const { services, _uid } = blok ?? {}

  const multilink = useLink()
  const i18n = makeFallbacki18n(lang ?? {})

  return (
    <SbEditable content={blok} key={_uid}>
      <Table auto_close={true} {...sanitize(props)}>
        {services?.map((node, i) => {
          const { title, link_tags, link } = node?.content ?? {}
          const { content, includes, link_text } = i18n.array(['content', 'includes', 'link_text'], node?.content ?? {})

          const _tags = (link_tags ?? []).filter((i) => i !== 'All')
          const _to = settings?.[`projects_routes_${link}`] ?? {}

          const params = !!_tags.length
            ? `?tags=${encodeURIComponent(link_tags.map((ii) => ii.split(' ').join('-')).join('+'))}`
            : false

          return (
            <Table.Cell
              title={[localize(title ?? '', locales?.global)]}
              sx={{ variant: 'collapsible.service_list' }}
              key={_uid + node.uuid + i}
              id={_uid + node.uuid + i}
            >
              {(oppened) => (
                <>
                  <div data-element="list" data-column>
                    {(includes?.list ?? []).map((item, _i) => (
                      <Heading as="h4" variant="text" key={_uid + (item?.id ?? '') + _i}>
                        {item?.text}
                      </Heading>
                    ))}
                  </div>

                  <div data-element="content" data-column>
                    <Rte content={content} indented variant="text" />
                  </div>

                  <Marquee
                    id={'accordion-' + node.uuid}
                    {...multilink(_to, { params })}
                    paused={!!!oppened}
                    speed="20s"
                    multiplier={3}
                    sx={{
                      gridColumn: '1/-1!important',
                      '._hover >*': {
                        transform: 'skew(-12deg)',
                      },
                      '>div>*': {
                        transition: 'transform var(--ui-duration) cubic-bezier(0,0,0,1)',
                      },
                    }}
                  >
                    <Heading
                      variant="title"
                      cropped={false}
                      sx={{
                        my: 1,
                        mr: '1em',
                        mb: 'calc(var(--padding-x) - 0.08em)',
                        lineHeight: '1.2',
                      }}
                    >
                      {link_text}
                    </Heading>
                  </Marquee>
                </>
              )}
            </Table.Cell>
          )
        })}
      </Table>
    </SbEditable>
  )
}

export default FeaturedServices
