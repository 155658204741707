import { buildFonts, fontlist } from '@monobits/fonts'
import { makeFluids } from '@monobits/scales'

const fluid = makeFluids(
  {
    'size-running': ['18px', '32px', false],
    'size-menu': ['27px', '54px', false],
    'size-caption': ['13px', '18px'],
    'size-text': ['13px', '21px'],
    'size-subtitle': ['32px', '86px'],
    'size-name': ['36px', '120px'],
    'size-title': ['32px', '170px'],
  },
  ['375px', '2500px']
)

export const scales = {
  fontSizes: fluid.scale,
  fontWeights: {
    body: 400,
    heading: 400,
    caption: 400,
    name: 400,
  },
  lineHeights: {
    body: 1.25,
    caption: 1.3,
    name: 0.95,
    heading: 1,
  },
}

export const rootFontStyles = {
  fontSize: 1,
  fontFamily: 'now',
  lineHeight: 'body',
  fontWeight: 'body',

  '--rte': '1.35em',
  '.-rte + .-rte': { mt: 'var(--rte)' },

  ...fluid.properties,
}

const common = (type = 'body', uppercase = false) => ({
  textTransform: uppercase ? 'uppercase' : null,
  fontFamily: type,
  fontWeight: type,
  lineHeight: type,
  crops: 'now',
})

// const allianz = {
//   family: 'ES Allianz',
//   fallbacks: 'helvetica, arial, sans-serif',
//   crop: [9, 8],
// }
// export default buildFonts([fontlist(['allianz'], { allianz }), scales], {

export default buildFonts([fontlist(['now']), scales], {
  fonts: {
    heading: 'inherit',
    body: 'inherit',
    name: 'inherit',
    caption: 'inherit',
    monospace: 'Menlo, monospace',
  },
  variants: {
    key: 'text',
    heading: { variant: 'text.title' },
    __default: { variant: 'text.small' },
    __cropped: {
      title: { fontSize: 5, ...common('heading', false) },
      name: { fontSize: 4, ...common('name', false) },
      heading: { fontSize: 4, ...common('heading', false) },
      subtitle: { fontSize: 3, ...common('heading', true) },
      caption: { fontSize: 1, ...common('caption', false) },
      text: { fontSize: 2, ...common() },
      running: { fontSize: 'var(--size-running)', ...common() },
      menu: { fontSize: 'var(--size-menu)', ...common() },
    },
    small: { variant: 'text.caption' },
  },
})
