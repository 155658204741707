import { ThemeProvider } from 'theme-ui'
import theme from '@boiler/theme'

import Root from './Root'

const Layout = (props) => {
  const initialColorModeName = 'light'

  return (
    <ThemeProvider theme={theme([], { initialColorModeName })}>
      <Root initialColorModeName={initialColorModeName} {...props} />
    </ThemeProvider>
  )
}

export default Layout
