/**
 * Grabs the duration of the video
 * and adds `.vjs-minutes-long` or `.vjs-hours-long` on the player
 *
 * @example
 * import './plugins/HourClassPlugin'
 * // ...
 * player.HourClassPlugin()
 */
export const hourClassPlugin = (player) => {
  player.ready(() => {
    player.one('loadedmetadata', () => {
      const hours = Math.floor(player.duration() / 3600)
      player.addClass(`vjs-${hours === 0 ? 'minutes' : 'hours'}-long`)
    })
  })
}
