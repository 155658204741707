import SbEditable from 'storyblok-react'
import { MapBloks } from '../bloks'
import { useComponent, useLink } from '../hooks'
import { Cta } from '../atoms'

const RunningText = ({ blok, sanitize = (p) => p, ...props }) => {
  const { body, link, link_text } = blok ?? {}
  const { running_text } = useComponent() ?? {}
  const multilink = useLink()

  return (
    <SbEditable content={blok} key={blok._uid}>
      {running_text({
        value: (
          <>
            <MapBloks items={body} id={blok} variant="text" />

            {!!link_text && !!link && (
              <div className="Cta" sx={{ mt: 1, transitionDelay: '0.4s!important' }}>
                <Cta {...multilink(link)} sx={{ width: [null, 'fit-content'] }} size={null}>
                  {link_text}
                </Cta>
              </div>
            )}
          </>
        ),
        multiple: !!body?.length !== 1,
        ...sanitize(props),
      })}
    </SbEditable>
  )
}

export default RunningText
