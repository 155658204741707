import styled from '@emotion/styled'
import { position } from 'polished'

import { css, Flex } from 'theme-ui'

const x = {
  height: 'var(--height, 100px)',
  outerHeight: 'calc(var(--header-height) + (var(--padding-x) * 2))',
  padding: 'var(--padding-x, 0px)',
  speed: 'var(--speed, 0.45s)',
  ease: 'var(--ease, ease)',
  delay: 'var(--delay, 0s)',
}

export const StyledContainer = styled(Flex)(
  css({
    width: `calc(100% - (${x.padding} * 2))`,
    position: 'relative',
    top: x.padding,
    left: x.padding,
  })
)

const stickyStyles = {
  ...position('fixed', 0, 0, null, 0),
  zIndex: 'nav',
}

const shyStyles = {
  '[data-scroll-direction="down"] &': {
    '--translate': `calc(${x.outerHeight} * -1)`,
  },
  '[data-scroll-position="top"] &,[data-scroll-position="bottom"] &': {
    '--translate': '0.0001px',
  },
  [StyledContainer]: {
    transform: 'translate3d(0, var(--translate, 0.0001px), 0)',
    transition: `transform ${x.speed} ${x.ease} ${x.delay}`,
    willChange: 'transform',
  },
}

export const StyledHeader = styled(Flex)(({ spacer }) =>
  css({
    width: '100%',
    '&[data-is-shy=true]': shyStyles,
    '&[data-is-sticky=true]': stickyStyles,

    mb: spacer && x.outerHeight,
  })
)
