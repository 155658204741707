import { useEffect } from 'react'
import { useBreakpoint } from '@boiler/utils'

const useSetResponsiveAttr = () => {
  const { indexListener, getBreakpoint } = useBreakpoint({ quiet: true })

  useEffect(() => {
    const DOM = document.documentElement

    const { removeIndexListener } = indexListener((value) => {
      const { index, name, device } = getBreakpoint(value)
      DOM.setAttribute('data-break-index', index)
      DOM.setAttribute('data-breakpoint', name)
      DOM.setAttribute('data-device', device)
    })

    return removeIndexListener
  }, []) // eslint-disable-line
}

export default useSetResponsiveAttr
