import { forwardRef } from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { get } from 'lodash'
import normalizeUrl from 'normalize-url'

const Link = forwardRef((props, ref) => <GatsbyLink ref={ref} activeClassName="_active" {...props} />)

const types = ({ from, to, state }) => ({
  story: {
    as: Link,
    attr: 'to',
    path: 'story.full_slug',
    external: false,
    extend: { state: { from, to, ...state } },
  },
  url: {
    as: 'a',
    attr: 'href',
    path: 'url',
    external: true,
    extend: {
      target: '_blank',
      rel: 'noreferrer noopener',
    },
  },
})

const removeTrailingSlash = (url = '') =>
  (url ?? '')?.length !== 1 ? (url ?? '')?.replace(/\/$/, '') ?? '' : url ?? ''

export const multiLink = (link, { params = '', from, to, state = {} } = { params: '', state: {} }) => {
  const { as, attr, path, external, extend } = types({ from, to, state })?.[link?.linktype] ?? {}
  const story_url = get(link, path)
  const safe_url = story_url === 'home' || story_url?.split('/')?.[1] === 'home' ? '' : story_url

  const url = safe_url || get(link, 'href')

  let output
  if (external) output = url != null ? normalizeUrl(url) : url
  if (!!!external && !!!params) output = '/' + removeTrailingSlash(url)
  if (!!!external && !!params) output = '/' + removeTrailingSlash(url) + params

  return { as, [attr]: output, ...extend }
}
