export default {
  __default: {
    borderRadius: '200px',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'text',

    alignItems: 'center',
    justifyContent: 'center',

    '&[data-size=full]': { width: '100%' },
    '&[data-size=fit]': { width: 'fit-content' },

    py: '0.6em',
    px: '1em',

    transition: 'background-color var(--ui-duration) var(--ui-ease),color var(--ui-duration) var(--ui-ease)',

    '@media (hover: hover)': {
      '&:hover': {
        bg: 'text',
        color: 'background',
      },
    },
  },
}
