import { transitions, easeInOut } from 'polished'

export default {
  alpha: 1,
  __default: {
    '--stroke': 'var(--stroke-x)',
    '--line-gap': 'clamp(4px, 0.4em, 10px)',
    pb: 'var(--line-gap)',

    '--spacing': '100%',

    '&[data-inverted=true][data-use-offset=true]': {
      mb: 'calc(var(--line-gap) * -1)',
    },

    textDecoration: 'none',
    backgroundImage: 'linear-gradient(var(--from), var(--from)), linear-gradient(var(--to), var(--to))',
    backgroundPosition: '100% var(--spacing), 0 var(--spacing)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% var(--stroke), 0 var(--stroke)',
    ...transitions(['background-size', 'opacity'], `var(--ui-duration) ${easeInOut('cubic')}`),

    '@media (hover: hover)': {
      '&:not(._active):hover': {
        backgroundSize: '0 var(--stroke), 100% var(--stroke)',
      },
    },
    '&._active, &[data-active=true]': {
      backgroundSize: '0 var(--stroke), 100% var(--stroke)',
    },
  },
}
