import { debounce } from 'lodash'

const set = (key = '', value = '') => document.documentElement.style.setProperty(key, value)

export const polyfill = () => {
  const svh = document.documentElement.clientHeight * 0.01
  const dvh = window.innerHeight * 0.01

  set('--svh', svh + 'px')
  set('--dvh', dvh + 'px')
  set('--vh-x', dvh + 'px')

  if (!!document?.body) {
    const fixed = document.createElement('div')
    fixed.style.width = '1px'
    fixed.style.height = '100vh'
    fixed.style.position = 'fixed'
    fixed.style.left = '0'
    fixed.style.top = '0'
    fixed.style.bottom = '0'
    fixed.style.visibility = 'hidden'

    document.body.appendChild(fixed)

    const fixedHeight = fixed.clientHeight
    fixed.remove()

    const lvh = fixedHeight * 0.01

    set('--lvh', lvh + 'px')
  }
}

const initialize = () => {
  // SSR support
  if (typeof window === 'undefined') return

  const isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints > 0

  if (!!isTouch) {
    // We run the calculation as soon as possible (eg. the script is in document head)
    polyfill()

    // We run the calculation again when DOM has loaded
    document.addEventListener('DOMContentLoaded', () => polyfill())

    // We run the calculation when window is resized
    window.addEventListener('resize', () => debounce(polyfill, 300))
  }
}

export default initialize
