import { Link } from 'gatsby'
import { Flex, Grid, useThemeUI } from 'theme-ui'
import { omit } from 'lodash'

import { useLinkFrom } from '../../hooks'
import { StyledHeader, StyledContainer } from './Header.styled'

/**
 * This is the Header component
 */
const Header = ({ children, variant, sx, logo, logoAriaLabel, homePath, sticky, shy, ...props }) => {
  const { useStickyHeader, useShyHeader } = useThemeUI(null).theme?.config
  const from = useLinkFrom() ?? ''

  const linked = homePath
    ? {
        as: Link,
        to: homePath,
        state: { from, to: 'home' },
      }
    : {}

  return (
    <StyledHeader
      as="header"
      sx={{ variant: 'header.container', ...sx }}
      data-is-shy={shy ?? useShyHeader}
      data-is-sticky={sticky ?? useStickyHeader}
      {...omit(props, ['modes'])}
    >
      <StyledContainer>
        {logo && (
          <Flex {...linked} aria-label={logoAriaLabel} sx={{ variant: 'header.logo' }}>
            {logo}
          </Flex>
        )}
        <Grid as="nav" sx={{ variant: 'header.nav' }}>
          {children}
        </Grid>
      </StyledContainer>
    </StyledHeader>
  )
}

export default Header
