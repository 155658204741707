import { Image as StoryblokImage } from '@boiler/image'
import { Lazy } from '@boiler/intersection-observer'

const Image = ({ blok, eager = false, fit, ratio, position, lazyload, fitted, sanitize = (p) => p, ...props }) => {
  const { image } = blok ?? {}

  return (
    <Lazy inview={{ once: true }} eager={eager}>
      {({ ref, inview }) => (
        <StoryblokImage
          ref={ref}
          data={image}
          inview={inview}
          object={{ fit, position }}
          ratio={!!ratio ? ratio : null}
          lazyload={lazyload ?? true}
          fitted={fitted ?? !!ratio}
          {...sanitize(props)}
        />
      )}
    </Lazy>
  )
}

export default Image
