import { inset } from '@monobits/core'

export default {
  __default: {
    position: 'fixed',

    width: '100%',
    height: 'calc(var(--lvh, 1vh) * 100)',

    top: 0,
    left: 0,
    right: 0,
    zIndex: 0,

    pointerEvents: 'none',
    userSelect: 'none',

    transition: 'transform 2.5s var(--bg-ease)',

    // '&[data-scoped=false]': {
    //   transform: 'scale(1.4)',
    // },
    // '&[data-scoped=true]': {
    //   transform: 'scale(var(--scale-out, 1))',
    // },
    '&[aria-hidden=true]': {
      visibility: 'hidden',
    },
  },

  assets: {
    size: '100%',
    position: 'absolute',
    ...inset(0),

    transition: 'opacity var(--ui-duration) var(--ui-ease)',

    '>[data-aspect-ratio],>[data-ready]': {
      size: '100%',
      position: 'absolute',
      ...inset(0),
    },
    '&[data-selected=false]': {
      opacity: 0,
      zIndex: 0,
    },
    '&[data-selected=true]': {
      zIndex: 2,
    },
  },
}
