import { useRef } from 'react'
import { useConditionalEffect, useIsomorphicLayoutEffect as useLayoutEffect } from '@react-hookz/web'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

/**
 * Use ScrollTrigger Hook,
 * Convenient effect hook to write triggers.
 *
 * @param  {function} tween Function that returns a GSAP Tween or Timeline
 * @property  {function} tween.gsap Expose `gsap` as a prop
 * @property  {function} tween.scroll Expose `scroll` as a prop (from Locomotive Scroll)
 *
 * @param  {function} configs Function that returns a `ScrollTrigger.create()` options object.
 * @property  {function} configs.scroll Expose `scroll` as a prop (from Locomotive Scroll)
 *
 * @param  {Number} delay UnMount delay (usefull with page transitions)
 * @param  {Array} deps Effect Hook Dependecies
 * @param  {Array} predicate Array of predicates, if all true, the effect will render
 *
 * @example
 * useScrollTrigger(
 *   ({ gsap }) => gsap.to('.element', { rotation: 360, ease: 'none' }),
 *   () => ({
 *     trigger: '.elementParent',
 *     start: '50% 50%',
 *     end: '+=300',
 *     pin: true,
 *     scrub: true,
 *     markers: true,
 *   }),
 *   0,
 *   [...deps],
 *   [...predicates]
 * )
 */
const useScrollTrigger = (tween = () => {}, configs = () => {}, delay = 0, deps = [], predicate = []) => {
  let ref = useRef(null)
  let animation = useRef(null)

  const node = ref?.current

  useConditionalEffect(
    () => {
      let options = configs({ node })
      animation.current = tween({ gsap, node })

      ScrollTrigger.create({
        animation: animation.current,
        ...options,
      })

      if (!!animation.current?.scrollTrigger && !!delay) {
        return () => setTimeout(() => animation.current?.scrollTrigger?.kill(), delay)
      }
      if (!!animation.current?.scrollTrigger) {
        return () => animation.current?.scrollTrigger?.kill()
      }
    },
    deps,
    predicate,
    undefined,
    useLayoutEffect
  )

  return [ref, { node, animation: animation?.current }]
}

export default useScrollTrigger
