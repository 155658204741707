import { useEvent } from 'react-use-event-hook'

/**
 * Listen to an elemement classList changes.
 */
export const useClassListObserver = (player, onMutation, onMount) => {
  const handleMutation = useEvent((mutationList) => {
    if (player) {
      typeof onMount === 'function' && onMount(mutationList)

      mutationList.forEach(function (mutation) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const classList = [...(mutation.target?.['classList'] ?? [])]

          onMutation(classList, mutation)
        }
      })
    }
  })

  return {
    handleMutation,
    NewClassListObserver: () => new MutationObserver(handleMutation),
  }
}

export default useClassListObserver
