import cls from 'classnames'
import Link from './Link'

const MultilineLink = ({ blok, children, className, ...props }) => {
  const { link, params, text } = blok ?? {}

  return (
    <Link className={cls('link', className)} link={link} params={params} {...props}>
      {text}
    </Link>
  )
}
export default MultilineLink
