import { inset } from '@monobits/core'

export default {
  shape: {
    '--height': 'calc(100 * var(--svh, 1vh))',
  },
  head: {
    variant: 'project.shape',

    p: 1,
    height: 'var(--height, 100vh)',
    position: 'relative',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',

    overflow: 'hidden',

    '>div': {
      position: 'absolute!important',
      ...inset(0),

      size: '100%',
    },
  },
  header: {
    mt: 3,
    mb: 1,

    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gap: 'calc(var(--padding-x) * 0.75)',

    '&[data-with-link=true]': {
      gridTemplateRows: '1fr auto 1fr',
    },
    '&[data-with-link=false]': {
      gridTemplateRows: 'auto 1fr',
    },

    hr: {
      gridColumn: '1/-1',
    },

    '[data-area="live"],[data-area="amount"],[data-area="year"]': {
      textAlign: 'right',
    },
    '[data-area="live"]': {
      gridColumn: '5/-1',
      display: 'inline-flex',
      justifyContent: 'flex-end',
    },
    '[data-area="amount"]': {
      gridColumn: '6/-1',
    },
    '[data-area="title"]': {
      gridColumn: ['1/4', null, '1/3'],
    },
  },
  content: {
    gridColumn: '1/-1',

    '>div': {
      my: [1, 0, 'calc((var(--padding-x) * 2) * -1)'],

      display: 'grid',
      gridTemplateColumns: ['repeat(4, 1fr)', 'repeat(8, 1fr)', 'repeat(4, 1fr)'],
      alignContent: 'start',
      columnGap: [1, null, 2],
      rowGap: 1,

      gridTemplateAreas: [
        `
          "a a a a"
          "title title title title"
          ". . . ."
          "year year year year"
          ". . . ."
          "expertise expertise expertise expertise"
          ". . . ."
          "mission mission mission mission"
        `,
        `
          "a a a a a a a a"
          "title title title title expertise expertise expertise expertise"
          ". . . . expertise expertise expertise expertise"
          ". . . . expertise expertise expertise expertise"
          "year year year year . . . ."
          "year year year year . . . ."
          ". . . . . . . ."
          ". . . . . . . ."
          "mission mission mission mission mission mission mission mission"
        `,
        `
          "a a a a"
          "title title expertise expertise"
          "year year mission mission"
        `,
      ],

      '[data-area="title"]': {
        gridArea: 'title',
      },
      '[data-area="year"]': {
        gridArea: 'year',
        mt: [null, null, 2],
      },
      '[data-area="credits"]': {
        gridArea: 'credits',
      },
      '[data-area="expertise"]': {
        gridArea: 'expertise',
      },
      '[data-area="mission"]': {
        mt: [null, null, 2],
        gridArea: 'mission',
      },

      '[data-chunk]': {
        display: 'flex',
        flexDirection: 'column',
        gap: 1,

        '>nav': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '0.7em',

          '>*': { mt: 0 },
        },
      },

      '>hr': {
        width: '100%',
        borderTopColor: 'text',
        borderTopWidth: 'var(--stroke-x)',
        willChange: 'transform',
      },
    },
  },
}
