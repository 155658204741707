import { useState, createContext, useContext } from 'react'
import { useThemeUI } from 'theme-ui'
import { useSet } from '@react-hookz/web'
import { withHover } from '@monobits/core'
import { Section, Collapsible as Base } from '@monobits/components'

import { useComponent, useLink } from '../hooks'

const CollapseContext = createContext()

const Cell = withHover(
  ({ children, title, id, link = {}, sx, hover, ...props }) => {
    const {
      bezier,
      transition: { duration },
    } = useThemeUI().theme?.motion.collapsible ?? {}
    const {
      set,
      auto,
      state: [state, setState],
    } = useContext(CollapseContext)

    const { collapsible } = useComponent() ?? {}

    const opened = auto ? state === id : !!set.has(id)
    const isLink = 'fieldtype' in link

    const multilink = useLink()

    return collapsible.head({
      sx: { '--speed': `${duration}s`, '--ease': bezier, ...sx },
      props: {
        wrapper: {
          'data-handle': id,
          'data-selected': !!hover || opened,
          'aria-expanded': opened,
          'data-static': false,
          ...props,
        },
        button: isLink
          ? multilink(link)
          : {
              as: 'button',
              onClick: () => {
                if (auto) setState(opened ? '' : id)
                return opened ? set.delete(id) : set.add(id)
              },
            },
      },
      content: (
        <Base onTrigger={!opened}>{typeof children === 'function' ? children(!!hover || opened) : children}</Base>
      ),
      value: title,
      withContent: !!!isLink,
      isLink,
      link,
      id,
    })
  },
  { mouse: true }
)

const CollapsiblesComponent = ({ children, length = 0, auto_close = true, ...props }) => {
  const { collapsible } = useComponent() ?? {}
  const set = useSet([])
  const state = useState('')

  const auto = auto_close ?? collapsible?.auto ?? true

  return (
    <CollapseContext.Provider value={{ set, state, auto }}>
      <Section {...collapsible?.section} data-single={!!((length ?? 1) === 1)} {...props}>
        <div>{children}</div>
      </Section>
    </CollapseContext.Provider>
  )
}

CollapsiblesComponent.Cell = Cell

export default CollapsiblesComponent
