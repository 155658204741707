import { omit } from 'lodash'

import { SEO } from '../bloks'
import useStoryblok from '../layouts/useStoryblok'
import Template from '../templates'

const BaseEntry = ({ pageContext, location, seo = () => {}, noindex = false, nofollow = false, ...props }) => {
  let { story, settings, locales } = useStoryblok(pageContext, location)

  const storyblok = {
    blok: story.content,
    source: story,
    settings,
    locales,
    lang: pageContext?.lang ?? {},
    themes: pageContext?.themes ?? {},
    field_component: story?.field_component,
    all_nodes_slugs: pageContext?.all_nodes_slugs ?? [],
  }

  const force_noindex = !!location.search ? true : null

  return (
    <>
      <SEO
        {...storyblok}
        {...seo(story)}
        noindex={force_noindex ?? noindex}
        nofollow={force_noindex ?? nofollow}
        pageContext={pageContext}
      />
      <Template
        {...storyblok}
        tags={pageContext?.tags ?? []}
        component={story.content.component}
        key={pageContext.story._uid}
        realPath={pageContext.realPath}
        location={location}
        sanitize={(obj) =>
          omit(obj, [
            ...Object.keys(pageContext),
            ...Object.keys(storyblok),
            ...Object.keys(props),
            'location',
            'tags',
            //
          ])
        }
        {...props}
      />
    </>
  )
}

export default BaseEntry
