import { useComponent, useLink } from '../hooks'

const NavItem = ({ blok, component = 'nav_item', allowSpecial = false, ...props }) => {
  const { link, params, text, locale, is_special, _uid } = blok ?? {}
  const components = useComponent() ?? {}
  const multilink = useLink()

  const renderer = components[component]

  return renderer({
    uid: _uid,
    value: text || locale,
    is_special: is_special ?? false,
    allowSpecial,
    ...multilink(link, { params }),
    ...props,
  })
}

export default NavItem
