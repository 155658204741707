import { multiLink } from '@boiler/utils'
import { useData } from '.'

const useLink = () => {
  const { component } = useData() ?? {}

  return (link, { params = '', state = {} } = { params: '', state: {} }) =>
    multiLink(link, {
      to: link?.story?.content?.component ?? 'preview',
      from: component ?? 'preview',
      params,
      state,
    })
}

export const useLinkFrom = () => {
  const { component } = useData() ?? {}
  return component ?? 'preview'
}

export default useLink
