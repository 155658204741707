import { useViewState } from '.'
import getOptimalRootMargin from './getOptimalRootMargin'

const Lazy = ({ children, inview = {}, options = {}, eager = false }) => {
  const [ref, intersecting] = useViewState([], {
    inview: { once: true, eager, disabled: false, ...inview },
    options: { rootMargin: getOptimalRootMargin(), ...options },
  })

  return children({ ref, inview: intersecting })
}

export default Lazy
