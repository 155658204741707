import { Bloks } from '../bloks'
import { useComponent } from '../hooks'

const Links = ({ blok, ...props }) => {
  const { body, _uid } = blok ?? {}
  const { links } = useComponent() ?? {}

  const value = body?.length
    ? body.map((child) => <Bloks blok={child} key={child._uid} parentId={_uid} {...props} />)
    : null

  return links({ value, ...props })
}

export default Links
