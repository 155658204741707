import { useState, useRef } from 'react'
import { useConditionalEffect, useIsomorphicLayoutEffect as useLayoutEffect } from '@react-hookz/web'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'
import { debounce } from 'lodash'
import { useSmoother } from '.'
import { BOTTOM } from '../__consts'

const useSticky = (
  trigger = null,
  { forwardRef = false, bottom = null, pin, options = {} } = {},
  dependencies = [],
  predicates = []
) => {
  let instance = useRef(null)
  let ref = useRef(null)
  const { ids } = useSmoother() ?? {}
  const {
    percent,
    offset: [operator, offset],
  } = { ...BOTTOM, ...(bottom ?? {}) }

  const watch = JSON.stringify({ trigger, percent, offset, pin, options, ...dependencies })

  const [refresh, setRefresh] = useState(0)

  useConditionalEffect(
    () => {
      const handleRefresh = debounce(() => setRefresh((prev) => (prev + 1) % 2), 500)
      ScrollTrigger.addEventListener('refresh', handleRefresh)
      return () => ScrollTrigger.removeEventListener('refresh', handleRefresh)
    },
    [bottom != null],
    [bottom != null],
    undefined,
    useLayoutEffect
  )

  useConditionalEffect(
    () => {
      const node = !!forwardRef ? forwardRef : ref.current
      const getter = gsap.getProperty(node)
      const height = getter('height', 'px')

      if (bottom != null) {
        const value = percent / 100
        const reverse = 1 - value

        gsap.set(node, {
          position: 'absolute',
          visibility: 'visible',
          top: `calc((${window?.innerHeight}px * ${reverse}) - ${height}px ${operator} ${offset})`,
        })
        node?.setAttribute('data-sticky', 'sticky')
      }

      const _t = typeof trigger === 'function' ? trigger(ref.current) : trigger

      instance.current = ScrollTrigger.create({
        trigger: _t ?? ids?.content ?? 'body',
        start: 'top top',
        pin: pin ?? node,
        pinSpacing: false,
        ...(typeof options === 'function' ? options({ node, height }) : options),
      })

      return () => {
        instance.current?.kill()
        node?.setAttribute('data-sticky', false)
      }
    },
    [watch, forwardRef, refresh],
    [forwardRef != null || forwardRef === false, ...predicates],
    undefined,
    useLayoutEffect
  ) // eslint-disable-line

  return ref
}

export default useSticky
